<template>
    <div class="main">

        <el-select v-model="form.type" placeholder="活动类型" size="small" class="inputSty" clearable>
            <el-option label="克减" value="1"></el-option>
            <el-option label="折扣" value="2"></el-option>
        </el-select>
        <el-select v-model="form.memberId" placeholder="创建人员" size="small" class="inputSty" clearable>
            <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>

        <!-- <el-date-picker style="width: 300px;top: 1.5px;" v-model="dateArr" value-format="yyyy-MM-dd" type="daterange"
            size="small" range-separator="至" start-placeholder="活动开始日期" end-placeholder="活动结束日期" class="inputSty">
        </el-date-picker> -->


        <el-button size="small" class="addButton" @click="tableData = [], getActivePrice()"
            type="primary">查询</el-button>
        <el-button size="small" class="addButton" @click="addShow = true">创建活动</el-button>

        <!-- 表格显示 -->
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">

                    <el-table-column prop="name" label="活动名称"></el-table-column>
                    <el-table-column label="是否在活动">
                        <template scope="scope">
                            <div class="statSty" style="background: #149514" v-if="scope.row.isInValidPeriod == '1'">是
                            </div>
                            <div class="statSty" style="background: #ee8c03" v-if="scope.row.isInValidPeriod == '0'">否
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="crm_intention" label="活动类型">
                        <template scope="scope">
                            <div class="statSty" style="background: #149514" v-if="scope.row.type == '1'">克减</div>
                            <div class="statSty" style="background: #ee8c03" v-if="scope.row.type == '2'">打折</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="crm_intention" label="优惠内容">
                        <template scope="scope">
                            <div v-if="scope.row.type == '1'">
                                每克优惠{{ scope.row.value }}元
                            </div>
                            <div v-if="scope.row.type == '2'">
                                {{ scope.row.value }}折扣
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name2" label="创建人员"></el-table-column>
                    <el-table-column prop="addTime" label="创建时间"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间"></el-table-column>
                    <el-table-column prop="address" width="300" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="detailsFun(scope.row)">终止活动</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>


        <!-- 创建任务新增弹窗 -->
        <el-dialog title="创建优惠活动" :visible.sync="addShow" width="600px">
            <el-form :model="form3" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="活动名称" prop="name" maxlength="10" size="small" style="width: 292px;">
                    <el-input v-model="form3.name"></el-input>
                </el-form-item>
                <el-form-item label="活动类型" prop="type" maxlength="10" size="small">
                    <el-select v-model="form3.type" placeholder="权限类型">
                        <el-option label="克减" value="1"></el-option>
                        <el-option label="折扣" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="form3.type == '1'" label="克减" prop="value" maxlength="10" size="small"
                    style="width: 292px;">
                    <el-input type="number" v-model="form3.value"></el-input>
                </el-form-item>
                <el-form-item v-if="form3.type == '2'" label="折扣" prop="value" maxlength="10" size="small"
                    style="width: 292px;">
                    <el-input type="number" v-model="form3.value"></el-input>
                </el-form-item>
                <el-form-item label="活动时间" prop="dateArr" maxlength="10" size="small">

                    <el-date-picker style="width: 300px;top: 1.5px;" v-model="form3.dateArr" value-format="yyyy-MM-dd"
                        type="daterange" size="small" range-separator="至" start-placeholder="活动开始日期"
                        end-placeholder="活动结束日期" class="inputSty">
                    </el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addShow = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveFun()">确 定</el-button>

            </span>
        </el-dialog>
    </div>
</template>

<script>


import moment from 'moment';
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            userAdmin: [],
            form: {
                shopId: this.shopId,
                "memberId": "",
                "type": ""

            },
            form2: {},
            form3: {
                shopId: localStorage.getItem("shopId"),
                "value": "",
                "name": "",
                "type": "1",
                "memberId": "",
                dateArr: [],

            },
            dateArr: [],
            textarea: "",
            user: JSON.parse(localStorage.getItem("user")),
            addTypeShow: false,
            addTypeShow2: false,
            obs: {},
            obs2: [],
            addShow: false,
            rules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                value: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                dateArr: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ]
            },
        }
    },
   
    mounted() {
        this.shopId = localStorage.getItem("shopId");
        this.form.shopId = localStorage.getItem("shopId");
        this.getAdminUser();
        this.form.begWarnTime = moment().format('YYYY-MM-DD');

        const threeDaysAgo = moment().add(7, 'days');
        this.form.endWarnTime = threeDaysAgo.format('YYYY-MM-DD');
        this.dateArr = [this.form.begWarnTime, this.form.endWarnTime]


    },
    methods: {
        getAdminUser() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.userAdmin = res.data;
                this.getActivePrice();
            })
        },
        getActivePrice() {

            this.$api.post("good/getActivePrice", this.form, (res) => {
                res.data.forEach(e => {
                    let arr = this.userAdmin.filter(o => o.id == e.memberId)[0]
                    e.name2 = arr ? arr.name : '未知'

                });

                this.tableData = res.data;
            })

        },

        saveFun() {
            if (this.form3.type=='1') {
                if (10<=this.form3.value||3>this.form3.value) {
                    this.$message.error('折扣取值范围3~10');
                    return
                }
                
            }else{
                if (200<=this.form3.value||10>this.form3.value) {
                    this.$message.error('折扣取值范围10~200');
                    return
                }
            }



            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.form3.memberId = this.user.id;
                    this.form3.startTime = this.form3.dateArr[0];
                    this.form3.endTime = this.form3.dateArr[1];
                    this.$api.post("good/addActivePrice", this.form3, (res) => {
                        if (res.code == 200) {
                            this.getActivePrice()
                            this.addShow = false
                            this.$message({
                                message: '创建成功',
                                type: 'success'
                            });
                        } else {
                            this.$message.error('新增失败');
                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });
        },


    },


}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputSty {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.textSty {
    width: 40%;
    margin: 6px 4%;
    display: inline-flex;
    /* border: 1px solid #efefef; */
}

.statSty {
    background: rgb(0, 255, 0);
    color: rgb(255, 255, 255);
    padding: 0px 5px;
    width: 70px;
    text-align: center;
}
</style>