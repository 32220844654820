<template>
    <div class="main">
        <el-select v-model="form.memberId" placeholder="选择操作人员" size="small" class="inputSty" clearable>
            <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>

        <el-date-picker v-model="dateArr" value-format="yyyy-MM-dd" type="daterange" size="small" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" class="inputSty">
        </el-date-picker>
        <el-button size="small" class="addButton" @click="stockList = [], getStock()" type="primary">查询</el-button>

        <!-- 表格显示 -->
        <div class="table">
            <template>
                <el-table :data="stockList" stripe style="width: 100%">
                    <el-table-column prop="date" label="头像" width="80">
                        <template scope="scope">
                            <div class="goodBox">
                                <el-image fit="cover" :src="scope.row.userImg"
                                    :preview-src-list="[scope.row.userImg]" class="goodImg"></el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作人员">
                    </el-table-column>
                    <el-table-column prop="number" label="盘点数量">

                    </el-table-column>
                    <el-table-column prop="topNumber" label="上次盘点数量">

                    </el-table-column>
                    <el-table-column prop="addTime" label="盘点时间">

                    </el-table-column>



                    
                    <el-table-column prop="addDate" label="差异状态">
                        <template scope="scope">
                            <div style="color:#00D400 " v-if="scope.row.number>scope.row.topNumber">+{{scope.row.number - scope.row.topNumber  }}</div>
                            <div style="color:#FF0000" v-else>{{scope.row.number - scope.row.topNumber  }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="details" label="差异备注">

</el-table-column>
                    <el-table-column prop="address" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="detailsFun(scope.row)">详情</el-button>
                           
                        </template>
                    </el-table-column>
                </el-table>
            </template>

        </div>
        <!-- 详情弹窗 -->
        <el-dialog title="盘点详情" :visible.sync="addTypeShow2" width="600px">
           
                <div class="textSty">操作人员：{{ obs.name }}</div>
                <div class="textSty">盘点数量：{{ obs.number }}</div>
                <div class="textSty">盘点时间：{{ obs.addTime }}</div>
                <div class="textSty">上次盘点数量：{{ obs.topNumber }}</div>

                <div class="textSty" style="color:#00D400 " v-if="obs.number > obs.topNumber">差异情况：+{{ obs.number - obs.topNumber }}</div>
                <div class="textSty" style="color:#FF0000" v-else >差异情况：{{ obs.number - obs.topNumber }}</div>
                <div class="textSty">差异备注：{{ obs.details }}</div>
               
            
 

            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addTypeShow2 = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>


import moment from 'moment';
export default {
    data() {
        return {
            shopId: '',
            stockList: [],
            userAdmin: [],
            form: {
                shopId: this.shopId,
                "openId": "",
                "memberId": "",
                "stat": "",
                "addTime": "",
                "endTime": "",
            },
            form2: {},
            dateArr: [],
            textarea: "",
            user: JSON.parse(localStorage.getItem("user")),
            addTypeShow: false,
            addTypeShow2: false,
            obs: {},
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId");
        this.form.shopId = localStorage.getItem("shopId");
        this.getAdminUser();

        const threeDaysAgo = moment().subtract(3, 'days');
        this.form.addTime = threeDaysAgo.format('YYYY-MM-DD');
        this.form.endTime = moment().format('YYYY-MM-DD');
        this.dateArr = [this.form.addTime, this.form.endTime]

    },
    methods: {
        getAdminUser() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.userAdmin = res.data;
                this.getStock();
            })
        },
        getStock() {
            if (this.dateArr) {
                this.form.addTime = this.dateArr[0];
                this.form.endTime = this.dateArr[1];
            } else {
                this.form.addTime = '';
                this.form.endTime = '';
            }

            this.$api.post("shop/getTakeStock", this.form, (res) => {
              

                this.stockList = res.data;
            })

        },

        //查看详情
        detailsFun(item) {
            this.addTypeShow2 = true;
            this.obs = item;

        },

    },


}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputSty {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.textSty {
    width: 40%;
    margin: 6px 4%;
    display: inline-block;
    /* border: 1px solid #efefef; */
}
</style>