<template>
    <div class="main">
        <div class="box1">
            <div class="box2" style="width: 30%;">
                <div class="title">高浏览量商品</div>
                <qiun-vue-ucharts type="bar" :opts="opts1" :chartData="chartData2" />
            </div>
            <div class="box2" style="width: 30%;">
                <div class="title">高收藏量商品</div>
                <qiun-vue-ucharts type="bar" :opts="opts2" :chartData="chartData3" />
            </div>
            <div class="box2" style="width: 40%;">
                <div class="title">商品分类占比排名</div>
                <qiun-vue-ucharts type="rose" :opts="opts3" :chartData="chartData4" />
            </div>
        </div>



    </div>
</template>

<script>
import qiunVueUcharts from '@qiun/vue-ucharts'
export default {
    components: {
        qiunVueUcharts
    },
    data() {
        return {
            chartData: {},
            chartData2: {},
            chartData3: {},
            chartData4: {},
            user: JSON.parse(localStorage.getItem("user")),
            //您可以通过修改 config-ucharts.js 文件中下标为 ['column'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
            opts1: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 30, 0, 5],
                enableScroll: false,
                legend: {},
                xAxis: {
                    boundaryGap: "justify",
                    disableGrid: false,
                    min: 0,
                    axisLine: false,
                    max: 70
                },
                yAxis: {},
                extra: {
                    bar: {
                        type: "stack",
                        width: 30,
                        meterBorde: 1,
                        meterFillColor: "#FFFFFF",
                        activeBgColor: "#000000",
                        activeBgOpacity: 0.08,
                        categoryGap: 2
                    }
                }
            },
            opts2: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 30, 0, 5],
                enableScroll: false,
                legend: {},
                xAxis: {
                    boundaryGap: "justify",
                    disableGrid: false,
                    min: 0,
                    axisLine: false,
                    max: 40
                },
                yAxis: {},
                extra: {
                    bar: {
                        type: "group",
                        width: 30,
                        meterBorde: 1,
                        meterFillColor: "#FFFFFF",
                        activeBgColor: "#000000",
                        activeBgOpacity: 0.08,
                        linearType: "custom",
                        barBorderCircle: true,
                        seriesGap: 2,
                        categoryGap: 2
                    }
                }
            },
            opts3: {
        color: ["#1890FF","#91CB74","#FAC858","#EE6666","#73C0DE","#3CA272","#FC8452","#9A60B4","#ea7ccc"],
        padding: [5,5,5,5],
        enableScroll: false,
        legend: {
          show: true,
          position: "left",
          lineHeight: 25
        },
        extra: {
          rose: {
            type: "radius",
            minRadius: 50,
            activeOpacity: 0.5,
            activeRadius: 10,
            offsetAngle: 0,
            labelWidth: 15,
            border: true,
            borderWidth: 2,
            borderColor: "#FFFFFF",
            linearType: "custom"
          }
        }
      }


        };
    },
    mounted() {

        this.getServerData1();

    },
    methods: {
        getServerData1() {
            let data = {
                shopId: this.user.shopId
            }
            this.$api.post("shop/getGoodsE", data, (res) => {
                let name = [];
                let value = [];
                res.data.pageView.forEach(e => {
                    name.push(e.goodName.slice(0, 5))
                    value.push(e.pageView)
                });
                let res2 = {
                    categories: name,
                    series: [
                        {
                            name: "商品浏览量",
                            data: value
                        },

                    ]
                };
                this.chartData2 = JSON.parse(JSON.stringify(res2));


                let name2 = [];
                let value2 = [];
                res.data.collect.forEach(e => {
                    name2.push(e.goodName.slice(0, 5))
                    value2.push(e.collectNub)
                });
                let res3 = {
                    categories: name2,
                    series: [
                        {
                            name: "商品收藏量",
                            data: value2
                        },

                    ]
                };
                this.chartData3 = JSON.parse(JSON.stringify(res3));
               
                let arr4 = [];
                res.data.type.forEach(e => {
                    arr4.push({
                        name:e.name,
                        // centerText:e.name,
                        value:e.type_count,
                    })
                });
                arr4 = arr4.sort((a,b)=>{return b.value-a.value })
                console.log(arr4);
                let res4 = {
                    series: [
                        {
                            data: arr4
                        }
                    ]
                };
                this.chartData4 = JSON.parse(JSON.stringify(res4));








            })




        },
    }
}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.box1 {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    position: relative;
}

.box2 {
    width: 33%;
    height: calc(100% - 50px);
    padding-top: 50px;
    /* border: 1px solid rgb(33, 38, 91); */
    display: inline-block;
    position: relative;
}

.title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-weight: bold;
}
</style>