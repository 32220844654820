<template>
    <div class="main">
        <div class="box1">
            <div class="box11" style="width: 40%;">
                <div class="title">概况</div>
                <div class="box111">
                    <div class="text">商品总量</div>
                    <div class="textNub">{{ dataSun1.goodSun }}</div>
                </div>
                <div class="box111">
                    <div class="text">客户总量</div>
                    <div class="textNub">{{ dataSun1.crmSun }}</div>
                </div>
                <div class="box111">
                    <div class="text">预约总量</div>
                    <div class="textNub">{{ dataSun1.yuSun }}</div>
                </div>
                <div class="box111">
                    <div class="text">订单总量</div>
                    <div class="textNub">{{ dataSun1.orderSun }}</div>
                </div>



            </div>
            <div class="box11" style="width: 59%;">
                <div class="title">店铺今日</div>
                <div class="box111">
                    <div class="text">门店客流</div>
                    <div class="textNub">{{ dataSun2.newCrm }}</div>
                </div>
                <div class="box111">
                    <div class="text">新增用户</div>
                    <div class="textNub">{{ dataSun2.newPeople }}</div>
                </div>

                <div class="box111">
                    <div class="text">销售金额</div>
                    <div class="textNub">{{ dataSun2.orderMoney }}</div>
                </div>
                <div class="box111">
                    <div class="text">盘点数量</div>
                    <div class="textNub">{{ takeStockNumber }}</div>
                </div>
                <div class="box111">
                    <div class="text">今日金价</div>
                    <div class="textNub">￥{{ price }}</div>
                </div>
            </div>

        </div>
        <div class="box2">
            <div style="width: 60%;background: #f6f6f6; " class="box22">
                <div class="box222">
                    <div class="title">金价走势</div>
                    <div style="width: calc(100% - 40px); height: calc(100% - 40px); padding: 20px;padding-top:40px;">
                        <canvas width="880" height="200" ref="chartCanvas"></canvas>

                    </div>
                </div>
                <div style="margin-top:1.5%;background: #f6f6f6; " class="box222">
                    <div class="box2222">
                        <div class="title">客户动态</div>
                        <qiun-vue-ucharts type="bar" :opts="opts2" :chartData="chartData2" />
                    </div>
                    <div class="box2222">
                        <div class="title">邀请成功率</div>
                        <div
                            style="width: calc(100% - 60px); height: calc(100% - 60px); padding: 30px;padding-top:40px;">
                            <canvas ref="chartCanvas2"></canvas>

                        </div>
                    </div>

                </div>

            </div>
            <div style="width: 39%;" class="box22">
                <div class="title">热销商品</div>
                <div
                    style="overflow: auto; width: calc(100% - 60px); height: calc(100% - 130px); padding: 30px;padding-top:20px;margin-top:40px ">
                    <el-table :data="tableData" stripe style="width: 100%">
                        <el-table-column prop="date" label="头像" width="80">
                            <template scope="scope">
                                <div class="goodBox">
                                    <el-image fit="cover" :src="scope.row.imgMain" :preview-src-list="srcList"
                                        class="goodImg"></el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格" width="80">
                            <template scope="scope">
                                <div style="color:rgb(245, 30, 75);font-weight: bold;" class="goodBox">￥{{
                        scope.row.price }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodName" label="商品名称">
                            <template scope="scope">
                                <div class="goodName">￥{{ scope.row.goodName }}</div>
                            </template>

                        </el-table-column>
                        <el-table-column prop="pageView" label="浏览量" width="80">
                        </el-table-column>
                        <!-- <el-table-column prop="collectNub" label="收藏量" width="80">
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>

        </div>







    </div>
</template>

<script>


import Chart from 'chart.js/auto';

import qiunVueUcharts from '@qiun/vue-ucharts'
export default {
    components: {
        qiunVueUcharts
    },

    data() {

        return {
            shopId: '',
            form: {
                name: "",

            },
            opts2: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 30, 0, 5],
                enableScroll: false,
                legend: {},
                xAxis: {
                    boundaryGap: "justify",
                    disableGrid: false,
                    min: 0,
                    axisLine: false,
                    max: 40
                },
                yAxis: {},
                extra: {
                    bar: {
                        type: "group",
                        width: 30,
                        meterBorde: 1,
                        meterFillColor: "#FFFFFF",
                        activeBgColor: "#000000",
                        activeBgOpacity: 0.08,
                        linearType: "custom",
                        barBorderCircle: true,
                        seriesGap: 2,
                        categoryGap: 2
                    }
                }
            },
            rules: [{}],
            tableData: [],
            dataSun1: {},
            dataSun2: {},
            price: "",
            chartData2:{},
            takeStockNumber:''

        }
    },
    mounted() {
        this.price = JSON.parse(localStorage.getItem("user")).glodPrice;
        // 在 mounted 钩子中创建图表
        this.createChart();
        this.createChart2();
        this.getGoods();
        this.getShopData();
        this.getServerData2();
    },
    methods: {
        //金价动态
        createChart() {

            let data = {
                shopId: localStorage.getItem("shopId")
            }
            this.$api.post("good/getPriceTop", data, (res) => {
                let a = []
                let b = []
                res.data.forEach(e => {
                    a.push(e.timeStr)
                    b.push(e.price)
                });
                cht(a, b)
            })


            let that = this;
            function cht(a, b) {
                console.log(a);
                console.log(b);
                // 获取 canvas 元素
                const ctx = that.$refs.chartCanvas.getContext('2d');
                // 创建图表实例
                that.chart = new Chart(ctx, {
                    type: 'line', // 图表类型为折线图
                    data: {
                        labels: a,
                        datasets: [{
                            label: '单位(￥)',
                            data: b,
                            fill: false, // 不填充区域
                            borderColor: 'rgb(75, 192, 192)', // 折线颜色
                            tension: 0.1, // 线条曲率
                            pointStyle: 'circle', // 点的样式为圆形
                            pointBackgroundColor: 'rgba(75, 192, 192, 0.2)', // 点的背景色
                            pointBorderColor: 'rgb(75, 192, 192)', // 点的边框颜色
                            pointBorderWidth: 2, // 点的边框宽度
                            pointRadius: 5, // 点的半径
                            pointHoverRadius: 8 // 鼠标悬停时点的半径
                        }],
                        options: {
                            plugins: {
                                legend: {
                                    display: false // 关闭图例
                                }
                            }
                        }
                    }
                });
            }
        },
        //推广率
        createChart2() {
            let data = {
                shopId: localStorage.getItem("shopId")
            }
            this.$api.post("crm/getCrmOk", data, (res) => {

                cht(res.data)
            })


            let that = this;
            function cht(val) {


                // 获取 canvas 元素
                const ctx = that.$refs.chartCanvas2.getContext('2d');

                // 创建图表实例
                new Chart(ctx, {
                    type: 'pie', // 图表类型为饼状图
                    data: {
                        labels: ['未响应', '接受邀请'],
                        datasets: [{
                            label: '人数',
                            data: [val.okNo, val.ok], // 数据
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 206, 86)',
                                'rgb(75, 192, 192)',
                                'rgb(153, 102, 255)'
                            ],
                            hoverOffset: 4 // 鼠标悬停时偏移量
                        }]
                    },
                    options: {
                        responsive: true, // 启用响应式
                        maintainAspectRatio: false // 不维持纵横比
                    }
                });
            }
        },
        //获取销量高的商品
        getGoods() {
            let data = {
                shopId: localStorage.getItem("shopId")
            }
            this.$api.post("good/getGoodsTop", data, (res) => {

                this.tableData = res.data;
            })

        },
        //获取进店店铺简要数据、以及总览商品
        getShopData() {
            var today = new Date();
            // 计算昨天的日期  
            var yesterday = new Date(today);
            // 获取年月日  
            var year = yesterday.getFullYear();
            var month = yesterday.getMonth() + 1; // 月份从0开始，所以要加1  
            var date = yesterday.getDate();
            // 格式化输出年月日  
            var formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
            console.log(formattedDate);
            let data = {
                shopId: localStorage.getItem("shopId"),
                timeDay: formattedDate,
            }

            this.$api.post("crm/getShopdata", data, res => {
                console.log(res.data);
                this.dataSun2 = res.data;
            })
            this.$api.post("crm/getShopInfoSun", data, (res) => {

                this.dataSun1 = res.data;
            })

        },
        getServerData2() {
            let data = {
                shopId: localStorage.getItem("shopId")
            }
            this.$api.post("shop/getDynamicE", data, (res) => {
                let name = [];
                let value = [];
                res.data.date.forEach(e => {
                    name.push(e.nickname)
                    value.push(e.pageView)
                });
                let res2 = {
                    categories: name,
                    series: [
                        {
                            name: "商品浏览量",
                            data: value
                        },

                    ]
                };
                this.chartData2 = JSON.parse(JSON.stringify(res2));
            })
            this.$api.post("shop/getTakeStockE", data, (res) => {
                this.takeStockNumber = res.data.reverse()[0].number;

            })



        },
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    height: calc(100% - 60px);
    background: #f6f6f6;
    padding: 0px !important;
    position: relative;
    text-align: left;
    /* border:1px solid red; */
}

.el-tab-pane {
    height: calc(100vh - 160px);
}

.box1 {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;

}

.box11 {
    display: flex;
    justify-content: space-between;
    width: 49.5%;
    height: 120px;
    /* border: 1px solid; */
    position: relative;
    background: #fff;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.box111 {
    width: 25%;
    height: 65px;
    margin: 10px;
    /* border: 1px solid; */
    display: inline-block;
    margin-top: 38px;
    text-align: center;

}

.box2 {
    width: 100%;
    height: calc(100vh - 250px);
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    position: relative;
    /* padding:5px; */
}

.box22 {
    height: 100%;
    /* border:1px solid;   */
    background: #fff;
    position: relative;
    /* border: 1px solid red; */
}

.title {
    position: absolute;
    font-size: 15;
    font-weight: bold;
    top: 10px;
    left: 20px;
}

.text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
}

.textNub {
    font-size: 22px;
    font-weight: bold;
    color: rgb(75, 192, 192);
    line-height: 38px;
}

.box222 {
    /* border:1px solid red; */
    width: 100%;
    height: 49%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.box2222 {
    /* border: 1px solid red; */
    width: 49.5%;
    height: calc(100% - 62px);
    background: #fff;
    display: inline-block;
    position: relative;
    padding: 30px 0px;
}

.goodName {
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 文字溢出时省略号显示 */
}
</style>