<template>
    <div class="hotspot-image" v-if="imageSrc">
        <img :src="imageSrc" style="width: 100%;-webkit-user-drag: none; /* Safari */
  -khtml-user-drag: none; 
  -moz-user-drag: none; 
  -o-user-drag: none; 
  user-drag: none; " alt="图片链接有问题" ref="image" @load="onImageLoad" @error="handleImageError">
        <div v-for="(area, index) in hotspots" :key="index" class="hotspot-area" :style="{
        left: area.left + 'px',
        top: area.top + 'px',
        width: area.width + 'px',
        height: area.height + 'px'
    }" @mousedown="startDrag(index, $event)">
            <div class="resize-handle" @mousedown.stop.prevent="startResize(index, $event)"></div>
            <button class="delete-button" @click="deleteHotspot(index)">x</button>
            <button class="selTitle">选区{{ index + 1 }}</button>
        </div>
        <button class="add-button" @click="addHotspot">新增选区</button>
    </div>
</template>

<script>
export default {
    props: ["imageSrc"],
    data() {
        return {
            // imageSrc: 'https://myuser.obs.cn-north-4.myhuaweicloud.com/截屏2024-03-14 16.21.18.png_117104044925390', // 图片地址
            hotspots: [], // 选区列表
            activeHotspotIndex: null, // 当前活动的选区索引
            dragStartX: 0, // 拖动起始X坐标
            dragStartY: 0, // 拖动起始Y坐标
            resizeStartX: 0, // 调整大小起始X坐标
            resizeStartY: 0, // 调整大小起始Y坐标
            resizeStartWidth: 0, // 调整大小起始宽度
            resizeStartHeight: 0, // 调整大小起始高度
        };
    },
    methods: {
        onImageLoad() {
            // 图片加载后初始化选区列表
            // this.hotspots = [
            //     { left: 50, top: 50, width: 100, height: 100 },
            //     { left: 200, top: 100, width: 150, height: 150 }
            //     // 可以根据需要初始化更多选区
            // ];
            this.hotspots = [
                {
                    left: 50, top: 50, width: 100, height: 100, skipUrl: {
                        chooseS: false,
                        showText: '',
                        typeId: '0',
                        typeShow: ['0', '1', '2', '3', '4'],
                        type: '',
                        value: '',
                    },
                },
                {
                    left: 200, top: 100, width: 150, height: 150, skipUrl: {
                        chooseS: false,
                        showText: '',
                        typeId: '0',
                        typeShow: ['0', '1', '2', '3', '4'],
                        type: '',
                        value: '',
                    },
                }
                // 可以根据需要初始化更多选区
            ];
        },
        handleImageError(event) {
            // 图片加载失败时，将备用图片地址赋值给图片元素的src属性
            event.target.src = "https://myuser.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0.png_117109942543900";
        },
        startDrag(index, event) {
            // 开始拖动选区
            this.activeHotspotIndex = index;
            this.dragStartX = event.pageX - this.hotspots[index].left;
            this.dragStartY = event.pageY - this.hotspots[index].top;
        },
        startResize(index, event) {
            // 开始调整选区大小
            this.activeHotspotIndex = index;
            this.resizeStartX = event.pageX;
            this.resizeStartY = event.pageY;
            this.resizeStartWidth = this.hotspots[index].width;
            this.resizeStartHeight = this.hotspots[index].height;
            event.stopPropagation();
        },
        onMouseMove(event) {
            if (this.activeHotspotIndex !== null) {
                const mouseX = event.pageX;
                const mouseY = event.pageY;
                const hotspot = this.hotspots[this.activeHotspotIndex];
                if (this.resizeStartWidth && this.resizeStartHeight) {
                    const newWidth = this.resizeStartWidth + (mouseX - this.resizeStartX);
                    const newHeight = this.resizeStartHeight + (mouseY - this.resizeStartY);
                    // 调整选区大小
                    hotspot.width = Math.max(10, newWidth);
                    hotspot.height = Math.max(10, newHeight);
                    this.checkOverlap();
                } else {
                    // 移动选区
                    hotspot.left = Math.max(0, Math.min(this.$refs.image.clientWidth - hotspot.width, mouseX - this.dragStartX));
                    hotspot.top = Math.max(0, Math.min(this.$refs.image.clientHeight - hotspot.height, mouseY - this.dragStartY));
                    this.checkOverlap();
                }
            }
        },
        onMouseUp() {
            // 停止拖动或调整大小
            this.activeHotspotIndex = null;
            this.resizeStartWidth = 0;
            this.resizeStartHeight = 0;
        },
        checkOverlap() {
            // 检查选区重叠并调整位置
            for (let i = 0; i < this.hotspots.length; i++) {
                if (i !== this.activeHotspotIndex) {
                    const active = this.hotspots[this.activeHotspotIndex];
                    const target = this.hotspots[i];
                    if (this.isOverlap(active, target)) {
                        // 如果发生重叠，调整选区位置
                        if (active.left + active.width > target.left && active.left < target.left + target.width) {
                            if (active.top + active.height > target.top && active.top < target.top + target.height) {
                                const newX = active.left < target.left ? target.left - active.width : target.left + target.width;
                                const newY = active.top < target.top ? target.top - active.height : target.top + target.height;
                                active.left = Math.max(0, Math.min(this.$refs.image.clientWidth - active.width, newX));
                                active.top = Math.max(0, Math.min(this.$refs.image.clientHeight - active.height, newY));
                            }
                        }
                    }
                }
                console.log("选区重叠");
            }
        },
        isOverlap(rect1, rect2) {
            // 检查两个矩形是否重叠
            return (
                rect1.left < rect2.left + rect2.width &&
                rect1.left + rect1.width > rect2.left &&
                rect1.top < rect2.top + rect2.height &&
                rect1.top + rect1.height > rect2.top
            );
        },
        addHotspot() {
            // 添加新选区
            this.hotspots.push({
                left: 50, top: 50, width: 100, height: 100, skipUrl: {
                    chooseS: false,
                    showText: '',
                    typeId: '0',
                    typeShow: ['0', '1', '2', '3', '4'],
                    type: '',
                    value: '',
                },
            });
        },
        deleteHotspot(index) {
            // 删除选区
            this.hotspots.splice(index, 1);
        }
    },
    mounted() {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    },
    beforeDestroy() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    },
    watch: {
        hotspots: {
            handler(newValue, oldValue) {
                // console.log('用户信息发生变化：', newValue);
                // 在这里可以做一些逻辑处理
                this.$emit('dataChange', newValue)
            },
            deep: true // 深度监听对象的变化
        }
    }
};
</script>
<style scoped>
.hotspot-image {
    position: relative;
    display: inline-block;
    min-height: 100px;
}

.hotspot-area {
    position: absolute;
    border: 1px solid red;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.delete-button {
    position: absolute;
    top: 0px;
    right: 0px;
    /* padding: 5px; */
    background-color: red;
    color: white;
    border: none;
    /* border-radius: 50%; */
    cursor: pointer;
}

.selTitle {
    position: absolute;
    top: 0px;
    left: 0px;
    /* padding: 5px; */
    background-color: red;
    color: white;
    border: none;
    /* border-radius: 50%; */
    cursor: pointer;
    font-size: 12px;
}

.add-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px;
    /* background-color: green; */
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    cursor: pointer;
}
</style>