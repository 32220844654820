<template>
  <div class="main">
    <div class="box">
      <div class="title">商品分类</div>
      <div class="goodTypeBox">
        <div v-for="item in typeArr" :key="item.id" class="goodType" @click="selectTypeFun(item)" v-bind:class="{ selectClass: selectType == item.id }">
          {{ item.name }}
          <div v-if="goodArr.typeId == item.id" class="img">
            <img src="@/assets/icon/dui.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box">
        <div class="title">商品分组</div>
      </div> -->

    <div class="box">
      <div class="title">基本信息</div>

      <div class="goodBox">
        <span class="goodTitle">商品名称:</span>
        <span class="goodInput">
          <el-input size="small" maxlength="20" show-word-limit class="inputBox" v-model="goodArr.goodName" placeholder="请输入商品名称"></el-input>
          <div class="title2">准确选择商品类目有助于完善商品信息</div>
        </span>
      </div>
      <div class="goodBox">
        <span class="goodTitle">商品质量:</span>
        <span class="goodInput2">
          <el-input size="small" class="inputBox21" type="number" v-model.number="goodArr.weight" placeholder="质量"></el-input>
          <!-- <div class="title2">准确选择商品类目有助于完善商品信息</div> -->
        </span>
        <span class="goodTitle">商品库存:</span>
        <span class="goodInput2" style="left: 15px; width: 150px">
          <el-input size="small" class="inputBox21" type="number" v-model.number="goodArr.inventory" placeholder="库存"></el-input>
          <!-- <div class="title2">准确选择商品类目有助于完善商品信息</div> -->
        </span>
        <span class="goodTitle">商品编号:</span>
        <span class="goodInput2" style="left: 15px">
          <el-input size="small" class="inputBox2" clearable v-model="goodArr.productId" placeholder="请输入商品编号"></el-input>
          <!-- <div class="title2">准确选择商品类目有助于完善商品信息</div> -->
        </span>
      </div>

      <div class="goodBox">
        <span class="goodTitle">商品价格:</span>
        <span class="goodInputColor">
          <el-tabs v-model="goodArr.priceType">
            <el-tab-pane label="一口价" name="1">
              <span class="goodTitle2">整体价格：</span>
              <el-input size="small" class="inputBox3" v-model.number="goodArr.price" placeholder="请输入内容"></el-input>
            </el-tab-pane>
            <el-tab-pane label="每克工费+每克金价" name="2">
              <span class="goodTitle2">每克工费：</span>

              <el-input size="small" class="inputBox3" v-model.number="goodArr.makePrice" placeholder="请输入内容"></el-input>
              <span class="formula">（{{ goodArr.makePrice }}+{{ glodPrice }}） × {{ goodArr.weight }} = {{ (goodArr.makePrice + glodPrice) * goodArr.weight }}元</span>
            </el-tab-pane>

            <el-tab-pane label="每克金价+整体工费" name="3">
              <span class="goodTitle2">整体工费：</span>

              <el-input size="small" class="inputBox3" v-model.number="goodArr.makePrice" placeholder="请输入内容"></el-input>
              <span class="formula">（{{ goodArr.weight }} × {{ glodPrice }}）+ {{ goodArr.makePrice }} = {{ goodArr.makePrice + goodArr.weight * glodPrice }}元</span>
            </el-tab-pane>
          </el-tabs>
        </span>
      </div>
      <!-- 商品属性 -->
      <div class="goodBox">
        <span class="goodTitle">商品属性:</span>
        <span class="goodInputColor">
          <div class="styleBox">
            <span v-for="item in AttributeData" :key="item.id" class="attributeBox">
              <template v-if="item.attributeType == '1'">
                <span class="attributeBoxFont">{{ item.name }}：</span>
                <el-radio v-for="(item2, index) in JSON.parse(item.attributeValue).value" :key="index" style="position: relative; left: 4px" v-model="item.value" :label="item2.title">{{ item2.title }}</el-radio>
              </template>

              <template v-if="item.attributeType == '2'">
                <span class="attributeBoxFont">{{ item.name }}：</span>
                <el-select v-model="item.value" :placeholder="'请选择' + input" size="mini">
                  <el-option v-for="item2 in JSON.parse(item.attributeValue).value" :key="item2.id" :label="item2.label" :value="item2.label"> </el-option>
                </el-select>
              </template>
            </span>
          </div>
        </span>
      </div>
      <!-- 商品视频 -->
      <div class="goodBox">
        <span class="goodTitle">商品视频:</span>
        <span class="goodInput3">
          <div class="upload2" v-for="(item, index) in mp4List" :key="item.url">
            <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset="" @click="mp4List.splice(index, 1)" />
            <Video class="img" v-show="true" :videoSrc="item.url" :width="100" :height="100" :autoplay="true" :controls="true" :loop="false" :muted="false" preload="auto" :showPlay="true" :playWidth="96" zoom="contain"> </Video>
          </div>

          <div class="upload" @click="(uploadObsShow = true), (obsType = '视频')">
            <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="" />
          </div>
        </span>
      </div>
      <!-- 商品图片 -->
      <div class="goodBox">
        <span class="goodTitle">商品图片:</span>
        <span class="goodInput3">
          <!-- 主图 -->
          <div class="upload2" v-if="goodArr.imgMain">
            <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset="" @click="goodArr.imgMain = ''" />
            <el-image class="img" :src="goodArr.imgMain" alt="" srcset="" :preview-src-list="srcList" style="width: 100px; height: 100px"></el-image>
          </div>
          <div v-else class="upload" @click="(uploadObsShow = true), (imgType = '1'), (obsType = '图片')">
            <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="" />
          </div>

          <!-- 其他图片 -->
          <div class="upload2" v-for="(item, index) in imgList" :key="item.url">
            <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset="" @click="imgList.splice(index, 1)" />
            <el-image class="img" :src="item.url" alt="" srcset="" :preview-src-list="srcList" style="width: 100px; height: 100px"></el-image>
          </div>
          <div v-if="imgList.length < 5" class="upload" @click="(uploadObsShow = true), (imgType = '2'), (obsType = '图片')">
            <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="" />
          </div>
        </span>
      </div>
      <!-- 商品说明 -->
      <div class="goodBox">
        <span class="goodTitle">文字描述:</span>
        <span class="goodInput">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="goodArr.remarks"> </el-input>
        </span>
      </div>
    </div>

    <div class="box">
      <div class="title">确定上传</div>

      <!-- <span class="groupingBox">
        <span>选择分组：</span>
        <el-select size="small" v-model="goodArr.groupingId" placeholder="请选择">
          <el-option v-for="item in groupingArr" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </span> -->

      <el-button v-if="buttonType == '新增'" class="saveButton1" size="small" @click="save('0')" type="primary">保存</el-button>
      <el-button v-if="buttonType == '新增'" class="saveButton2" size="small" @click="save('1')" type="primary">上架并保存</el-button>
      <el-button v-if="buttonType == '修改'" class="saveButton2" size="small" @click="upGood()" type="primary">修改</el-button>
    </div>

    <!-- 上传图片弹窗 -->
    <el-dialog :title="`选择${obsType}`" :visible.sync="uploadObsShow" width="800px">
      <obsUpload v-if="uploadObsShow" :propsObsType="obsType" @getObsUrl="getObsUrl" @close="uploadObsShow = false" />
    </el-dialog>
  </div>
</template>

<script>
import obsUpload from "@/components/obsUpload.vue";
import Video from "@/components/video";
export default {
  components: { obsUpload, Video },
  data() {
    return {
      groupingArr: [],
      typeArr: [],
      selectType: "",
      input: "",
      shopId: "",
      activeName: "2",
      uploadObsShow: false,
      imgList: [],
      mp4List: [],
      obsType: "图片",
      srcList: [],
      RAdio: [],
      AttributeData: [],
      RAdioSelect1: "",
      value: "",
      imgType: "1",
      goodArr: {
        goodName: "",
        typeId: "",
        weight: "",
        productId: "",
        priceType: "2",
        makePrice: "",
        price: "",
        UrlTv: "",
        UrlImg: "",
        remarks: "",
        groupingId: "",
        imgMain: "",
        inventory: "1",
      },
      goodArrCopy: {
        goodName: "",
        typeId: "",
        weight: "",
        productId: "",
        priceType: "2",
        makePrice: "",
        price: "",
        UrlTv: "",
        UrlImg: "",
        remarks: "",
        groupingId: "",
        imgMain: "",
        inventory: "1",
      },
      glodPrice: JSON.parse(localStorage.getItem("user")).glodPrice,
      buttonType: "新增",
    };
  },
  //销毁
  beforDestroy() {
    this.$bus.$off("getdata");
  },
  mounted() {
    this.randomNumber()
    this.shopId = localStorage.getItem("shopId");
    this.goodType();
    this.getAttribute();
    // this.getGoodGrouping();
    let temporaryData = localStorage.getItem("temporaryData");
    console.log(temporaryData !== "");
    if (temporaryData) {
      this.buttonType = "修改";
      this.goodArr = JSON.parse(temporaryData);
      let imgList = JSON.parse(this.goodArr.UrlImg);
      let arr = [];
      let arr2 = [];
      imgList.forEach((o, i) => {
        arr.push(o);
        arr2.push({
          url: o,
          id: i,
        });
      });
      this.srcList = arr;
      this.imgList = arr2;
      localStorage.setItem("temporaryData", "");
    } else {
      this.buttonType = "新增";
    }
  },
  methods: {
    //商品编号随机数
    randomNumber(){
  //随机数
  var randomNumber = Math.floor(Math.random() * 100000000);
    var randomString = randomNumber.toString();
    while (randomString.length < 8) {
        randomString = '0' + randomString;
    }
    this.goodArr.productId = randomString;
    },
    //获取分类
    goodType() {
      let data = {
        shopId: this.shopId,
      };
      this.$api.post("good/getGoodType", data, (res) => {
        res.data = res.data.filter((o) => o.showType == 1);
        res.data.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.typeArr = res.data;
        if (this.buttonType == "新增") {
          this.goodArr.typeId = res.data[0].id;
        }
      });
    },
    //获取属性
    getAttribute() {
      let data = {
        shopId: this.shopId,
      };
      this.$api.post("good/getGoodAttribute", data, (res) => {
        let arr = [];
        res.data.forEach((o) => {
          if (o.attributeShow == 1) {
            o.value = JSON.parse(o.attributeValue).SelectValue;
            arr.push(o);
          }
        });
        this.AttributeData = arr;
      });
    },
    //获取分组
    // getGoodGrouping() {
    //   let data = {
    //     shopId: this.shopId,
    //   };
    //   this.$api.post("good/getGoodGrouping", data, (res) => {
    //     let arr = [];
    //     res.data.forEach((o) => {
    //       if (o.showGrouping == 1) {
    //         arr.push(o);
    //       }
    //     });
    //     this.groupingArr = arr;
    //   });
    // },
    selectTypeFun(data) {
      this.selectType = data.id;
      this.goodArr.typeId = data.id;
    },
    //获取obs
    getObsUrl(data) {
      this.uploadObsShow = false;
      console.log(data);
      if (this.obsType == "视频") {
        data.forEach((o) => {
          if (!this.mp4List.some((e) => e.url == o.url)) {
            this.mp4List.push(o);
          }
        });
      } else if (this.obsType == "图片") {
        if (this.imgType == "1") {
          this.goodArr.imgMain = data[0].url;
          return;
        }
        data.forEach((o) => {
          if (!this.imgList.some((e) => e.url == o.url)) {
            this.srcList.push(o.url);
            this.imgList.push(o);
          }
        });
      }
    },
    //数据校验
    check() {
      let imgArr = [];
      this.imgList.forEach((o) => {
        imgArr.push(o.url);
      });
      this.goodArr.UrlImg = imgArr;
      let tvArr = [];
      this.mp4List.forEach((o) => {
        tvArr.push(o.url);
      });
      this.goodArr.UrlTv = tvArr;
      let obj = this.goodArr;
      if (obj.typeId == "") {
        this.$message.error("请先选择 商品分类");
        return true;
      }
      if (obj.goodName == "") {
        this.$message.error("商品名称 不能为空！");
        return true;
      }
      if (obj.inventory == "") {
        this.$message.error("商品库存 不能为空！");
        return true;
      }
      if (obj.priceType !== "1") {
        if (obj.weight == "") {
          this.$message.error("商品重量 不能为空！");
          return true;
        }
      } else {
        obj.weight =obj.weight|| "0";
      }

      if (obj.productId == "") {
        this.$message.error("商品编号 不能为空！");
        return true;
      }
      if (obj.priceType == "1" && obj.price == "") {
        this.$message.error("商品价格 不能为空！");
        return true;
      }
      if (obj.priceType !== "1" && obj.makePrice == "") {
        this.$message.error("制作工费 不能为空！");
        return true;
      }
      if (obj.imgMain == "") {
        this.$message.error("商品主图，必须上传");
        return true;
      }

      return false;
    },
    //保存
    save(state) {
      if (this.check()) return;
      let data = JSON.parse(JSON.stringify(this.goodArr));
      data.shopId = this.shopId;
      let AttributeData = JSON.parse(JSON.stringify(this.AttributeData));
      AttributeData.forEach(o => {
        let val=''
        if(typeof o.attributeValue === 'string'){
            val = JSON.parse(o.attributeValue);
        }else{
            val = o.attributeValue;
        }
        val.SelectValue = o.value;
        o. attributeValue= val;
      });
      data.attribute = JSON.stringify(AttributeData);
      data.UrlImg = JSON.stringify(this.goodArr.UrlImg);
      data.UrlTv = JSON.stringify(this.goodArr.UrlTv);
      data.makePrice = this.goodArr.makePrice || 0;
      data.price = this.goodArr.price || 0;
      data.groupingId = this.goodArr.groupingId || 0;
      data.state = state;
      data.memberId = JSON.parse(localStorage.getItem("user")).id;
      console.log(data);
      this.$api.post("good/addGood", data, (res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.messageOpen();
        } else {
          this.$message.error("新增失败");
        }
      });
    },
    //更新商品
    upGood() {
      if (this.check()) return;
      let data = JSON.parse(JSON.stringify(this.goodArr));
      let AttributeData = JSON.parse(JSON.stringify(this.AttributeData));
      AttributeData.forEach(o => {
        let val=''
        if(typeof o.attributeValue === 'string'){
            val = JSON.parse(o.attributeValue);
        }else{
            val = o.attributeValue;
        }
        val.SelectValue = o.value;
        o. attributeValue= val;
      });
      data.attribute = JSON.stringify(AttributeData);
      data.shopId = this.shopId;
      // data.attribute = JSON.stringify(this.AttributeData);
      data.UrlImg = JSON.stringify(this.goodArr.UrlImg);
      data.UrlTv = JSON.stringify(this.goodArr.UrlTv);
      data.makePrice = this.goodArr.makePrice || 0;
      data.price = this.goodArr.price || 0;
      data.groupingId = this.goodArr.groupingId || 0;
      data.memberId = JSON.parse(localStorage.getItem("user")).id;
      console.log(data);
      this.$api.put("good/upGood", data, (res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
          this.$router.push({ name: "goodManagement" });
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    messageOpen() {
      this.$confirm("恭喜您，商品上传成功！", "上传成功", {
        confirmButtonText: "查看",
        cancelButtonText: "继续上传",
        type: "warning",
      })
        .then(() => {
          this.$router.push({ name: "goodManagement" });
          this.$emit("iconShow", "goodManagement");
        })
        .catch(() => {
          this.goodArr = JSON.parse(JSON.stringify(this.goodArrCopy));
        });
    },
  },
};
</script>

<style scoped>
.main {
  width: calc(100% - 20px);
  height: calc(100% - 55px);
  padding: 0px;
  position: relative;
  text-align: left;
}

.groupingBox {
  position: absolute;
  top: 15px;
  right: 196px;
  display: inline-block;
}

.saveButton1 {
  position: absolute;
  right: 125px;
  top: 15px;
}

.saveButton2 {
  position: absolute;
  right: 20px;
  top: 15px;
}

.attributeBox {
  /* border:1px solid red; */

  width: 290px;
  display: inline-block;
  margin: 10px 0;
}

.attributeBoxFont {
  /* border:1px solid #000; */
  width: 90px;
  display: inline-block;
  position: relative;
  text-align: right;
  color: #00228a;
  font-weight: bold;
  font-size: 15px;
}

.iconCuo2 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  cursor: pointer;
  z-index: 2;
}

.iconJia {
  position: relative;
  width: 30px;
  top: 35px;
}

.formula {
  font-weight: bold;
  font-size: 15px;
  color: #dbdada;
  position: relative;
  left: 20px;
}

.upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #e4e4e4;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
}

.upload2 {
  width: 100px;
  height: 100px;
  border: 1px dashed #fff;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.goodPriceBox {
  width: 200px;
  height: 80px;
  border: 1px dashed #593939;
  border-radius: 5px;
  text-align: center;
  margin-right: 20px;
  position: relative;
  display: inline-block;
}

.goodInputColor {
  display: inline-block;
  position: relative;
  width: 600px;
  background: #f5f7fa;
  left: 30px;
  border-radius: 5px;
  padding: 10px;
  display: inline-table;
}

.goodTypeBox {
  /* border:1px solid red; */
  width: calc(100% - 60px);
  left: 20px;
  position: relative;
}

.goodTypeBox .img {
  width: 30px;
  height: 30px;
  background: #409eff;
  position: absolute;
  bottom: -15px;
  right: -15px;
  transform: rotate(45deg);
}

.goodTypeBox .img img {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
  left: -8px;
  transform: rotate(-50deg);
}

.goodType {
  min-width: 50px;
  height: 30px;
  border: 1px solid #a7a3a3;
  border-radius: 5px;
  margin: 0 10px 5px 0;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 30px;
  padding: 5px 10px;
  cursor: pointer;
  overflow: hidden;
}

.goodType:hover {
  border: 1px solid #409eff;
}

.box {
  width: 100%;
  margin-bottom: 10px;
  background: #fff;
  padding: 10px;
  position: relative;
}

.savaBox {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #efefef;
}

.goodBox {
  width: 1000px;
  /* border: 1px solid red; */
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  left: 5px;
}

.title {
  position: relative;
  font-weight: bold;
  margin: 10px;
  margin-top: 5px;
}

.goodTitle {
  display: inline-table;
  position: relative;
  width: 70px;
  /* border:1px solid red; */
  text-align: left;
}

.goodTitle2 {
  display: inline-table;
  position: relative;
  width: 80px;
  /* border:1px solid red; */
  text-align: left;
}

.goodInput {
  display: inline-block;
  position: relative;
  width: 600px;
  /* border:1px solid red; */
  left: 30px;
  display: inline-table;
}

.goodInput2 {
  display: inline-block;
  position: relative;
  width: 160px;
  /* border:1px solid red; */
  left: 30px;
  display: inline-table;
}

.goodInput3 {
  position: relative;
  width: 900px;
  /* border:1px solid red; */
  left: 30px;
  display: flex;
}

.inputBox {
  width: 400px;
}

.inputBox2 {
  width: 185px;
}

.inputBox21 {
  width: 100px;
}

.inputBox3 {
  width: 200px;
  margin: 5px 0 10px;
}

.title2 {
  margin-top: 4px;
  color: #8a9099;
  line-height: 22px;
  font-size: 12px;
}

.selectClass {
  border: 1px solid #409eff;
  color: #409eff;
  background: #51a7fe12;
}
</style>
