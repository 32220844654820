//定义url域名前缀
//var url = 'http://124.71.98.245:8080/';
//var url = 'http://192.168.1.7:3001/';
//
//var url = 'http://localhost:3001/';
var url = 'https://gold.ddxx.fun/gold/';
let date = new Date();
let month = date.getMonth() + 1; // 月份从0开始，所以需要加1  
let day = date.getDate();
let weekday = date.getDay(); // 星期几从0开始，所以需要加1 
let key1 = `${month + 1}${weekday + 2}${day + 3}`;
let key2 = `${month + 3}${weekday + 2}${day + 1}`;
let key = key1 * key2 * day * 3;

import axios from 'axios'
export default {
    //定义默认数据
    shopId: '',
    userId: '',
    openId: '',
    uuid: require('uuid'),
    //进行封装请求
    get(fileName, data, getData) {
        //console.log(axios);
        axios.get(url + fileName, {
            params: data,
            header: {
                "key": key,
            },
        }).then(
            (res) => {
                getData(res.data);
            },
            (err) => {
                console.log("网络数据请求错误！");
                console.log(err);

            }
        );

    },

    post(fileName, data, getData) {
        axios.post(url + fileName, JSON.stringify(data), {
            headers: {
                "key": key,
                "token": localStorage.getItem("token"),
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: data
        }).then(
            (res) => {
                if (res.data.code == 404) {
                    localStorage.removeItem("token")
                }
                getData(res.data);
            },
            (err) => {
                console.log("网络数据请求错误！");
                console.log(err);

            }
        );

    },
    put(fileName, data, getData) {
        axios.put(url + fileName, JSON.stringify(data), {
            headers: {
                "key": key,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: data
        }).then(
            (res) => {
                if (res.data.code == 404) {
                    localStorage.removeItem("token")
                }
                getData(res.data);
            },
            (err) => {
                console.log("网络数据请求错误！");
                console.log(err);

            }
        );

    },
    del(fileName, data, getData) {
        axios.delete(url + fileName, {
            headers: {
                "key": key,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: data
        }).then(
            (res) => {
                if (res.data.code == 404) {
                    localStorage.removeItem("token")
                }
                getData(res.data);
            },
            (err) => {
                console.log("网络数据请求错误！");
                console.log(err);

            }
        );

    },

    apiOther(fileName, data, getData) {
        //console.log(axios);
        axios.get(fileName, {
                params: data,
            })
            .then(
                (res) => {
                    getData(res);
                },
                (err) => {
                    console.log("网络数据请求错误！");
                    console.log(err);

                }
            );

    },
}