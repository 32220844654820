<template>
    <div class="main">
        <el-button size="mini" class="addButton" @click="addShowFun()" type="primary">新增员工</el-button>
        <el-button size="mini" class="addButton" @click="tableData = [], getAdmin()">刷新</el-button>
        <!-- <el-button size="mini" class="addButton" @click="getGoodType()" type="primary">获取分类</el-button> -->
        <div class="table">

            <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="date" label="头像" width="80">
                    <template scope="scope">
                        <div class="goodBox">
                            <el-image fit="cover" :src="scope.row.userImg+'?x-image-process=image/resize,m_lfit,h_100'" :preview-src-list="[scope.row.userImg]"
                                class="goodImg"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" >
                </el-table-column>
                <el-table-column prop="userName" label="登录手机号">
                </el-table-column>
                <!-- <el-table-column prop="type" label="角色类型" width="280">
                    <template scope="scope">
                        <div v-if="scope.row.type == '1'">管理员</div>
                        <div v-if="scope.row.type == '2'">店长</div>
                        <div v-if="scope.row.type == '3'">员工</div>
                    </template>
</el-table-column> -->
                <el-table-column prop="ShopName" label="所属店铺">
                </el-table-column>
                <el-table-column prop="ShopName" label="小程序">
                    <template slot-scope="scope">
                        <div style="color:blue" v-if="scope.row.openId">已授权</div>
                        <div v-else>未授权</div>
                    </template>
                </el-table-column>


                <el-table-column prop="address" width="380" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.type !== '1'" size="mini" @click="setAdmin(scope.row)">编辑</el-button>
                        <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info"
                            icon-color="red" title="确定删除该管理员吗，请谨慎操作！" @confirm="delAdmin(scope.row)">
                            <el-button v-if="scope.row.type !== '1'" size="mini" slot="reference"
                                type="danger">删除</el-button>
                        </el-popconfirm>
                        <el-button size="mini" type="primary" plain @click="setPassWord(scope.row)">修改密码</el-button>
                        <!-- <el-button v-if="scope.row.openId" size="mini" type="danger" plain
                            @click="offSetAppOk(scope.row)">关闭小程序</el-button> -->
                        <el-popconfirm v-if="scope.row.openId" confirm-button-text='好的' cancel-button-text='不用了'
                            icon="el-icon-info" icon-color="red" title="确定取消小程序授权吗，请谨慎操作！"
                            @confirm="offSetAppOk(scope.row)">
                            <el-button size="mini" slot="reference" type="danger" plain>关闭小程序</el-button>
                        </el-popconfirm>
                        <el-button v-else size="mini" plain @click="setApp(scope.row)">授权小程序</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <!-- 新增弹窗 -->
        <el-dialog :title="titleText" :visible.sync="addShow" width="350px">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                
                <el-form-item label="姓 名" prop="name" maxlength="10" size="small">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item v-if="titleText == '新增员工'" label="手机号码" prop="phone" maxlength="10" size="small">
                    <el-input v-model.number="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="员工类型" prop="type" maxlength="10" size="small">
                    <el-select v-model="form.type" placeholder="权限类型">
                        <el-option label="店长" value="2"></el-option>
                        <el-option label="员工" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="员工头像" prop="userImg" maxlength="10" size="small">
                    <div class="upload2" v-if="form.userImg">
                        <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset=""
                            @click="form.userImg = ''">
                        <el-image class="img" :src="form.userImg" alt="" srcset="" :preview-src-list="srcList"
                            style="width: 100px; height: 100px;"></el-image>
                    </div>
                    <div v-else class="upload" @click="uploadObsShow = true, imgType = '1', obsType = '图片'">
                        <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="">
                    </div>
                </el-form-item>


            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addShow = false">取 消</el-button>
                <el-button size="small" v-if="titleText == '新增员工'" type="primary" @click="addAdmin()">确 定</el-button>
                <el-button size="small" v-else type="primary" @click="setAdminOk()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="form2Show" width="350px">
            <el-form :model="form2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
                <el-form-item label="设置密码" prop="passWord" size="small">
                    <el-input type="password" v-model="form2.passWord"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="passWord2" size="small">
                    <el-input type="password" v-model="form2.passWord2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="form2Show = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setPassWordOk()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 授权列表 -->
        <el-dialog title="选择授权对象" :visible.sync="addSetAppShow" width="750px">
            <el-table :data="tableData2" stripe style="width: 100%" @current-change="handleCurrentChange"
                highlight-current-row>
                <el-table-column prop="date" label="头像" width="80">
                    <template scope="scope">
                        <div class="goodBox">
                            <el-image fit="cover" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]"
                                class="goodImg"></el-image>
                        </div>
                    </template>
                </el-table-column>

                <!-- <el-table-column prop="goodName" label="商品名称">
                    <template scope="scope">
                        <div class="goodName">￥{{ scope.row.goodName }}</div>
                    </template>

                </el-table-column> -->
                <el-table-column prop="phone" label="手机号码">
                </el-table-column>
                <el-table-column prop="pageView" label="微信昵称">
                </el-table-column>
                <el-table-column prop="user_name" label="真实姓名">
                </el-table-column>
                <el-table-column prop="ipName" label="登录地址">
                </el-table-column>

            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addSetAppShow = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setAppOk()">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 上传图片弹窗 -->
        <el-dialog title="选择头像" :visible.sync="uploadObsShow" width="800px">
            <obsUpload v-if="uploadObsShow" propsObsType="图片" @getObsUrl="getObsUrl" @close="uploadObsShow = false" />
        </el-dialog>


    </div>
</template>

<script>
import obsUpload from "@/components/obsUpload.vue";
var phoneJuck = (rule, value, callback) => {
    var regExp = new RegExp("^1[3578]\\d{9}$");
    if (!(regExp.test(value))) {
        callback(new Error("手机号输入不规范"));
    } else {
        callback();
    }
};

export default {
    components: { obsUpload },

    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.form2.passWord2 !== '') {
                    this.$refs.ruleForm2.validateField('passWord2');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form2.passWord) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            shopId: '',
            tableData: [],
            tableData2: [],
            addShow: false,
            form: {},
            form2: {},
            form3: {},
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 3, message: '长度在 2 到 3 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: "请输入", trigger: "input", },
                    { required: true, validator: phoneJuck, trigger: "input", },
                ],
                type: [
                    { required: true, message: '请选择员工类型', trigger: 'change' }
                ],
                userImg: [
                    { required: true, message: '请选择员工头像', trigger: 'change' }
                ],
            },
            rules2: {
                passWord: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                passWord2: [
                    { validator: validatePass2, trigger: 'blur' }
                ],

            },
            form2: {},
            form2Show: false,
            addSetAppShow: false,
            uploadObsShow: false,
            titleText: "",
            selMemberId: "",
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId")
        this.getAdmin();
    },
    methods: {
        //查询
        getAdmin() {
            let data = {
                shopId: this.shopId,
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.tableData = res.data;
            })
        },
        addShowFun() {
            this.form = {
                name: "",
                phone: "",
                type: "",
                shopId: this.shopId,
                userImg:"",
            }
            this.titleText = "新增员工";
            this.addShow = true;
            this.$refs.ruleForm.resetFields();


        },
        //新增管理员
        addAdmin() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$api.post("user/addAdmin", this.form, (res) => {
                        if (res.code == 200) {
                            this.addShow = false;
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.getAdmin()
                        } else {
                            console.log(res.data);
                            this.$message.error(res.data);

                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });

        },
        //删除
        delAdmin(e) {
            this.$prompt('请输入当前账户登录密码，验证成功后方可进行操作', '身份认证', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                let data = {
                    "phone": localStorage.getItem("phone"),
                    "shopId": this.shopId,
                    "passWord": this.$fun.setCry(value)
                }
                this.$api.post("user/approve", data, (res) => {
                    if (res.code == 200) {
                        delFun()
                    } else {
                        this.$message.error('身份验证失败');
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消删除'
                });
            });
            let that = this;
            let phone = e.userName;
            function delFun() {
                let delForm = {
                    "phone": phone,
                    "shopId": that.shopId,
                }
                that.$api.del("user/delAdmin", delForm, (res) => {
                    if (res.code == 200) {
                        that.getAdmin()
                        that.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    } else {
                        that.$message.error('操作失败');
                    }
                })

            }



        },
        //修改密码
        setPassWord(e) {
            this.$prompt('请输入当前账户登录密码，验证成功后方可更改密码', '身份认证', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                let data = {
                    "phone": localStorage.getItem("phone"),
                    "shopId": this.shopId,
                    "passWord": this.$fun.setCry(value)
                }
                this.$api.post("user/approve", data, (res) => {
                    if (res.code == 200) {
                        this.form2 = {
                            "phone": e.userName,
                            shopId: this.shopId,
                            passWord: "",
                            passWord2: "",
                        }
                        this.form2Show = true;
                    } else {
                        this.$message.error('身份验证失败');
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消修改'
                });
            });
        },
        setPassWordOk() {
            this.$refs.ruleForm2.validate((valid) => {
                if (valid) {

                    let form = JSON.parse(JSON.stringify(this.form2));
                    form.passWord = this.$fun.setCry(this.form2.passWord);
                    this.$api.put("user/AdminPassWord", form, (res) => {
                        if (res.code == 200) {
                            this.form2Show = false;
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });
        },
        //编辑用户
        setAdmin(val) {
            this.titleText = "编辑数据",
                this.form = {
                    name: val.name,
                    phone: val.userName,
                    type: val.type,
                    shopId: this.shopId,
                }
            this.addShow = true;

        },
        setAdminOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$api.put("user/setAdmin", this.form, (res) => {
                        if (res.code == 200) {
                            this.addShow = false;
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.getAdmin()
                        } else {
                            this.$message.error(res.data.msg);

                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });
        },
        //授权小程序
        setApp(val) {
            console.log(val);
            this.form3 = {};
            this.selMemberId = val.id
            let data = {
                "phone": val.userName,
                "shopId": this.shopId,
            }
            this.$api.post("user/getAdminSetUser", data, (res) => {
                if (res.code == 200) {
                    this.tableData2 = res.data
                    this.addSetAppShow = true;
                } else {
                    this.$message.error(res.msg);
                }
            })

        },
        //选择对象
        handleCurrentChange(e) {
            console.log(e);
            this.form3 = {
                "memberId": this.selMemberId,
                "shopId": this.shopId,
                "openId": e.openId
            }
        },
        setAppOk() {
            if (!this.form3.shopId) {
                this.$message.error("请先选择对象");
                return
            }

            this.$api.put("user/AdminSetUser", this.form3, (res) => {
                if (res.code == 200) {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.getAdmin()

                    this.addSetAppShow = false;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        offSetAppOk(e) {
            console.log(e);

            let data = {
                "memberId": e.id,
                "shopId": e.shopId,
                "openId": e.openId
            }
            this.$api.put("user/offAdminSetUser", data, (res) => {
                if (res.code == 200) {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.getAdmin()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //选择图片
        getObsUrl(e) {
            console.log(e);
            this.form.userImg = e[0].url;
            this.uploadObsShow = false;
        },





    }

}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.upload {
    width: 100px;
    height: 100px;
    border: 1px dashed #e4e4e4;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
}

.iconJia {
    position: relative;
    width: 30px;
    top: 35px;

}
.upload2 {
  width: 100px;
  height: 100px;
  border: 1px dashed #fff;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}
.iconCuo2 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  cursor: pointer;
  z-index: 2;
}
</style>