<template>
    <div class="Box">
        <!-- 上传图片弹窗 -->
        <el-dialog :title="`选择图片`" :visible.sync="uploadObsShow" width="800">
            <obsUpload v-if="uploadObsShow" :propsObsType="obsType" @getObsUrl="getObsUrl"
                @close="uploadObsShow = false" />
        </el-dialog>
        <!-- 跳转链接弹窗 -->
        <el-dialog :title="`挂载对象`" :visible.sync="jumpWindowShow" width="70%">
            <jumpWindow v-if="jumpWindowShow" :config="temporaryObs" @okObsUrl="getjumpObs"
                @close="jumpWindowShow = false" />
        </el-dialog>
        <!-- 模块设置集 -->
        <div class="moduleSetBox" v-for="(itemSun, indexSun) in phoneData" :key="indexSun">
            <!-- 轮播图 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '轮播图'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">图片高度</span>
                    <el-slider :min="0" :max="800" class="M1_Slide" v-model="itemSun.data.imgHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.imgHeight"
                        controls-position="right" :min="0" :max="800"></el-input-number>
                </div>

                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>

                <div class="M3_Box">
                    <span>上传图片</span>

                    <div class="M3_upBox" v-for="(item, index) in itemSun.data.imgs" :key="index">
                        <img @click="DelIconFun(itemSun.data.imgs, index)" class="M3_iconDEl"
                            src="@/assets/icon/cuo2.png" alt="" srcset="">
                        <img class="M3_img" :src="item.url" alt="" srcset="">
                        <div class="M3_inputBox">
                            <el-input placeholder="请输入内容" v-model="item.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = item">添加</span>
                                </template>
                            </el-input>
                            <el-input style="margin-top:5px" disabled placeholder="请选择" v-model="item.skipUrl.showText"
                                size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input>
                        </div>
                        <div class="M3_xian"></div>
                    </div>
                    <div @click="M3_addImg(itemSun.data.imgs)" class="M3_addImgButton">添加一个</div>
                </div>

            </div>
            <!-- 搜索框 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '搜索框'">
                <div class="M2_SlideBox">
                    <span class="M2_demonstration">提示文字</span>
                    <el-input class="M2_input" size="small" style="width: 365px;" v-model="itemSun.data.text"
                        placeholder="请输入内容"></el-input>
                </div>


                <div class="M4_Box">
                    <span>字体颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">

                    <span class="M4_font">输入框颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.inputColor"></el-color-picker>
                    <el-button @click="itemSun.data.inputColor = itemSun.data.inputColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M1_SlideBox">
                    <span class="M1_demonstration">圆角弧度</span>
                    <el-slider max=20 size="mini" class="M1_Slide" v-model="itemSun.data.roundness"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.roundness"
                        controls-position="right" :min="0" :max="20"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider max=50 size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
                <div style="height: 5px;"></div>
                <div @click="" class="M5_addImgButton">恢复默认样式</div>


            </div>
            <!-- 图标导航 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '图标导航'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">图标弧度</span>
                    <el-slider size="small" class="M1_Slide" v-model="itemSun.data.radius"></el-slider>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">图标高度</span>
                    <el-slider size="small" class="M1_Slide" v-model="itemSun.data.modulePaddingTop"></el-slider>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>

                </div>
                <div class="M4_Box">
                    <span class="M4_font">字体颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M3_Box">
                    <span>上传图片</span>
                    <div class="M3_upBox" v-for="(item, index) in itemSun.data.imgs" :key="index">

                        <img @click="DelIconFun(itemSun.data.imgs, index)" class="M3_iconDEl"
                            src="@/assets/icon/cuo2.png" alt="" srcset="">
                        <img class="M3_img" :src="item.url" alt="" srcset="">
                        <div class="M3_inputBox">
                            <el-input placeholder="自定义图片URl" v-model="item.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = item">选择图片</span>
                                </template>
                            </el-input>
                            <el-input style="margin-top:5px" maxlength="4" show-word-limit placeholder="请输入标题名称"
                                v-model="item.text" size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">选择跳转链接</span>
                                </template>
                            </el-input>

                        </div>
                    </div>
                    <div @click="M3_addImg(itemSun.data.imgs)" class="M5_addImgButton">添加一个</div>
                </div>

            </div>
            <!-- 商品 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '商品'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                </div>
                <div class="M6_Box">
                    <span class="M6_font">显示类型</span>
                    <el-radio-group class="M6_radio" v-model="itemSun.data.type" size="mini">
                        <el-radio-button label=1>一行一个</el-radio-button>
                        <el-radio-button label=2>一行两个</el-radio-button>
                        <el-radio-button label=3>一行三个</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">商品标题颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>

                </div>
                <div class="M4_Box">
                    <span class="M4_font">商品价格颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.priceColor"></el-color-picker>
                    <el-button @click="itemSun.data.priceColor = itemSun.data.priceColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M2_SlideBox">
                    <span class="M2_demonstration">选择商品</span>
                    <el-input class="M2_input" disabled size="small" style="width: 365px;"
                        v-model="selectModule.data.skipUrl.showText" placeholder="请输入内容">
                        <template slot="append">
                            <span style="cursor: pointer" @click="temporaryObs = itemSun.data, JumpUrl()">请选择商品</span>
                        </template>
                    </el-input>
                    <!-- <el-input style="margin-top:5px" placeholder="请输入内容" v-model="item.skipUrl.showText"
                                size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">请选择商品</span>
                                </template>
                    </el-input> -->
                </div>



            </div>
            <!-- 图片拼接 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '图片拼接'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">图片高度</span>
                    <el-slider :min="0" :max="800" class="M1_Slide" v-model="itemSun.data.imgHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.imgHeight"
                        controls-position="right" :min="0" :max="800"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">图片内边距</span>
                    <el-slider :min="0" :max="50" class="M1_Slide" v-model="itemSun.data.paddingValue"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.paddingValue"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M6_Box">
                    <span class="M6_font">显示类型</span>
                    <el-radio-group class="M6_radio" v-model="itemSun.data.type" size="mini">
                        <el-radio-button label=1>单图横排</el-radio-button>
                        <el-radio-button label=2>双图并排</el-radio-button>
                        <el-radio-button label=3>三图并排</el-radio-button>
                        <el-radio-button label=4>三图拼接</el-radio-button>
                    </el-radio-group>
                </div>



                <div class="M3_Box">
                    <span>上传图片</span>

                    <div class="M3_upBox" v-for="(item, index) in itemSun.data.imgs" :key="index">
                        <img @click="DelIconFun(itemSun.data.imgs, index)" class="M3_iconDEl"
                            src="@/assets/icon/cuo2.png" alt="" srcset="">
                        <img class="M3_img" :src="item.url" alt="" srcset="">
                        <div class="M3_inputBox">
                            <el-input placeholder="请输入内容" v-model="item.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = item">添加</span>
                                </template>
                            </el-input>

                            <el-input style="margin-top:5px" placeholder="请输入内容" v-model="item.skipUrl.showText"
                                size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input>
                        </div>
                        <div class="M3_xian"></div>
                    </div>
                    <div @click="M3_addImg(itemSun.data.imgs)" class="M3_addImgButton">添加一个</div>
                </div>

            </div>
            <!-- 视频内容 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '视频内容'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider max=50 size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>


                <div class="M1_SlideBox">
                    <span class="M1_demonstration">圆角弧度</span>
                    <el-slider max=20 size="mini" class="M1_Slide" v-model="itemSun.data.roundness"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.roundness"
                        controls-position="right" :min="0" :max="20"></el-input-number>
                </div>
                <div class="M2_SlideBox">
                    <span class="M2_demonstration">选择商品</span>
                    <el-input class="M2_input" size="small" style="width: 365px;" v-model="itemSun.data.text"
                        placeholder="请输入内容">
                        <template slot="append">选择视频链接</template>
                    </el-input>
                </div>

            </div>
            <!-- 文字导航 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '文字导航'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">字体颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">
                    <span>选中按钮背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.selectColor"></el-color-picker>
                    <el-button @click="itemSun.data.selectColor = itemSun.data.selectColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">选中按钮字体颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.selectFontColor"></el-color-picker>
                    <el-button @click="itemSun.data.selectFontColor = itemSun.data.selectFontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">选中按钮圆角弧度</span>
                    <el-slider :max="15" size="mini" class="M1_Slide" v-model="itemSun.data.roundness"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.roundness"
                        controls-position="right" :min="0" :max="15"></el-input-number>
                </div>
                <div class="M6_Box">
                    <span class="M6_font">按钮编辑</span>
                    <!-- <el-radio-group class="M6_radio" v-model="itemSun.data.index" size="mini">
                        <el-radio-button v-for="(item, index) in itemSun.data.fontArr" :key="index"
                            :label="index">{{ item.name }}</el-radio-button>
                    </el-radio-group> -->
                    <draggable v-model="itemSun.data.fontArr" forceFallback="true">
                        <transition-group>
                            <div v-for="(item, index) in itemSun.data.fontArr" :key="index">

                                <el-input class="M7_input" size="small" v-model="item.name"></el-input>
                                <el-button icon="el-icon-rank" size="small" plain></el-button>
                            </div>
                        </transition-group>
                    </draggable>


                </div>
                <div class="M6_Box">
                    <span class="M6_font">选中按钮</span>
                    <el-radio-group class="M6_radio" v-model="itemSun.data.index" size="mini">
                        <el-radio-button v-for="(item, index) in itemSun.data.fontArr" :key="index" :label="index">{{
            item.name }}</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="M6_Box">
                    <span class="M6_font">显示类型</span>
                    <!-- <span class="M6_font">{{ itemSun.data.fontArr[0].type }}</span> -->
                    <!-- <el-radio-group class="M6_radio" @input="selType($event,itemSun.data.fontArr[itemSun.data.index])"
                    :value="itemSun.data.fontArr[itemSun.data.index].type"   size="mini"> -->
                    <el-radio-group class="M6_radio" v-model="itemSun.data.fontArr[itemSun.data.index].type"
                        size="mini">
                        <el-radio-button label="1">品牌橱窗</el-radio-button>
                        <el-radio-button label="2">店铺精选</el-radio-button>
                        <el-radio-button label="3">商品精选</el-radio-button>
                    </el-radio-group>
                </div>
                <!-- 样式一设置 -->
                <div class="M3_Box" v-if="itemSun.data.fontArr[itemSun.data.index].type == '1'">
                    <span>背景图片及商品上传</span>


                    <div class="M3_upBox" style="height:160px"
                        v-for="(item, index) in itemSun.data.fontArr[itemSun.data.index].arr" :key="index">
                        <img @click="DelIconFun(itemSun.data.fontArr[itemSun.data.index].arr, index)" class="M3_iconDEl"
                            src="@/assets/icon/cuo2.png" alt="" srcset="">
                        <img class="M3_img" :src="item.url" alt="" srcset="">
                        <div class="M3_inputBox">
                            <el-input placeholder="请输入内容" v-model="item.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = item">添加</span>
                                </template>
                            </el-input>
                            <el-input style="margin-top:5px" disabled placeholder="请选择" v-model="item.skipUrl.showText"
                                size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input>

                        </div>
                        <div class="M3_xian"></div>
                        <div class="M1_SlideBox">
                            <span class="M1_demonstration">背景图片高度</span>
                            <el-slider :min="0" :max="800" class="M1_Slide" v-model="item.imgHeight"></el-slider>
                            <el-input-number class="M1_input" size="mini" v-model="item.imgHeight"
                                controls-position="right" :min="0" :max="800"></el-input-number>
                        </div>

                    </div>
                    <div @click="addGoodList(itemSun.data.fontArr[itemSun.data.index])" class="M3_addImgButton">添加一个
                    </div>
                </div>
                <!-- 样式二设置 -->
                <div class="M3_Box" v-if="itemSun.data.fontArr[itemSun.data.index].type == '2'">
                    <span>标题设置及商品上传</span>
                    <div class="M3_upBox" style="height:160px"
                        v-for="(item, index) in itemSun.data.fontArr[itemSun.data.index].arr" :key="index">
                        <img @click="DelIconFun(itemSun.data.fontArr[itemSun.data.index].arr, index)" class="M3_iconDEl"
                            src="@/assets/icon/cuo2.png" alt="" srcset="">

                        <!-- <img class="M3_img" :src="item.url" alt="" srcset=""> -->
                        <div :style="{ background: item.backColor }" style="text-align: center; line-height: 70px;"
                            class="M3_img">
                            背景</div>
                        <div class="M3_inputBox">
                            <el-input placeholder="请输入标题" v-model="item.title" size="small">

                            </el-input>
                            <el-input style="margin-top:5px" disabled placeholder="请选择" v-model="item.skipUrl.showText"
                                size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input>
                        </div>
                        <div class="M3_xian"></div>
                        <div class="M4_Box">
                            <span class="M4_font">模块背景颜色</span>
                            <el-color-picker class="M4_picker" size="small" v-model="item.backColor"></el-color-picker>
                            <el-button @click="itemSun.backColor = itemSun.backColor_" class="M4_button"
                                size="small">默认颜色</el-button>
                        </div>
                    </div>
                    <div @click="addGoodList(itemSun.data.fontArr[itemSun.data.index])" class="M3_addImgButton">添加一个
                    </div>
                </div>
                <!-- 样式三设置 -->
                <div class="M3_Box" v-if="itemSun.data.fontArr[itemSun.data.index].type == '3'">
                    <span>背景设置及商品上传</span>
                    <div v-for="(item, index) in itemSun.data.fontArr[itemSun.data.index].arr" :key="index">
                        <div class="M3_upBox">
                            <img @click="DelIconFun(itemSun.data.fontArr[itemSun.data.index].arr, index)"
                                class="M3_iconDEl" src="@/assets/icon/cuo2.png" alt="" srcset="">

                            <div :style="{ background: item.backColor }" style="text-align: center; line-height: 70px;"
                                class="M3_img">
                                背景</div>
                            <div class="M3_inputBox">
                                <el-input placeholder="" disabled size="small">

                                </el-input>
                                <el-input style="margin-top:5px" disabled placeholder="请选择"
                                    v-model="item.skipUrl.showText" size="small">
                                    <template slot="append">
                                        <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                    </template>
                                </el-input>
                            </div>



                            <div class="M3_xian"></div>
                        </div>
                        <div class="M4_Box">
                            <span class="M4_font">模块背景颜色</span>
                            <el-color-picker class="M4_picker" size="small" v-model="item.backColor"></el-color-picker>
                            <el-button @click="itemSun.backColor = itemSun.backColor_" class="M4_button"
                                size="small">默认颜色</el-button>
                        </div>

                    </div>
                    <div @click="addGoodList(itemSun.data.fontArr[itemSun.data.index])" class="M3_addImgButton">添加一个
                    </div>
                </div>


            </div>
            <!-- 消息公告 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '消息公告'">
                <div class="M2_SlideBox">
                    <span class="M2_demonstration">提示文字</span>
                    <el-input class="M2_input" size="small" style="width: 365px;" v-model="itemSun.data.title"
                        placeholder="请输入内容"></el-input>
                </div>


                <div class="M4_Box">
                    <span>字体颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider max=50 size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
                <div style="height: 5px;"></div>
                <!-- <div @click="" class="M5_addImgButton">恢复默认样式</div> -->


            </div>
            <!-- 富文本 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '富文本'">
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider max=50 size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">
                    <span>字体颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>


                <div class="M1_SlideBox">
                    <span class="M1_demonstration">字体大小</span>
                    <el-slider :min="12" :max="25" size="small" class="M1_Slide"
                        v-model="itemSun.data.fontSize"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.fontSize"
                        controls-position="right" :min="12" :max="25"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">文本间距</span>
                    <el-slider :min="15" :max="30" size="small" class="M1_Slide"
                        v-model="itemSun.data.lineHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.lineHeight"
                        controls-position="right" :min="15" :max="30"></el-input-number>
                </div>
                <div class="M6_Box">
                    <span class="M6_font">居中方式</span>
                    <el-radio-group class="M6_radio" v-model="itemSun.data.permutation" size="mini">
                        <el-radio-button label="left">左对齐</el-radio-button>
                        <el-radio-button label="center">居中</el-radio-button>
                        <el-radio-button label="right">右对齐</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="M7_Box">
                    <span class="M7_font">编辑文字</span>
                    <el-input class="M8_input" type="textarea" :rows="5" placeholder="请输入内容"
                        v-model="itemSun.data.text">
                    </el-input>
                </div>





            </div>
            <!-- 浮动按钮 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '浮动按钮'">
                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M3_Box" v-for="(item, index) in itemSun.data.iconArr" :key="index">
                    <span>{{ item.title }}</span>
                    <div class="M3_upBox">
                        <img class="M3_img" :src="item.url" alt="" srcset="">
                        <div class="M3_inputBox">
                            <el-input placeholder="自定义图标" v-model="item.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = item">选择图片</span>
                                </template>
                            </el-input>
                            <el-switch style="margin-top:15px" v-model="item.show" active-text="显示悬浮按钮"
                                inactive-text="隐藏悬浮按钮">
                            </el-switch>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 辅助线 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '辅助线'">

                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M4_Box">
                    <span class="M4_font">辅助线颜色</span>
                    <el-color-picker class="M4_picker" size="small" v-model="itemSun.data.fontColor"></el-color-picker>
                    <el-button @click="itemSun.data.fontColor = itemSun.data.fontColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">辅助线上下间距</span>
                    <el-slider :max="100" size="mini" class="M1_Slide" v-model="itemSun.data.boxHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.boxHeight"
                        controls-position="right" :min="0" :max="100"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
            </div>
            <!-- 辅助空白 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '辅助空白'">
                <div class="M4_Box">
                    <span class="M4_font">背景颜色</span>
                    <el-color-picker class="M4_picker" size="small"
                        v-model="itemSun.data.backgroundColor"></el-color-picker>
                    <el-button @click="itemSun.data.backgroundColor = itemSun.data.backgroundColor_" class="M4_button"
                        size="small">默认颜色</el-button>
                </div>

                <div class="M1_SlideBox">
                    <span class="M1_demonstration">辅助空白上下间距</span>
                    <el-slider :max="100" size="mini" class="M1_Slide" v-model="itemSun.data.boxHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.boxHeight"
                        controls-position="right" :min="0" :max="100"></el-input-number>
                </div>
                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>
            </div>
            <!-- 热区切图 -->
            <div v-if="selectModule.id == indexSun && selectModule.name == '热区切图'">
                <!-- <div class="M1_SlideBox">
                    <span class="M1_demonstration">图片高度</span>
                    <el-slider :min="0" :max="800" class="M1_Slide" v-model="itemSun.data.imgHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.imgHeight"
                        controls-position="right" :min="0" :max="800"></el-input-number>
                </div> -->

                <div class="M1_SlideBox">
                    <span class="M1_demonstration">模块高度</span>
                    <el-slider :max="50" size="small" class="M1_Slide" v-model="itemSun.data.moduleHeight"></el-slider>
                    <el-input-number class="M1_input" size="mini" v-model="itemSun.data.moduleHeight"
                        controls-position="right" :min="0" :max="50"></el-input-number>
                </div>

                <Hotspot :imageSrc="itemSun.data.url" @dataChange="dataChange($event, itemSun.data)" />

                <div class="M3_Box">
                    <span>图片选择</span>

                    <div style="display: flex; height: 35px;" class="M3_upBox">
                        <div style="display: inline-block; position: relative;top: 10px;left: 15px; ">图片选择</div>
                        <div class="M3_inputBox" style=" height: auto; width: 290px; display: inline-block;">
                            <!-- <el-input style=" disabled" placeholder="请选择" v-model="item.skipUrl.showText" size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input> -->
                            <el-input placeholder="图片链接" v-model="itemSun.data.url" size="small">
                                <template style="padding:0px;" slot="append">
                                    <span @click="uploadObsShow = true, temporaryObs = itemSun.data">添加</span>
                                </template>
                            </el-input>
                        </div>
                        <!--  <div class="M3_xian"></div> -->
                    </div>
                </div>
                <div class="M3_Box">
                    <span>选区操作</span>

                    <div style="display: flex; height: 35px;" class="M3_upBox"
                        v-for="(item, index) in itemSun.data.hotspots" :key="index">
                        <div style="display: inline-block; position: relative;top: 10px;left: 15px; ">选区{{ index + 1 }}</div>
                        <div class="M3_inputBox" style=" height: auto; width: 290px; display: inline-block;">
                            <el-input style=" disabled" placeholder="请选择" v-model="item.skipUrl.showText" size="small">
                                <template slot="append">
                                    <span style="cursor: pointer" @click="temporaryObs = item, JumpUrl()">跳转</span>
                                </template>
                            </el-input>
                        </div>
                        <!--  <div class="M3_xian"></div> -->
                    </div>
                </div>




            </div>
            <!-- <div>{{selectModule.id}}</div> -->
            <!-- <div>{{indexSun }}</div> -->



        </div>
    </div>
</template>

<script>
import obsUpload from "@/components/obsUpload.vue";
import jumpWindow from "@/components/jumpWindow.vue";
import draggable from 'vuedraggable'
import Hotspot from '@/components/hotspot.vue'
export default {
    props: ["phoneData"],
    components: { obsUpload, jumpWindow, draggable, Hotspot },
    data() {
        return {
            uploadObsShow: false,
            jumpWindowShow: false,
            obsType: '图片',
            temporaryObs: "",//临时obs
            jumpObs: '',
            selectModule: {}
        }
    },
    methods: {
        //增加轮播图
        M3_addImg(obj) {
            console.log("添加轮播图");
            console.log("添加轮播图");
            let data = {
                skipUrl: {
                    chooseS: false,
                    showText: '',
                    typeId: '0',
                    typeShow: ['0', '1', '2', '3', '4'],
                    type: '',
                    value: '',
                },
                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
            };
            obj.push(data)
        },
        //数组删除
        DelIconFun(obj, index) {
            obj.splice(index, 1);

        },
        //图片热区数据变化
        dataChange(e, obs) {
            obs.hotspots = e
        },
        //添加图片回调
        getObsUrl(obs) {
            console.log(obs);
            this.temporaryObs.url = obs[0].url;

            this.uploadObsShow = false;
        },
        //打开选择链接窗口
        JumpUrl() {
            this.jumpWindowShow = true;

        },
        //跳转链接回调
        getjumpObs(obj) {
            console.log(this.temporaryObs);
            //this.temporaryObs.skipUrl = obj;

            console.log(obj);

        },
        //接收父组件的选择传值、更新页面
        dataSelectFun(obs) {
            console.log('obj改变了', obs)
            this.selectModule = obs
            this.phoneData = JSON.parse(JSON.stringify(this.phoneData))

        },
        //增加商品列表
        addGoodList(obs) {
            console.log("增加商品列表", obs);
            obs.arr.push({
                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/807a2895-7a92-4d6c-81e6-2919a6f822840",
                imgHeight: 250,
                title: "鸿星尔克",
                fontSize: 15,
                fontColor: '#000',
                backColor: '#ff0',
                backColor_: '#ff0',
                skipUrl: {
                    chooseS: true,
                    showText: '',
                    typeId: '4',
                    typeShow: ['2', '4'],
                    type: '',
                    value: [
                        {
                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/962a238c-ccbf-48f0-a4ce-01e6d666bee00",
                            goodName: "精致手镯",
                            price: "23.00",
                        },
                        {
                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/962a238c-ccbf-48f0-a4ce-01e6d666bee00",
                            goodName: "商品名称",
                            price: "23.00",
                        },
                        {
                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/962a238c-ccbf-48f0-a4ce-01e6d666bee00",
                            goodName: "商品名称",
                            price: "23.00",
                        }
                    ],
                },

            },)
        },
        //文字导航显示类型切换
        selType(e, obs) {
            console.log(e);
            obs.type = e;
        }
    },
    watch: {
        phoneData: {
            handler(val) {
                this.$emit("selectDataFun", '', val)
            },
            deep: true
        }
    }


}

</script>

<style scoped>
@import "@/components/phoneSet.css";

.Box {
    height: calc(100vh - 150px);
    /* overflow-y: auto;  */
    overflow: auto;


}

.Box::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.moduleSetBox {
    padding: 2px 0;

}


::v-deep .el-slider__button {
    width: 16px;
    height: 8px !important;
    border: solid 2px #00228a;
    background-color: #fff;
    border-radius: 30%;
    transition: .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative;
    top: -1px;
}

::v-deep .el-slider__bar {
    height: 4px;
}

::v-deep .el-slider__runway {
    height: 4px;
}

::v-deep .el-input-grouM3__append {
    padding: 0 5px;
}

::v-deep .el-color-picker--small .el-color-picker__trigger {
    height: 32px;
    width: 280px;
}
</style>