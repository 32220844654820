<template>
    <div class="main">
        <el-input v-model="form.seekValue" placeholder="请输入日志内容" size="small" class="inputSty" clearable></el-input>
        <el-select v-model="form.memberId" placeholder="选择操作人员" size="small" class="inputSty" clearable>
            <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
        <!-- <el-date-picker v-model="dateArr" value-format="yyyy-MM-dd" type="daterange" size="small" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" class="inputSty">
        </el-date-picker> -->
        <el-date-picker v-model="form.addTime" type="date" class="inputSty" :clearable="false"
         value-format="yyyy-MM-dd" placeholder="选择日期" size="small">
        </el-date-picker>
        <el-button size="small" class="addButton" @click="tableData = [], getLog()" type="primary">查询</el-button>
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                    <el-table-column prop="type" label="日志类型" width="200">
                    </el-table-column>
                    <el-table-column prop="name" label="操作人员" width="280">

                    </el-table-column>
                    <el-table-column prop="details" label="操作内容">
                    </el-table-column>
                    <el-table-column prop="addtime" label="操作时间" width="280">
                    </el-table-column>
                </el-table>
            </template>

            <el-dialog :title="windowTitle" :visible.sync="addTypeShow" width="300px">
                <el-input style="width: 250px;" clearable maxlength="10" size="small" v-model="input"
                    placeholder="请输入分类名称"></el-input>

                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="addTypeShow = false">取 消</el-button>
                    <el-button v-if="windowTitle == '新增分类'" size="mini" type="primary" @click="savaType()">确
                        定</el-button>
                    <el-button v-else size="mini" type="primary" @click="updateType()">保 存</el-button>
                </span>
            </el-dialog>

        </div>

    </div>
</template>

<script>


import moment from 'moment';
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            userAdmin: [],
            form: {
                shopId: this.shopId,
                memberId: "",
                addTime: "",
                seekValue:"",
            },
            dateArr: [],
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId");
        this.form.shopId = localStorage.getItem("shopId");
        this.getAdminUser();
        let dateStr = moment().format('YYYY-MM-DD');
        this.form.addTime = dateStr;

    },
    methods: {
        getAdminUser() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.userAdmin = res.data;
                this.getLog();
            })
        },
        getLog() {

            this.$api.post("shop/getShopLogPc", this.form, (res) => {
                res.data.forEach(e => {
                    let arr = this.userAdmin.filter(o => o.id == e.memberId)[0]
                    e.name = arr ? arr.name : '未知'
                });

                this.tableData = res.data.reverse();
            })

        },

    },


}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputSty {
    width: 200px;
    margin-right: 10px
}
</style>