<template>
    <div>
        <div class="titleBox"></div>
        <draggable v-model="phoneData" forceFallback="true">
            <transition-group>
                <div class="moduleBox" v-for="(itemSun, index2) in phoneData" :key="index2"
                    :style="{ marginTop: itemSun.data.moduleHeight + 'px' }"
                    :class="{ SelectBox: (itemSun.name == selectModule.name) }">
                    <div class="Overlay" @click="selectModulefun(itemSun, index2)" @mouseenter="Mous(index2, 1)"></div>
                    <div v-if="moduleId == index2" @click="delModule(index2)" class="delBurtton">删除</div>
                    <!-- 轮播图 -->
                    <div v-if="itemSun.name == '轮播图'">
                        <el-carousel :height="itemSun.data.imgHeight + 'px'">
                            <el-carousel-item v-for="(item, index) in itemSun.data.imgs" :key="index">
                                <img :style="{ height: itemSun.data.imgHeight + 'px' }" class="LBT_img" :src="item.url"
                                    alt="" srcset="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <!-- 搜索框 -->
                    <div v-if="itemSun.name == '搜索框'">
                        <div class="SSK_box" :style="{ background: itemSun.data.backgroundColor }">
                            <div class="SSK_box2"
                                :style="{ background: itemSun.data.inputColor, borderRadius: itemSun.data.roundness + 'px' }">
                                <img class="SSK_img" src="./phoneIcon/seek.png" alt="" srcset="">
                                <div class="SSK_font" :style="{ color: itemSun.data.fontColor }">{{ itemSun.data.text }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 图标导航 -->
                    <div v-if="itemSun.name == '图标导航'">
                        <div class="TBDH_box"
                            :style="{ background: itemSun.data.backgroundColor, paddingTop: itemSun.data.modulePaddingTop + 'px' }">
                            <div v-for="item in itemSun.data.imgs" class="TBDH_imgBox">
                                <img :src="item.url" alt="" srcset="" class="TBDH_img"
                                    :style="{ borderRadius: itemSun.data.radius + 'px' }">
                                <div class="TBDH_font" :style="{ color: itemSun.data.fontColor }">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 商品 -->
                    <div v-if="itemSun.name == '商品'">
                        <div class="SP_box" :style="{ background: itemSun.data.backgroundColor }">
                            <div v-for="(item, index) in itemSun.data.skipUrl.value" :key="index"
                                style="display: inline-block;">
                                <!-- 一行两个 -->
                                <div class="SP_goodsBox" v-if="itemSun.data.type == 2">
                                    <img class="SP_img" :src="item.imgMain" alt="" srcset="">
                                    <div class="SP_title">{{ item.goodName }}</div>
                                    <div class="SP_price">￥{{ item.price }}</div>
                                    <img class="SP_shoucang" src="@/assets/icon/shoucangButton_.png" alt="" srcset="">
                                </div>
                                <!-- 一行三个 -->
                                <div class="SP_goodsBox3" v-if="itemSun.data.type == 3">
                                    <img class="SP_img3" :src="item.imgMain" alt="" srcset="">
                                    <div class="SP_title">{{ item.goodName }}</div>
                                    <div class="SP_price">￥{{ item.price }}</div>
                                    <img class="SP_shoucang3" src="@/assets/icon/shoucangButton_.png" alt="" srcset="">
                                </div>
                                <!-- 一行一个 -->
                                <div class="SP_goodsBox1" v-if="itemSun.data.type == 1">
                                    <img class="SP_img1" :src="item.imgMain" alt="" srcset="">
                                    <div class="SP_fontBox">
                                        <div class="SP_title1">{{ item.goodName }}</div>
                                        <div class="SP_price1">￥{{ item.price }}</div>
                                        <img class="SP_shoucang" src="@/assets/icon/shoucangButton_.png" alt=""
                                            srcset="">
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                    <!-- 图片拼接 -->
                    <div v-if="itemSun.name == '图片拼接'" :style="{ background: itemSun.data.backgroundColor }">
                        <div v-for="(item, index) in itemSun.data.imgs" :key="index" style='display: inline-block;'>
                            <!-- 单图布局 -->
                            <div v-if="itemSun.data.type == 1 && index == 0">
                                <img class="TPPJ1_img" :style="{ height: itemSun.data.imgHeight + 'px' }"
                                    :src="item.url" alt="" srcset="">
                            </div>
                            <!-- 双图布局 -->
                            <div v-if="itemSun.data.type == 2 && index < 2" style='display: inline-block;'>
                                <img class="TPPJ2_img" :src="item.url" alt="" srcset=""
                                    :style="{ height: itemSun.data.imgHeight + 'px', margin: itemSun.data.paddingValue + 'px', width: (187.5 - (itemSun.data.paddingValue * 2)) + 'px' }">
                            </div>
                            <!-- 三图布局 -->
                            <div v-if="itemSun.data.type == 3 && index < 3" style='display: inline-block;'>
                                <img class="TPPJ2_img" :src="item.url" alt="" srcset=""
                                    :style="{ height: itemSun.data.imgHeight + 'px', margin: itemSun.data.paddingValue + 'px', width: (125 - (itemSun.data.paddingValue * 2)) + 'px' }">
                            </div>


                        </div>
                        <!-- 三图拼接 -->
                        <div v-if="itemSun.data.type == 4"
                            style='display: inline-block; position: relative;width:375px;'>
                            <div v-for="(item, index) in itemSun.data.imgs" :key="index" style='display: inline-block;'>
                                <img class="TPPJ4_img1" v-if="index == 0" :src="item.url" alt="" srcset=""
                                    :style="{ height: itemSun.data.imgHeight + 'px', margin: itemSun.data.paddingValue + 'px', width: (187.5 - (itemSun.data.paddingValue * 2)) + 'px' }">
                                <img class="TPPJ4_img2" v-if="index == 1" :src="item.url" alt="" srcset=""
                                    :style="{ height: (itemSun.data.imgHeight / 2) - (itemSun.data.paddingValue * 2) + 'px', margin: itemSun.data.paddingValue + 'px', width: (187.5 - (itemSun.data.paddingValue * 2)) + 'px' }">
                                <img class="TPPJ4_img3" v-if="index == 2" :src="item.url" alt="" srcset=""
                                    :style="{ height: (itemSun.data.imgHeight / 2) - (itemSun.data.paddingValue * 2) + 'px', margin: itemSun.data.paddingValue + 'px', width: (187.5 - (itemSun.data.paddingValue * 2)) + 'px' }">
                            </div>
                        </div>
                    </div>
                    <!-- 图片橱窗 -->
                    <div v-if="itemSun.name == '图片橱窗'">
                    </div>
                    <!-- 文字导航 -->
                    <div v-if="itemSun.name == '文字导航'"
                        :style="{ background: itemSun.data.backgroundColor, color: itemSun.data.fontColor }">
                        <div class="WZDH_fontBox">
                            <div class="WZDH_font" :style="{

            background: (itemSun.data.index == index ? itemSun.data.selectColor : ''),
            color: (itemSun.data.index == index ? itemSun.data.selectFontColor : ''),
            borderRadius: itemSun.data.roundness + 'px'
        }" v-for="(item, index) in itemSun.data.fontArr" :key="index">{{ item.name }}</div>

                            <!-- 商品展示-样式一 -->
                            <div v-if="itemSun.data.fontArr[itemSun.data.index].type == '1'">
                                <div class="WZDH_goodBox"
                                    v-for="(item2, index2) in itemSun.data.fontArr[itemSun.data.index].arr"
                                    :key="index2">
                                    <img class="WZDH_T1_back" :style="{ height: item2.imgHeight + 'px' }"
                                        :src="item2.url" alt="" srcset="">
                                    <div class="WZDH_T1_goodBox">
                                        <div class="WZDH_T1_goodsBox1" v-for="(item, index3) in  item2.skipUrl.value"
                                            :key="index3">
                                            <img class="WZDH_T1_img1" :src="item.imgMain" alt="" srcset="">
                                            <div class="WZDH_T1_title">{{ item.goodName }}</div>
                                            <div class="WZDH_T1_price">￥{{ item.price }}</div>
                                            <img class="WZDH_T1_shoucang1" src="@/assets/icon/shoucangButton_.png"
                                                alt="" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 商品展示-样式二 -->
                            <div v-if="itemSun.data.fontArr[itemSun.data.index].type == '2'">
                                <div class="WZDH_goodBox"
                                    v-for="(item2, index2) in itemSun.data.fontArr[itemSun.data.index].arr"
                                    :key="index2" style="position: relative;" :style="{ background: item2.backColor }">
                                    <div class="WZDH_T1_font1"
                                        :style="{ fontSize: item2.fontSize + 'px', color: item2.fontColor }">{{
            item2.title }}
                                    </div>
                                    <div class="WZDH_T1_goodBox" style="position: relative;">
                                        <div class="WZDH_T1_goodsBox1" v-for="(item, index3) in item2.skipUrl.value"
                                            :key="index3">
                                            <img class="WZDH_T1_img1" :src="item.imgMain" alt="" srcset="">
                                            <div class="WZDH_T1_title">{{ item.goodName }}</div>
                                            <div class="WZDH_T1_price">￥{{ item.price }}</div>
                                            <img class="WZDH_T1_shoucang1" src="@/assets/icon/shoucangButton_.png"
                                                alt="" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 商品展示-样式三 -->
                            <div v-if="itemSun.data.fontArr[itemSun.data.index].type == '3'">
                                <div class="WZDH_goodBox"
                                    v-for="(item2, index2) in itemSun.data.fontArr[itemSun.data.index].arr"
                                    :key="index2" style="position: relative;" :style="{ background: item2.backColor }">
                                    <div class="WZDH_T1_goodBox3" style="position: relative;">
                                        <div class="WZDH_T1_goodsBox3" v-for="(item, index3) in item2.skipUrl.value"
                                            :key="index3">
                                            <img class="WZDH_T1_img3" :src="item.imgMain" alt="" srcset="">
                                            <div class="WZDH_T1_title">{{ item.goodName }}</div>
                                            <div class="WZDH_T1_price">￥{{ item.price }}</div>
                                            <img class="WZDH_T1_shoucang1" src="@/assets/icon/shoucangButton_.png"
                                                alt="" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- 视频内容 -->
                    <div v-if="itemSun.name == '视频内容'" :style="{ background: itemSun.data.backgroundColor }">
                        <video class="SPNR_tv" :style="{ borderRadius: itemSun.data.roundness + 'px' }"
                            :src="itemSun.data.url" initial-time="0" autoplay="false" loop="false" muted="false"
                            direction="0" bindplay="" bindpause="" bindended="" bindtimeupdate="" bindwaiting=""
                            binderror="">
                        </video>
                    </div>
                    <!-- 消息公告 -->
                    <div v-if="itemSun.name == '消息公告'">
                        <div class="XXGG_box" :style="{ background: itemSun.data.backgroundColor }">
                            <div class="XXGG_box2"
                                :style="{ background: itemSun.data.inputColor, borderRadius: itemSun.data.roundness + 'px' }">
                                <img class="XXGG_img" src="./phoneIcon/gg.png" alt="" srcset="">
                                <div class="XXGG_font" :style="{ color: itemSun.data.fontColor }">{{ itemSun.data.title
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 富文本 -->
                    <div v-if="itemSun.name == '富文本'">
                        <div class="FWB_box" :style="{ background: itemSun.data.backgroundColor }">
                            <div class="FWB_font" :style="{
            color: itemSun.data.fontColor,
            fontSize: itemSun.data.fontSize + 'px',
            lineHeight: itemSun.data.lineHeight + 'px',
            textAlign: itemSun.data.permutation
        }">{{ itemSun.data.text }}</div>
                        </div>
                    </div>
                    <!-- 辅助线 -->
                    <div v-if="itemSun.name == '辅助线'">
                        <div class="FZX_box" :style="{
            background: itemSun.data.backgroundColor,
            height: itemSun.data.boxHeight + 'px',
            lineHeight: itemSun.data.boxHeight + 'px'
        }">
                            ----------------------------------------------------------------------
                        </div>
                    </div>
                    <!-- 辅助空白 -->
                    <div v-if="itemSun.name == '辅助空白'">
                        <div class="FZX_box" :style="{
            background: itemSun.data.backgroundColor,
            height: itemSun.data.boxHeight + 'px',
            lineHeight: itemSun.data.boxHeight + 'px'
        }">
                        </div>
                    </div>
                    <!-- 辅助空白 -->
                    <div v-if="itemSun.name == '浮动按钮'">
                        <div class="FDAN_font">浮动按钮占位框（具体请看右下角,手机端不显示）</div>
                    </div>
                    <!-- 热区切图 -->
                    <div v-if="itemSun.name == '热区切图'">
                        <div class="hotspot-image">
                            <img :src="itemSun.data.url" style="width: 100%;" alt="Image" ref="image"  @error="handleImageError">
                            <div v-for="(area, index) in itemSun.data.hotspots" :key="index" class="hotspot-area" :style="{
            left: area.left + 'px',
            top: area.top + 'px',
            width: area.width + 'px',
            height: area.height + 'px'
        }">
                                <!-- <div class="resize-handle" @mousedown.stop.prevent="startResize(index, $event)"></div> -->


                            </div>

                        </div>
                    </div>
                </div>

            </transition-group>
        </draggable>

    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: ["phoneData", "selectModule"],
    components: { draggable },
    data() {
        return {
            moduleId: 100,
        }
    },
    methods: {
        delModule(index) {
            console.log("杉树");
            this.phoneData.splice(index, 1)
        },
        selectModulefun(item, index) {
            console.log("选中", index);
            item.id = index;
            this.$emit("selectDataFun", item, this.phoneData)
        },
        Mous(index, type) {
            type == 1 ? this.moduleId = index : this.moduleId = 100;
            console.log("鼠标事件" + this.moduleId);
            this.$emit("selectDataFun", "", this.phoneData)


        },
        handleImageError(event) {
            // 图片加载失败时，将备用图片地址赋值给图片元素的src属性
            event.target.src = "https://myuser.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0.png_117109942543900";
        },

    }
}
</script>
<style scoped>
@import "@/components/phone.css";

.titleBox {
    height: 55px;
    /* background: #1e1e1e; */
    border-radius: 20px 30px 0 0;
}

.Overlay {
    width: 100%;
    background: red;
    display: inline-block;
    position: absolute;
    z-index: 20;
    height: 100%;
    opacity: 0
}

.moduleBox {
    width: 100%;
    min-height: 30px;
    /* border: 1px solid blue; */
    position: relative;
    /* z-index: 20; */
    cursor: pointer;
}

.moduleBox:hover {
    border: 0.5px dashed blue;

}

.SelectBox {
    border: 0.5px solid blue;
}

.delBurtton {
    position: absolute;
    bottom: 2px;
    right: 4px;
    color: brown;
    font-size: 12px;
    z-index: 21;
    width: 40px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border: 1px dashed red;
    background: #f37f7f5e;
}
.hotspot-image {
    position: relative;
    display: inline-block;
}

.hotspot-area {
    position: absolute;
    background: #ff44333c;
}
</style>