<template>
    <div class="main">
        <el-button size="mini" class="addButton" @click="addPageSet()" type="primary">新增页面</el-button>
        <!-- <el-button size="mini" class="addButton" @click="getGoodType()" type="primary">获取分类</el-button> -->
        <el-button size="mini" class="addButton" @click="tableData = [], getHomePage()">刷新</el-button>
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                  
                    <el-table-column prop="pageName" label="页面名称" width="280">
                    </el-table-column>
                    <el-table-column prop="pageName" label="是否为首页" >
                        <tempalace slot-scope = "scope"> 
                            <span style="color:#fff; padding:5px 20px;background-color: rgb(21, 166, 69);" v-if="scope.row.isHome =='1'" >是</span>
                            <span style="color:#000; padding:5px 20px;background-color:rgb(144, 145, 145);" v-else >否</span>
                        </tempalace>
                    </el-table-column>

                    <el-table-column prop="address" width="300" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="jumpPage(scope.row)">编辑</el-button>
                            <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info"
                                icon-color="red" title="确定删除该页面吗？" @confirm="delHomePage(scope.row)">
                                <el-button size="mini" slot="reference" type="danger">删除</el-button>
                            </el-popconfirm>
                            <el-button size="mini" type="primary" plain @click="setHome(scope.row.id)">设为首页</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <el-dialog :title="windowTitle" :visible.sync="addTypeShow" width="300px">
                <el-input style="width: 250px;" clearable maxlength="10" size="small" v-model="input" placeholder="请输入分类名称"></el-input>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="addTypeShow = false">取 消</el-button>
                    <el-button v-if="windowTitle == '新增分类'" size="mini" type="primary" @click="savaType()">确 定</el-button>
                    <el-button v-else size="mini" type="primary" @click="updateType()">保 存</el-button>
                </span>
            </el-dialog>

        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs'//引入插件
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            addTypeShow: false,
            windowTitle: "新增分类",
            input: "",
            nowObj: "",
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId")
        this.getHomePage();
        // 阻止默认行为
        document.body.ondrop = function (event) {
            event.preventDefault();
            event.stopPropagation();
        };
        this.rowDrop()//行拖拽

    },
    methods: {
        //查询
        getHomePage() {
            let data = {
                shopId: this.shopId,
            }
            this.$api.post("shop/getPageSet", data, (res) => {
                this.tableData = res.data;
            })
        },
        //删除
        delHomePage(e) {
            console.log(e);
            if (e.isHome=='1') {
                this.$message.error('请先解除首页绑定在删除');
                return
            }
            let data = {
                shopId: this.shopId,
                id: e.id + '',
            }
            this.$api.del("shop/delPageSet", data, (res) => {

                if (res.code == 200) {
                    this.getHomePage()
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('操作失败');
                }
                this.getHomePage()
            })
        },
        //编辑
        jumpPage(val){
            this.$router.push({ name: 'homeDecoration' });
            this.$emit('iconShow', 'homeDecoration');
            localStorage.setItem('temporaryData', JSON.stringify(val))
        },
        //新建页面
        addPageSet(){
            this.$router.push({ name: 'homeDecoration' });
            // this.$emit('iconShow', 'homeDecoration');
            // localStorage.setItem('temporaryData', JSON.stringify(val))
        },
        //设置为首页
        setHome(id){
            let data = {
                shopId: this.shopId,
                id:id
            }
            this.$api.post("shop/setHome", data, (res) => {
                if (res.code == 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getHomePage()
                } else {
                    this.$message.error('保存失败');
                }
            })
        }
       



    }

}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}
</style>