import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/main.css'/*引入公共样式*/
// 引入外部js>Api请求
import api from './js/url.js'//引入这个外部js
import fun from './js/fun.js'//引入这个外部js
Vue.prototype.$api = api//命名并暴露
Vue.prototype.$fun = fun//命名并暴露
//Vue.prototype.$jwt = jwt//命名并暴露

const uuid = require('uuid').v4()
Vue.prototype.$uuid = uuid//命名并暴露

import ElementUI from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';

import './assets/style/theme/index.css';


Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
}
}).$mount('#app')
