<template>
    <div class="main">
        <el-button size="mini" class="addButton" @click="addTypeShow = true" type="primary">修改价格</el-button>
        <el-button size="mini" class="addButton" @click="getPrice()">刷新</el-button>
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">

                    <el-table-column prop="name" label="修改人员">
                    </el-table-column>
                    <el-table-column prop="price" label="修改后的价格">
                    </el-table-column>
                    <el-table-column prop="hisPrice" label="修改前的价格">
                    </el-table-column>
                    <el-table-column prop="" label="调整趋势">
                        <template scope="scope" >
                            <div style="color:#0bc64e" v-if="scope.row.price>scope.row.hisPrice" >上涨</div>
                            <div style="color:#9e2727" v-else>下跌</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="addDate" label="修改时间">
                    </el-table-column>


                </el-table>
            </template>


        </div>
        <el-pagination style="text-align: right;margin-top:10px " small background layout="prev, pager, next"
            :page-size="nubPage" :total="tableData[0]?tableData[0].total_count:20" @current-change="pageChange">
        </el-pagination>

        <el-dialog title="设置店铺金价" :visible.sync="addTypeShow" width="300px">
            <el-input style="width: 250px;" clearable maxlength="8" type="number" size="small" v-model="price"
                placeholder="价格"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addTypeShow = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="sava()">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            shopId: localStorage.getItem("shopId"),
            tableData: [],
            addTypeShow: false,
            price: "",
            user: JSON.parse(localStorage.getItem("user")),
            nubPage: 20,
            thePage: 1,
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId")
        this.getPrice();

    },
    methods: {
        getPrice() {
            let data = {
                shopId: this.shopId,
                thePage: this.thePage,
                nubPage: this.nubPage,
            }
            this.tableData=[],
            this.$api.post("shop/getPrice", data, (res) => {
                this.tableData = res.data;
            })

        },

        sava() {
            if (this.price < 100) {
                this.$message.error('输入数字不能小于100');
                return
            }
            if (this.price > 2000) {
                this.$message.error('输入数字不能大于2000');
                return
            }
            let data = {
                shopId: this.shopId,
                crmMemberId: this.user.id,
                crmMemberName: this.user.name,
                "price": this.price,
                "hisPrice": this.user.glodPrice,
                "note": "哈哈哈"
            }
            this.$api.post("shop/setShopPrice", data, (res) => {
                console.log(res);
                this.addTypeShow = false;
                if (res.code == 200) {
                    this.getPrice()
                    this.$message({
                        message: '修改成功，3秒后将自动刷新页面！',
                        type: 'success'
                    });
                this.user.glodPrice = this.price;
                localStorage.setItem("user", JSON.stringify(this.user))
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        pageChange(e){
            this.thePage=e;
            this.getPrice()
            console.log(e);

        },



    }

}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 100px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}
</style>