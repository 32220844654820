<template>
    <div class="main">

        <div style="margin:10px 0px" >全场商品配置</div>
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

            <el-form-item label="整价商品" prop="shopName" style="width: 400px;" size="small" >
                <el-select v-model="form.activePrice_1" placeholder="选择活动">
                    <el-option v-for="(item, index) in activeArr1" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <div v-if="text1" style="color:red" >全场整价商品均享受{{text1 }}折</div>
            </el-form-item>
            <el-form-item label="克卖商品" prop="shopName" style="width: 400px;">
                <el-select v-model="form.activePrice_2" placeholder="选择活动" size="small" >
                    <el-option v-for="(item, index) in activeArr2" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <div v-if="text2" style="color:red" >全场克卖商品均优惠{{ text2 }}元</div>
            </el-form-item>

            <div style="width: 300px; text-align: right;">

                <el-button size="small" type="primary" @click="save()">进行修改</el-button>

            </div>

        </el-form>


    </div>
</template>

<script>

import obsUpload from "@/components/obsUpload.vue";
export default {
    components: {
        obsUpload,
    },

    data() {

        return {
            shopId: '',
            user: JSON.parse(localStorage.getItem("user")),
            activeArr1: [],
            activeArr2: [],
            form: {
                activePrice_1: "",
                activePrice_2: "",
            },
            rules: {


            },
            text1:"",
            text2:"",


        }
    },
    mounted() {
        this.getActivePrice()
       
    },
    methods: {
        getActivePrice() {
            this.$api.post("good/getActivePrice", { shopId: this.user.shopId }, (res) => {
                let arr = [{name:"不参与活动",id:"0"}]
                this.activeArr1 = [...arr,...res.data.filter(o=>o.type=='1')];
                this.activeArr2 = [...arr,...res.data.filter(o=>o.type=='2')];
                this.getShopInfo()
            })
        },
        getShopInfo(){
            this.$api.post("good/getShopActivePrice", { shopId: this.user.shopId }, (res) => {
                this.form.activePrice_1 = res.data.activePrice_1 ;
                this.form.activePrice_2 = res.data.activePrice_2 ;
                if (res.data.activePrice_1 ) {
                    this.text1 = this.activeArr1.filter(o=>o.id==res.data.activePrice_1)[0].value;
                }else{
                    this.text1 = "";
                }
                if (res.data.activePrice_2 ) {
                    this.text2 = this.activeArr2.filter(o=>o.id==res.data.activePrice_2)[0].value;
                }else{
                    this.text2 = "";
                }
            })
        },
        save(){
            let data = {
                shopId:this.user.shopId,
                activePrice_1:this.form.activePrice_1,
                activePrice_2:this.form.activePrice_2,
            }
            this.$api.put("good/setShopActivePrice", data, (res) => {
                this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
               this.getShopInfo()
            })
        },


    }
}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.el-tab-pane {
    height: calc(100vh - 160px);
}
</style>