<template>
  <div class="main">
    <el-button size="mini" class="addButton" @click="addAttributeButton()" type="primary">新增属性</el-button>
    <el-button size="mini" class="addButton" @click="tableData = [], getGoodAttribute()">刷新</el-button>
    <div class="table">
      <template>
        <el-table :data="tableData" stripe style="width: 100%">

          <el-table-column prop="name" label="属性名称">
          </el-table-column>
          <el-table-column prop="name" label="预览" width="300">
            <!-- 样式预览 -->
            <template slot-scope="scope">{{ }}
              <template v-if="scope.row.attributeType == '1'">
                <el-radio v-for="item in JSON.parse(scope.row.attributeValue).value" :key="item.title"
                  style="position: relative;left: 4px;" v-model="JSON.parse(scope.row.attributeValue).SelectValue" :label="item.title">{{ item.title
                  }}</el-radio>
              </template>
              <template v-else>
                <el-select v-model="JSON.parse(scope.row.attributeValue).SelectValue" :placeholder="'请选择' + input" size="mini">
                  <el-option v-for="item in JSON.parse(scope.row.attributeValue).value" :key="item.label"
                    :label="item.label" :value="item.label" :v-model="'5G'">
                  </el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="是否显示" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.attributeShow" active-color="#13ce66" @change="switchFun($event, scope.row)"
                inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="address" width="300" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="updateAttributeButton(scope.row)">编辑</el-button>

              <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info" icon-color="red"
                title="确定删除该属性吗？" @confirm="delAttributeButton(scope.row)">
                <el-button size="mini" slot="reference" type="danger">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog :title="windowTitle" :visible.sync="addAttributeShow" width="450px">
        属性样式：<template>
          <el-radio style="position: relative;left: 4px;" v-model="radioAttribute" label="1">单选框</el-radio>
          <el-radio style="position: relative;left: 4px;" v-model="radioAttribute" label="2">下拉菜单</el-radio>
        </template>
        <br>
        <br>
        属性名称：
        <el-input style="width: 250px;" size="small" v-model="input" placeholder="属性名称"></el-input>
        <br>
        <br>
        属性选值：
        <template v-if="radioAttribute == '1'">
          <el-input class="inputRAdio" size="small" v-model="RAdio[0].title" placeholder="单选值1"></el-input>
          <el-input class="inputRAdio" size="small" v-model="RAdio[1].title" placeholder="单选值2"></el-input>
        </template>
        <template v-else>
          <div class="inputRAdio2">
            <div v-for="item, index in options" :key="index">
              <el-input class="inputRAdio3" size="small" v-model="item.label" placeholder="下拉菜单值"></el-input>
              <el-button v-if="options.length >= 3" class="inputR2" size="mini" type="danger" icon="el-icon-minus" circle
                @click="options.splice(index, 1)"></el-button>
              <el-button v-if="index == options.length - 1" class="inputR2" size="mini" type="primary" icon="el-icon-plus"
                circle @click="options.push({ value: '', label: '' })"></el-button>
            </div>
          </div>
        </template>
        <br>
        <br>
        样式预览：
        <div class="styleBox">
          <template v-if="radioAttribute == '1'">
            <el-radio v-for="item in RAdio" :key="item.title" style="position: relative;left: 4px;" v-model="RAdioSelect"
              :label="item.title">{{ item.title }}</el-radio>
          </template>
          <template v-else>
            <el-select v-model="RAdioSelect2" :placeholder="'请选择' + input" size="mini">
              <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </template>
        </div>




        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="addAttributeShow = false">取 消</el-button>
          <el-button v-if="windowTitle == '新增属性'" size="mini" type="primary" @click="savaAttribute()">确 定</el-button>
          <el-button v-else size="mini" type="primary" @click="updateAttribute()">保 存</el-button>
        </span>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'//引入插件
export default {
  data() {
    return {
      value: "",
      shopId: '',
      tableData: [],
      addAttributeShow: false,
      windowTitle: "新增属性",
      input: "",
      nowObj: "",
      radioAttribute: "1",
      RAdio: [{ title: '空心' }, { title: '实心' }],
      RAdioSelect: '',
      RAdioSelect2: '',
      options: [{
        value: '选项1',
        label: '18K'
      }, {
        value: '选项2',
        label: '24K'
      },]
    }
  },
  mounted() {
    this.shopId = localStorage.getItem("shopId")
    this.getGoodAttribute();
    // 阻止默认行为
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.rowDrop()//行拖拽

  },
  methods: {

    getGoodAttribute() {
      let data = {
        shopId: this.shopId
      }
      this.$api.post("good/getGoodAttribute", data, (res) => {
        res.data.forEach(o => {
          o.attributeShow = o.attributeShow ? true : false;
        });
        this.tableData = res.data;
      })

    },
    //新增弹窗显示
    addAttributeButton() {
      this.windowTitle = '新增属性';
      this.input = "";
      this.addAttributeShow = true;
    },
    savaAttribute() {
      let data = {
        shopId: this.shopId,
        name: this.input,
        attributeType: this.radioAttribute,
        attributeValue: this.radioAttribute == 1 ? JSON.stringify({SelectValue:this.RAdioSelect,value:this.RAdio}) : JSON.stringify({SelectValue:this.RAdioSelect2,value:this.options}),
        attributeShow: 1
      }
      this.$api.post("good/addGoodAttribute", data, (res) => {
        console.log(res);
        this.addAttributeShow = false;
        if (res.code == 200) {
          this.getGoodAttribute()
          this.$message({
            message: '新增成功',
            type: 'success'
          });
        } else {
          this.$message.error('操作失败');
        }
        this.input = '';
      })
    },
    updateAttributeButton(e) {
      this.windowTitle = '编辑属性名称';
      this.radioAttribute = e.attributeType;
      this.input = e.name;

      this.nowObj = e;
      console.log(e.attributeType);
      if (e.attributeType == "1") {
        this.RAdio = JSON.parse(e.attributeValue).value;
        this.RAdioSelect = JSON.parse(e.attributeValue).SelectValue;
      } else {
        this.options = JSON.parse(e.attributeValue).value;
        this.RAdioSelect = JSON.parse(e.attributeValue).SelectValue2;
      }
      this.addAttributeShow = true;
      //console.log(e);

    },
    updateAttribute() {
      let data = {
        id: this.nowObj.id,
        name: this.input || this.nowObj.name,
        attributeType: this.radioAttribute,
        attributeValue: this.radioAttribute == 1 ? JSON.stringify({SelectValue:this.RAdioSelect,value:this.RAdio}) : JSON.stringify({SelectValue:this.RAdioSelect2,value:this.options}),
        attributeShow: this.nowObj.attributeShow ? 1 : 0,
      }
      this.$api.put("good/upGoodAttribute", data, (res) => {
        console.log(res);
        this.addAttributeShow = false;
        if (res.code == 200) {
          this.getGoodAttribute()
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('修改失败');
        }
        this.input = '';
      })


    },
    delAttributeButton(e) {
      console.log(e.id);
      let data = {
        shopId: this.shopId,
        id: e.id + '',
      }
      this.$api.del("good/delGoodAttribute", data, (res) => {

        if (res.code == 200) {
          this.getGoodAttribute()
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        } else {
          this.$message.error('操作失败');
        }
      })
    },
    //修改属性 显示隐藏
    switchFun(e, value) {

      let data = {
        id: value.id,
        attributeShow: e ? 1 : 0,
      }
      this.$api.put("good/upGoodAttributeShow", data, (res) => {

        this.addAttributeShow = false;
        if (res.code == 200) {
          this.getGoodAttribute()
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('修改失败');
        }
      })

    },
    //行拖拽插件
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (newIndex == oldIndex) return
          _this.tableData.splice(
            newIndex,
            0,
            _this.tableData.splice(oldIndex, 1)[0]
          )
          var newArray = _this.tableData.slice(0)
          _this.tableData = []
          _this.$nextTick(function () {
            _this.tableData = newArray
            _this.sortSave();
          })
        }
      })
    },
    //保存拖拽排序的结果
    sortSave(data) {
      console.log("保存拖拽排序的结果");
      let sort = [];
      this.tableData.forEach((o, index) => {
        sort.push({ id: o.id, sort: index + 1 + '' })
      });
      console.log(sort);
      this.$api.put('good/sortGoodAttribute', sort, (res) => {
        if (res.code == 200) {
          this.getGoodAttribute()
          this.$message({
            message: '排序成功',
            type: 'success'
          });
        } else {
          this.$message.error('操作失败');
        }

      })

    }



  }

}
</script>

<style scoped>
.main {
  width: calc(100% - 20px);
  height: calc(100% - 55px);
  background: #fff;
  padding: 10px;
  position: relative;
  text-align: left;
}

.addButton {
  position: relative;

}

.table {
  border: 1px solid #E9ECF0;
  padding: 10px;
  margin-top: 10px;
  height: calc(100% - 80px);
  overflow: auto;
}

div/deep/.el-dialog__body {
  padding: 5px 20px;
}

.el-button--mini {
  margin: 0px 5px;
}

.iconPX {
  width: 23px;
  height: 21px;
  position: relative;
  top: 3px;
  left: 3px;
  cursor: move;
}

.inputRAdio {
  position: relative;
  margin: 0px 5px 0px 0;
  width: 122px;
}

.inputRAdio2 {
  position: relative;
  display: inline-table;
}

.inputRAdio3 {
  position: relative;
  margin: 5px 0px;
  width: 220px;
}

.inputR2 {
  position: relative;

}

.styleBox {
  display: inline-table;
  width: 230px;
  padding: 10px;
  background: #f6f6f6;
  border-radius: 5px;
}
</style>