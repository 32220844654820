<template>
  <div class="main">
    <!-- 搜索框 -->
    <div>
      <el-input clearable class="buttonBox" size="small" v-model="form.goodName" placeholder="商品名称"></el-input>
      <el-input clearable class="buttonBox" size="small" v-model="form.productId" placeholder="商品编号"></el-input>
      <el-select clearable class="buttonBox2" size="small" v-model="form.typeId" placeholder="商品分类">
        <el-option v-for="item in goodTypeArr" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
      <!-- <el-select clearable class="buttonBox2" size="small" v-model="form.groupingId" placeholder="商品分组">
        <el-option v-for="item in goodGroupingArr" :key="item.id" :label="item.name" :value="item.id">
        </el-option> 
      </el-select> -->
      <el-select clearable class="buttonBox2" size="small" v-model="form.state" placeholder="状态">
        <el-option label="已上架" value="0"></el-option>
        <el-option label="已下架" value="1"></el-option>
      </el-select>
      <el-select clearable class="buttonBox2" size="small" v-model="form.priceType" placeholder="计价类型">
        <el-option label="一口价" value="1"></el-option>
        <el-option label="按克工费" value="2"></el-option>
        <el-option label="整体工费" value="3"></el-option>
      </el-select>
      <el-button size="small" @click="getGoodsData()" type="primary">查询</el-button>
      <el-button @click="uploadGoods" size="small">上传商品</el-button>
    </div>
    <!-- 表格显示 -->
    <el-table :data="goodsArr" border style="width: 100%" :header-cell-style="{ background: '#00228899', color: '#000' }" :height="screenHeight - 200">
      <el-table-column fixed prop="date" label="商品信息" width="300">
        <template scope="scope">
          <div class="goodBox">
            <el-image fit="cover" :src="scope.row.imgMain + '?x-image-process=image/resize,m_lfit,h_100'" :preview-src-list="[scope.row.imgMain]" class="goodImg"></el-image>
            <span class="goodNameBoxTitle">{{ scope.row.goodName }}</span>
            <!-- <span v-if="scope.row.priceType == '1'" class="goodweightBox"
              >￥{{ scope.row.price }}</span
            > -->
            <span v-if="scope.row.priceType !== '1'" class="goodweightBox">{{ scope.row.weight }}g</span>
          </div>
        </template>
      </el-table-column>
      <!-- 价格配置 -->
      <el-table-column label="价格" width="180">
        <template scope="scope">
          <div class="goodBox">
            <el-tooltip v-if="scope.row.priceType == '1'" class="item" effect="dark" content="一口价" placement="top-start">
              <span v-if="scope.row.active" style="color: red; font-weight: bold" class="goodNameBox">
                ￥{{ scope.row.activePrice }}

                <span style="color: #606266; font-size: 12px; line-height: 12px; text-decoration: line-through">{{ scope.row.price }}</span>
              </span>
              <span v-else style="color: red; font-weight: bold" class="goodNameBox">总售价：￥{{ scope.row.price }}</span>
            </el-tooltip>

            <el-tooltip v-if="scope.row.priceType == '2'" class="item" effect="dark" placement="top-start" :content="`(${GoldPrice}+${scope.row.makePrice})x${scope.row.weight}=${scope.row.price}`">
              <div class="goodNameBox">
                <span class="makePriceSty" >工费(g):￥{{ scope.row.makePrice }}</span>
                <br />
                <span v-if="scope.row.active" style="color: red; font-weight: bold" class="goodNameBox">
                  ￥{{ scope.row.activePrice }}

                  <span style="color: #606266; font-size: 12px; line-height: 12px; text-decoration: line-through">{{ scope.row.price }}</span>
                </span>
                <span v-else style="color: red; font-weight: bold" class="goodNameBox">总售价：￥{{ scope.row.price }}</span>
              </div>
            </el-tooltip>
            <el-tooltip v-if="scope.row.priceType == '3'" class="item" effect="dark" placement="top-start" :content="`(${GoldPrice}x${scope.row.weight})+${scope.row.makePrice}=${scope.row.price}`">
              <div class="goodNameBox">
                <span class="makePriceSty" >工费:￥{{ scope.row.makePrice }}</span>
                <br />
                <span v-if="scope.row.active" style="color: red; font-weight: bold" class="goodNameBox">
                  ￥{{ scope.row.activePrice }}

                  <span style="color: #606266; font-size: 12px; line-height: 12px; text-decoration: line-through">{{ scope.row.price }}</span>
                </span>
                <span v-else style="color: red; font-weight: bold" class="goodNameBox">总售价：￥{{ scope.row.price }}</span>
              </div>
            </el-tooltip>
          </div>
          <!-- 活动标签 -->
          <span v-if='scope.row.active' class="activeBox">{{ scope.row.active }}</span>
          <!-- 算价标签 -->
          <!-- <el-tooltip v-if="scope.row.priceType == '1'" class="item" effect="dark" content="一口价" placement="top-start">
            <span class="priceTypeBox">一口价</span>
          </el-tooltip>
          <el-tooltip v-if="scope.row.priceType == '2'" class="item" effect="dark" content="(金价+工费)x克重" placement="top-start">
            <span class="priceTypeBox">工费(g)</span>
          </el-tooltip>
          <el-tooltip v-if="scope.row.priceType == '3'" class="item" effect="dark" content="(金价x克重)+工费" placement="top-start">
            <span class="priceTypeBox">工费(整)</span>
          </el-tooltip> -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="金重" width="80">
        <template scope="scope">
          <div class="goodBox">
            <span class="goodNameBox">{{ scope.row.weight }}g</span>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="price" label="属性" width="150">
        <template scope="scope">
          <div style="font-size: 12px; line-height: 18px" :key="index3" v-for="(item3, index3) in JSON.parse(scope.row.attribute)">{{ item3.name }}:{{ item3.attributeValue.SelectValue }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="分类"></el-table-column>
      <!-- <el-table-column prop="groupingName" label="分组">

      </el-table-column> -->
      <el-table-column prop="productId" label="编号"></el-table-column>
      <el-table-column prop="inventory" label="库存"></el-table-column>
      <el-table-column fixed="right" prop="state" label="状态">
        <template scope="scope">
          <div class="goodBox">
            <span v-if="scope.row.state == '1'" class="state1">已上架</span>
            <span v-else class="state2">未上架</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button @click="editGood(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="switchState(scope.row, '1')" v-if="scope.row.state == '0'" type="text" size="small">上架</el-button>
          <el-button @click="switchState(scope.row, '0')" v-else type="text" size="small">下架</el-button>
          <el-popconfirm v-if="scope.row.state == '0'" title="确定删除该商品？" @confirm="delGood(scope.row)">
            <el-button slot="reference" style="color: red" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align: right; margin-top: 10px" small background layout="prev, pager, next" :page-size="nubPage" :total="goodsArr[0] ? goodsArr[0].total_count : 20" @current-change="pageChange"> </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: localStorage.getItem("shopId"),
      goodsArr: [],
      GoldPrice: JSON.parse(localStorage.getItem("user")).glodPrice,
      user: JSON.parse(localStorage.getItem("user")),
      srcList: [],
      input: "",
      value: "",
      options: [],
      form: {
        shopId: localStorage.getItem("shopId"), //店铺ID
        goodName: "", //商品名称
        state: "", //商品状态
        productId: "", //商品ID
        typeId: "", //商品分类ID
        groupingId: "", //商品分组ID
        priceType: "", //价格类型1一口价，2按克工费,3整体工费
        sort: "", //1收升2收降3浏升4浏降，不填默认
        priceSort: "", //1价格升2收降3浏升4浏降，不填默认
        priceMin: "", //价格范围最小
        priceMax: "", //价格范围最大
      },
      goodTypeArr: [],
      goodGroupingArr: [],
      nubPage: 15,
      thePage: 1,
      screenHeight: window.innerHeight,
    };
  },
  mounted() {
    this.shopId = localStorage.getItem("shopId");
    this.getGoodsData();
    this.getTypeArr();
    this.getGrouping();
  },
  methods: {
    //分页查询
    pageChange(e) {
      this.thePage = e;
      this.getGoodsData();
      console.log(e);
    },
    getGoodsData() {
      this.goodsArr = [];
      (this.form.thePage = this.thePage),
        (this.form.nubPage = this.nubPage),
        this.$api.post("good/getGoods", this.form, (res) => {
          console.log(res);
          // let arr = [];
          // res.data.forEach((o) => {
          //   arr.push(o.imgMain);
          // });
          // this.srcList = arr;
          this.goodsArr = res.data;
        });
    },
    //获取商品分类
    getTypeArr() {
      let data = {
        shopId: localStorage.getItem("shopId"),
      };
      this.$api.post("good/getGoodType", data, (res) => {
        this.goodTypeArr = res.data;
        console.log("=========");
        console.log(this.goodTypeArr);
      });
    },
    //获取商品分类
    getGrouping() {
      let data = {
        userId: this.shopId,
      };
      this.$api.post("good/getGoodGrouping", data, (res) => {
        this.goodGroupingArr = res.data;
      });
    },
    delGood(item) {
      console.log("删除商品");
      let data = {
        shopId: this.shopId,
        id: item.id,
        memberId:this.user.id,
        goodName:item.goodName
      };
      this.$api.del("good/delGood", data, (res) => {
        if (res.code == 200) {
          this.getGoodsData();
          this.$message({
            message: "操作成功！",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //切换状态
    switchState(item, state) {
      let data = {
        shopId: this.shopId,
        id: item.id,
        state: state,
        memberId:this.user.id,
        goodName:item.goodName
      };
      this.$api.put("good/switchStated", data, (res) => {
        console.log(res);
        if (res.code == 200) {
          this.getGoodsData();
          this.$message({
            message: "操作成功！",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //上传商品
    uploadGoods() {
      this.$router.push({ name: "goodUpload" });
      this.$emit("iconShow", "goodUpload");
    },
    //编辑商品
    editGood(obj) {
      console.log("编辑商品");
      this.$router.push({ name: "goodUpload" });
      this.$emit("iconShow", "goodUpload");
      //this.$bus.$emit('getdata',obj)
      localStorage.setItem("temporaryData", JSON.stringify(obj));
    },
  },
};
</script>

<style scoped>
.goodBox {
  /* border: 1px dashed red; */
  position: relative;
  display: flex;
}

.state1 {
  width: 80px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  background: #76b7dc;
  border-radius: 5px;
  color: #000;
}

.state2 {
  width: 80px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  background: #dc7676af;
  color: #fff;
  border-radius: 5px;
}

div/deep/.el-table__cell {
  padding: 8px 0 !important;
}

.goodNameBox {
  /* border:1px dashed #880; */
  display: inline-block;
  margin: 5px;
}

.goodNameBoxTitle {
  /* border:1px dashed #880; */
  display: inline-block;
  margin: 5px;
  width: 200px;
}

.goodImg {
  width: 65px;
  height: 65px;
  border-radius: 3px;
}

.goodweightBox {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-weight: bold;
  font-size: 20px;
  color: rgba(226, 27, 80, 0.206);
}

.priceTypeBox {
  position: absolute;
  top: 2px;
  right: 5px;
  font-weight: bold;
  font-size: 12px;
  color: rgba(226, 27, 80, 0.206);
}

.priceTypeBox2 {
  position: absolute;
  top: 2px;
  right: 5px;
  font-weight: bold;
  font-size: 25px;
  color: rgba(227, 171, 186, 0.176);
}

.buttonBox {
  width: 180px;
  margin: 0 10px 10px 0;
}

.buttonBox2 {
  width: 150px;
  margin: 0 10px 10px 0;
}

div/deep/.el-table thead {
  height: 60px;
}
.activeBox {
  background: #ce3838;
  color: #fff;
  font-size: 12px;
  padding: 2px 3px;
  transform: calc(0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 12px;
}
.makePriceSty{
  position: absolute;
    top: 12px;
    font-size: 12px;
    left: 10px;
}

</style>
