<template>
    <div>
        <div class="main">
            <!-- 左边菜单 -->
            <div class="mainBox1">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>装修菜单</span>
                    </div>
                    <div>
                        <el-collapse v-for="(item, index) in homeMenu" :key="index" v-model="activeNames">
                            <el-collapse-item :title="item.title" :name="index + 1 + ''">
                                <div @click="addPhoneData(item2)" class="menuButtonBox"
                                    v-for="(item2, index2) in item.data" :key="index2">
                                    <img :src="item2.icon" alt="" srcset="">
                                    <div>{{ item2.name }}</div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </el-card>
            </div>
            <!-- 手机显示 -->
            <div class="mainBox2">
                <img class="phone" src="@/assets/phone.png" alt="" srcset="">
                <div class="phoneBox">
                    <Phone :phoneData="phoneData" :selectModule="selectModule" @selectDataFun="selectDataFun"></Phone>
                </div>
                <!-- 浮动按钮-->
                <div v-if="phoneData.some(o => o.name == '浮动按钮')" class="FGAN_Box">
                    <div class="FGAN_ding" v-if="phoneData.filter(o => o.name == '浮动按钮')[0].data.iconArr[0].show"
                        :style="{ background: phoneData.filter(o => o.name == '浮动按钮')[0].data.backgroundColor }">
                        <img :src="phoneData.filter(o => o.name == '浮动按钮')[0].data.iconArr[0].url" alt="" srcset="">
                    </div>
                    <div class="FGAN_ding2" v-if="phoneData.filter(o => o.name == '浮动按钮')[0].data.iconArr[1].show"
                        :style="{ background: phoneData.filter(o => o.name == '浮动按钮')[0].data.backgroundColor }">
                        <img :src="phoneData.filter(o => o.name == '浮动按钮')[0].data.iconArr[1].url" alt="" srcset="">
                    </div>
                </div>
                <div class="lanHomeBox"></div>
            </div>
            <!-- 右边详细设置 -->
            <div class="mainBox3" v-if="setBoxShow">
                <el-card class="box-card" :body-style="{ padding: '0px' }">
                    <div slot="header" class="clearfix">
                        <span>{{ selectModule.name }}{{ titleType }}</span>
                        <el-button v-if="titleType == '设置'" size="small"
                            style="float: right; position: relative; top:-5px;" type="primary"
                            @click="dialogVisible = true">保存样式</el-button>
                        <el-button v-if="titleType == '编辑'" size="small"
                            style="float: right; position: relative; top:-5px;" type="primary"
                            @click="upHomePage()">确定保存</el-button>

                    </div>
                    <div style="background: #f6f6f6;">
                        <PhoneSet ref="PhoneSetRef" :phoneData="phoneData" @selectDataFun="selectDataFun"></PhoneSet>
                    </div>
                </el-card>
            </div>
        </div>
        <!-- 保存弹窗 -->
        <el-dialog title="请输入首页名称" :visible.sync="dialogVisible" width="30%">
            <el-input v-model="pageName" placeholder="请输入内容"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addPageData()">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Phone from "@/components/phone.vue";
import PhoneSet from "@/components/phoneSet.vue";

export default {
    components: { Phone, PhoneSet },
    data() {
        return {

            activeNames: ['1'],
            setBoxShow: true,
            selectModule: {},
            homeMenu: [
                {
                    title: "图文类", data: [

                        {
                            name: "轮播图", icon: require("../../assets/homeSet/L.png"), data: {
                                imgHeight: 320,
                                moduleHeight: 0,
                                imgs: [
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    },
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    }
                                ]
                            }
                        },
                        {
                            name: "图标导航", icon: require("../../assets/homeSet/T.png"), data: {
                                moduleHeight: 0,
                                modulePaddingTop: 0,
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                fontColor_: "#999",
                                fontColor: "#999",
                                priceColor: "#999",
                                priceColor_: "#999",
                                type: 1,
                                imgs: [
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        text: "标题一",
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    },
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        text: "标题二",
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    }, {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        text: "标题三",
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    }, {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        text: "标题四",
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0"
                                    },
                                ]
                            }
                        },
                        {
                            name: "文字导航", icon: require("../../assets/homeSet/W.png"),
                            data: {
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                fontColor: "#000",
                                fontColor_: "#000",
                                selectColor: "#f0f",
                                selectColor_: "#f0f",
                                selectFontColor: "#fff",
                                selectFontColor_: "#fff",
                                fontColor: "#999",
                                fontColor_: "#999",
                                roundness: 10,

                                index: 0,
                                fontArr: [
                                    {
                                        name: "标题一",
                                        type: "1",
                                        arr: [
                                            {
                                                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                imgHeight: 350,
                                                title: "品牌一",
                                                fontSize: 15,
                                                fontColor: '#000',
                                                backColor: '#979797',
                                                backColor_: '#979797',
                                                skipUrl: {
                                                    chooseS: true,
                                                    showText: '',
                                                    typeId: '2',
                                                    typeShow: ['2', '4'],
                                                    type: '',
                                                    value: [
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "精致手镯",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        }
                                                    ],
                                                },

                                            },
                                        ]
                                    },
                                    {
                                        name: "标题二",
                                        type: "1",
                                        arr: [
                                            {
                                                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                imgHeight: 250,
                                                title: "品牌一",
                                                fontSize: 15,
                                                fontColor: '#000',
                                                backColor: '#979797',
                                                backColor_: '#979797',
                                                skipUrl: {
                                                    chooseS: true,
                                                    showText: '',
                                                    typeId: '2',
                                                    typeShow: ['2', '4'],
                                                    type: '',
                                                    value: [
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "精致手镯",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        }
                                                    ],
                                                },

                                            },
                                        ]
                                    },
                                    {
                                        name: "标题三",
                                        type: "1",
                                        arr: [
                                            {
                                                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                imgHeight: 250,
                                                title: "品牌一",
                                                fontSize: 15,
                                                fontColor: '#000',
                                                backColor: '#979797',
                                                backColor_: '#979797',
                                                skipUrl: {
                                                    chooseS: true,
                                                    showText: '',
                                                    typeId: '2',
                                                    typeShow: ['2', '4'],
                                                    type: '',
                                                    value: [
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "精致手镯",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        },
                                                        {
                                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                                            goodName: "商品名称",
                                                            price: "23.00",
                                                        }
                                                    ],
                                                },

                                            },
                                        ]
                                    }
                                ]
                            }
                        },
                        {
                            name: "视频内容", icon: require("../../assets/homeSet/S.png"),
                            data: {
                                moduleHeight: 0,
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                roundness: 0,
                                url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/ce0fd0e7-cf29-40bd-a433-810af0646dfa0",
                            }
                        },
                        {
                            name: "图片拼接", icon: require("../../assets/homeSet/T.png"), data: {
                                imgHeight: 300,
                                moduleHeight: 0,
                                type: 4,
                                paddingValue: 0,
                                backgroundColor: "#fff",
                                backgroundColor_: "#fff",
                                imgs: [
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/d5770a4a-87c1-4d70-b473-49399030c7eb2"
                                    },
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/93073eca-72f5-427b-bff6-b97196c761002"
                                    },
                                    {
                                        skipUrl: {
                                            chooseS: false,
                                            showText: '',
                                            typeId: '0',
                                            typeShow: ['0', '1', '2', '3', '4'],
                                            type: '',
                                            value: '',
                                        },
                                        url: "https://glod2.obs.cn-north-4.myhuaweicloud.com/93073eca-72f5-427b-bff6-b97196c761002"
                                    }
                                ]
                            }
                        },
                        {
                            name: "热区切图", icon: require("../../assets/homeSet/T.png"), data: {
                                imgHeight: 320,
                                moduleHeight: 0,
                                url:"https://myuser.obs.cn-north-4.myhuaweicloud.com/截屏2024-03-14 16.21.18.png_117104044925390",
                                hotspots:[],
                            }
                        },
                        {
                            name: "商品", icon: require("../../assets/homeSet/S.png"),
                            data: {
                                moduleHeight: 0,
                                type: 3,
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                titleColor: "#f3f3f8",
                                titleColor_: "#f3f3f8",
                                priceColor: "red",
                                priceColor_: "red",
                                goods: [

                                ],
                                skipUrl: {
                                    chooseS: true,
                                    showText: '',
                                    typeId: '0',
                                    typeShow: ['0', '1', '2', '3', '4'],
                                    type: '',
                                    value: [
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "精致手镯",
                                            price: "23.00",
                                        },
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "商品名称",
                                            price: "23.00",
                                        },
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "商品名称",
                                            price: "23.00",
                                        },
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "商品名称",
                                            price: "23.00",
                                        },
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "商品名称",
                                            price: "23.00",
                                        },
                                        {
                                            imgMain: "https://glod2.obs.cn-north-4.myhuaweicloud.com/12aec9da-1ec0-4263-8671-92fdf151013e0",
                                            goodName: "商品名称",
                                            price: "23.00",
                                        }
                                    ],
                                },
                                gooodType: ['0', "1", "4"]
                            }
                        },
                        // { name: "弹窗广告", icon: require("../../assets/homeSet/T.png"), data: [] },


                    ]
                },
                {
                    title: "工具类", data: [
                        {
                            name: "搜索框", icon: require("../../assets/homeSet/S.png"),
                            data: {
                                moduleHeight: 0,
                                text: "请输入关键字进行搜索",
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                inputColor: "#fff",
                                inputColor_: "#fff",
                                fontColor: "#999",
                                fontColor_: "#999",
                                roundness: 20,
                            }
                        },
                        {
                            name: "消息公告", icon: require("../../assets/homeSet/S.png"),
                            data: {
                                title: "今日金价为325元，较昨天每克下架13元",
                                backgroundColor: "#fff7cc",
                                backgroundColor_: "#fff7cc",
                                fontColor: "#ff6600",
                                fontColor_: "#ff6600",

                            }
                        },
                        {
                            name: "富文本", icon: require("../../assets/homeSet/F.png"),
                            data: {
                                text: "股东会决议和章程修正案变更后的住所修改为“山西省大同经济技术开发区装备制造产业园区 1169 号”要与选择的事项相一致。窗口咨询电话0352-6116316",
                                backgroundColor: "#fff",
                                backgroundColor_: "#fff",
                                fontColor: "#000",
                                fontColor_: "#000",
                                fontSize: 12,
                                lineHeight: 20,
                                permutation: 'left',
                            }
                        },
                        {
                            name: "浮动按钮", icon: require("../../assets/homeSet/F.png"),
                            data: {
                                type: '1',
                                backgroundColor: "#0000005d",
                                backgroundColor_: "#0000005d",
                                iconArr: [{
                                    show: true,
                                    url: "https://myuser.obs.cn-north-4.myhuaweicloud.com/goHome.png_117109825076890",
                                    title: "置顶按钮配置",
                                }, {
                                    show: true,
                                    url: "https://myuser.obs.cn-north-4.myhuaweicloud.com/goOC.png_117109825136630",
                                    title: "客服按钮配置",
                                }],

                            }
                        },
                        {
                            name: "辅助线", icon: require("../../assets/homeSet/F.png"),
                            data: {
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                fontColor: "#000",
                                fontColor_: "#000",
                                boxHeight: 30

                            }
                        }, {
                            name: "辅助空白", icon: require("../../assets/homeSet/F.png"),
                            data: {
                                backgroundColor: "#f3f3f8",
                                backgroundColor_: "#f3f3f8",
                                moduleHeight: 0,
                                boxHeight: 50

                            }
                        },

                    ]
                },
                // {
                //     title: "营销类", data: [
                //         { name: "优惠券", icon: require("../../assets/homeSet/Y.png"), data: [] },
                //         { name: "限时特价", icon: require("../../assets/homeSet/X.png"), data: [] },
                //         { name: "会员专享", icon: require("../../assets/homeSet/H.png"), data: [] },
                //     ]
                // },
            ],
            phoneData: [],
            shopId: localStorage.getItem("shopId"),
            dialogVisible: false,
            pageName: "",
            titleType: "设置",
            pageId: "",

        }
    },
    mounted(e) {
        let temporaryData = localStorage.getItem('temporaryData');
        localStorage.setItem('temporaryData', '')
        if (temporaryData) {
            let data = JSON.parse(temporaryData)
            this.getPhoneData(data.id);
            this.pageId = data.id;
            this.titleType = "编辑"
        } else { this.titleType = "设置" }

    },
    methods: {
        //首页数据
        getPhoneData(id) {
            console.log("id", id);
            let data = {
                shopId: this.shopId,
                id: id,
            }
            this.$api.post("shop/getPageSet_id", data, (res) => {
                console.log(res.data);
                this.phoneData = JSON.parse(res.data.pageValue);
            })

        },
        //新增首页数据-弹窗打开
        addPhoneData(item2) {
            this.phoneData.push(item2);
            this.$message({
                message: '添加成功！',
                type: 'success'
            });
        },
        //修改首页数据
        upHomePage() {
            let value = JSON.parse(JSON.stringify(this.phoneData));
            value.forEach(o => {
                o.icon = '';
            });
            let data = {
                shopId: this.shopId,
                pageValue: JSON.stringify(value),
                id: this.pageId,
            }
            this.$api.put("shop/upPageSet", data, res => {
                if (res.code == 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('保存失败');
                }
            })

        },
        //手机模块选中的数据
        selectDataFun(item, phoneData) {
            if (item == '') {
                this.phoneData = phoneData;
                return
            }
            this.selectModule = item;
            this.$refs.PhoneSetRef.dataSelectFun(item)
        },
        //保存
        addPageData() {
            if (this.pageName == '') {
                this.$message.error('首页称不能为空！');
                return
            }
            let value = JSON.parse(JSON.stringify(this.phoneData));
            value.forEach(o => {
                o.icon = '';
            });
            let data = {
                shopId: this.shopId,
                pageValue: JSON.stringify(value),
                pageName: this.pageName,
                isHome: "0"
            }
            this.$api.post("shop/addPageSet", data, res => {
                if (res.code == 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialogVisible = false;
                    this.$router.push({ name: 'homeList' });
                    this.$emit('iconShow', 'homeList');
                } else {
                    this.$message.error('保存失败');
                }
            })

        },
    }
}

</script>

<style scoped>
.main {
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #f6f6f6;
}

.mainBox2 {
    height: calc(100% - 55px);
    width: 375px;
    /* border:1px solid red; */
    display: inline-block;
    background: #f6f6f6;
    /* overflow: auto; */
    position: relative;
}

.mainBox1 {
    width: 210px;
    /* border: 1px solid red; */
    display: inline-block;
    background: #fff;
    height: calc(100vh - 100px);
    overflow: auto;
}

.mainBox1::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.mainBox2::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.mainBox3::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.box-card {
    /* height: calc(100vh - 100px); */
    /* overflow-y: auto; */
}



.mainBox3 {
    height: calc(100vh - 100px);
    width: 390px;
    /* border: 1px solid red; */
    display: inline-block;
    background: #fff;
}

.mainBox3 .el-card__body {
    padding: 0px !important;
}

.phone {
    width: 417px;
    /* height: 787px; */
    position: relative;
    right: 20px;
    z-index: 10;
}

.phoneBox {
    width: 375px;
    height: 787px;
    /* border: 1px solid #ff0000; */
    position: absolute;
    top: 13px;
    border-radius: 30px;
    overflow: auto;
}

.phoneBox::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.menuButtonBox {
    width: 74px;
    height: 85px;
    /* border: 1px solid #667766; */
    display: inline-block;
    margin: 5px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    background: #b4ffb435;
}

.menuButtonBox>img {
    width: 55px;
    height: 55px;
    position: relative;
    top: 8px;
}

.menuButtonBox>image {
    width: 55px;
    height: 55px;
    position: relative;
    top: 8px;
}

.menuButtonBox>div {
    position: relative;
    bottom: 0px;
}

.lanHomeBox {
    width: 175px;
    height: 8px;
    border-radius: 10px;
    background: #494949;
    position: absolute;
    bottom: 25px;
    left: 100px;
    display: inline-block;
}

.FGAN_Box {
    right: 10px;
    top: 500px;
    /* border: 1px solid red; */
    width: 70px;
    height: 114px;
    position: absolute;
    z-index: 2;
}
</style>