<template>
  <div class="main">
    <!-- <input id="file" @change="handleFileChange" accept="image/*" type="file" :multiple="false"> -->
    <!-- 顶部导航 -->
    <div class="tablsBox">
      <template>
        <el-tabs v-model="obsType" @tab-click="obsTypeFun">
          <el-tab-pane label=" 图 片" name="图片"></el-tab-pane>
          <el-tab-pane label=" 视 频" name="视频"></el-tab-pane>
          <el-tab-pane label=" 模 型" name="模型"></el-tab-pane>
          <el-tab-pane label=" 回收站" name="回收站"></el-tab-pane>
        </el-tabs>
      </template>
    </div>

    <!-- 分组管理 -->
    <div class="imgGroupingBox" v-if="obsType !== '回收站'">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ obsType }}分组</span>
          <el-button
            @click="addGrouping()"
            style="float: right; padding: 3px 0"
            type="text"
            >新增分组</el-button
          >
        </div>
        <el-input
          style="position: relative; margin-bottom: 15px"
          prefix-icon="el-icon-search"
          size="small"
          v-model="input"
          placeholder="搜索分组"
        >
        </el-input>
        <div
          :style="groupingId == item.id ? 'background:#efefef' : ''"
          class="gr-box"
          v-for="(item, index) in grouping"
          @click="(groupingId = item.id), getObs()"
          :key="index"
        >
          {{ item.obsGroupingName }}
          <img
            class="buttonIcon"
            src="@/assets/icon/bianji.png"
            alt=""
            srcset=""
            @click="upObsGrouping(item)"
          />
          <img
            v-if="groupingId == item.id"
            class="buttonIcon2"
            @click="(groupingIndex = index), delObsGrouping(), (groupingId = item.id)"
            src="@/assets/icon/del.png"
          />
        </div>
        <div
          style="width: 100%; text-align: center; font-size: 12px; color: #666"
          v-if="grouping.length == 0"
        >
          ~ 暂无分组
        </div>
      </el-card>
    </div>
    <!-- 图片操作 -->
    <div class="imgBox" :class="{ imgBox2: obsType == '回收站' }">
      <el-card class="box-card" style="" >
        <div slot="header" class="clearfix">
          <el-input
            style="width: 200px"
            prefix-icon="el-icon-search"
            size="small"
            @input="inputChange"
            placeholder="按名称搜索"
            v-model="seekValue"
            clearable
          >
          </el-input>
          <el-select class="inputP" size="small" v-model="value" placeholder="请选择">
            <el-option label="按上传时间排序" value="按上传时间排序"></el-option>
          </el-select>
          <el-button
            v-if="obsType !== '回收站'"
            @click="(groupingSelectId = groupingId), (dialogShow = true)"
            style="float: right; padding: 3px 0"
            type="text"
            >上传{{ obsType }}</el-button
          >
        </div>
        <span class="spanBox" v-for="(item, index) in obsList" :key="index">
          <img
            v-if="obsType == '回收站'"
            class="iconCuo3"
            src="@/assets/icon/re.png"
            alt=""
            srcset=""
            @click="obsRecycle2(item, index)"
          />
          <img
            v-if="obsType == '回收站'"
            class="iconCuo2"
            src="@/assets/icon/del.png"
            alt=""
            srcset=""
            @click="Delobs(item, index)"
          />

          <img
            v-if="obsType !== '回收站'"
            class="iconCuo2"
            src="@/assets/icon/del.png"
            alt=""
            srcset=""
            @click="obsRecycle(item, index)"
          />
          <el-image
            v-if="item.obsType == '图片'"
            class="img"
            :src="item.url + '?x-image-process=image/resize,m_lfit,h_100'"
            alt=""
            srcset=""
            :preview-src-list="[item.url]"
          ></el-image>
          <Video
            v-if="item.obsType == '视频'"
            class="img"
            v-show="true"
            :videoSrc="item.url"
            :width="100"
            :height="100"
            :autoplay="false"
            :controls="true"
            :loop="false"
            :muted="false"
            preload="auto"
            :showPlay="true"
            :playWidth="96"
            zoom="contain"
          >
          </Video>
          <div class="groupBox" v-if="obsType == '回收站'">
            组:{{
              grouping.filter((o) => o.id == item.groupingTypeId)[0]
                ? grouping.filter((o) => o.id == item.groupingTypeId)[0].obsGroupingName
                : ""
            }}
          </div>
          <div class="spanBoxDiv">{{ item.name }}</div>
        </span>
        <div
          style="width: 100%; text-align: center; font-size: 12px; color: #666"
          v-if="obsList.length == 0"
        >
          ~ 暂无数据
        </div>
      </el-card>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="新增分组" :visible.sync="dialogVisible" width="300px">
      <el-input
        style="width: 250px"
        size="small"
        v-model="inputGrouping"
        placeholder="请输入分组名称"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="obsGroupingTile == '新增分组'"
          size="small"
          type="primary"
          @click="addGroupringFun()"
          >确 定</el-button
        >
        <el-button
          v-if="obsGroupingTile == '编辑分组'"
          size="small"
          type="primary"
          @click="upObsGroupingFun()"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog title="删除分组" :visible.sync="delDialogVisible" width="300px">
      <div v-if="delDialogVisible">
        确定删除【{{ grouping[groupingIndex].obsGroupingName }}】分组
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false">取 消</el-button>
        <el-button size="small" type="danger" @click="delGroupringFun()">删 除</el-button>
      </span>
    </el-dialog>
    <!-- 上传图片弹窗 -->
    <el-dialog
      title="上传图片"
      :visible.sync="dialogShow"
      width="800px"
      v-loading="loading"
      :element-loading-text="loadingName"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      请选择分组：
      <el-select v-model="groupingSelectId" size="mini" placeholder="请选择分组">
        <el-option
          v-for="item in grouping"
          :key="item.id"
          :label="item.obsGroupingName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <input
          style="display: none"
          ref="upload"
          id="file"
          @change="handleFileChange"
          accept="mp4/*"
          type="file"
          :multiple="true"
        />
        <div class="bosBox">
          <div class="upload" @click="uploadShow()">
            <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="" />
          </div>
          <span class="spanBox" v-for="(item, index) in temporaryList" :key="index">
            <img
              class="iconCuo"
              src="@/assets/icon/cuo.png"
              alt=""
              srcset=""
              @click="delFileChange(item.url, index)"
            />
            <img class="img" :src="item.url" alt="" srcset="" />
            <div class="spanBoxDiv">{{ item.name }}</div>
          </span>
        </div>
        <el-button size="mini" @click="dialogShow = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addObs()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ObsClient from "esdk-obs-browserjs";
import Video from "@/components/video";
export default {
  components: {
    Video,
  },

  data() {
    return {
      groupingSelectId: "",
      shopId: "",
      path: "fegddtbrvdgsfsadcsdacdsfcs",
      url: [],
      obsType: "图片",
      value: "",
      input: "",
      grouping: [{ id: "", obsGroupingName: "" }],
      dialogVisible: false,
      delDialogVisible: false,
      inputGrouping: "",
      groupingIndex: "",
      groupingId: "",
      obsGroupingTile: "新增分组",
      dialogShow: false,
      obsList: [],
      obsListCory: [],
      temporaryList: [],
      srcList: [],
      loading: false,
      loadingName: "",
      seekValue:"",
    };
  },
  mounted() {
    this.shopId = localStorage.getItem("shopId");
    this.getObsGrouping();
  },

  methods: {
    getObs() {
      let data = {
        shopId: this.shopId,
        obsType: this.obsType,
        groupingTypeId: this.groupingId,
        showObs: 1,
      };

      this.$api.post("shop/getObs", data, (res) => {
        this.obsList = JSON.parse(JSON.stringify(res.data));
        this.obsListCory = JSON.parse(JSON.stringify(res.data));
        // let srcList = [];
        // res.data.forEach((o) => {
        //   srcList.push(o.url);
        // });
        // this.srcList = srcList;
      });
    },
    //对象模糊查询
    inputChange(e){
      console.log(e);
      let keyword = this.seekValue;
      const filter = keyword.toUpperCase();
      let data =JSON.parse(JSON.stringify(this.obsListCory) )
    
    // 使用 filter 方法对数组进行过滤
    const filteredData = data.filter(item => {
        // 判断名字是否包含搜索关键词
        // return item.name.toUpperCase().includes(filter);
         // 获取对象名称的前缀
         const namePrefix = item.name.toUpperCase().substring(0, filter.length);
        // 判断对象名称前缀是否与搜索关键词匹配
        return namePrefix === filter;
    });

    this.obsList =  filteredData;

    },
    addObs() {
      if (!this.groupingSelectId) {
        this.$message({
          message: "请先选择分组",
          type: "warning",
        });
        return;
      }
      if (this.temporaryList.length == 0) {
        this.$message({
          message: "请先上传图片",
          type: "warning",
        });
        return;
      }
      let data = {
        shopId: this.shopId,
        obsType: this.obsType,
        groupingTypeId: this.groupingSelectId,
        obs: this.temporaryList,
        date: "2012-09-09",
      };
      this.$api.post("shop/addObs", data, (res) => {
        if (res.code == 200) {
          this.groupingId = this.groupingSelectId;
          this.getObsGrouping(true);
          this.getObs();
          this.dialogShow = false;
          this.temporaryList = [];

          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error("添加失败");
        }
      });
    },
    //obs加入回收站
    obsRecycle(item, index) {
      this.$api.put("shop/showObs", { id: item.id, showObs: 0 }, (res) => {
        if (res.code == 200) {
          this.obsList.splice(index, 1);
          this.$message({
            message: "已加入回收站",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //obs回收站恢复
    obsRecycle2(item, index) {
      this.$api.put("shop/showObs", { id: item.id, showObs: 1 }, (res) => {
        if (res.code == 200) {
          this.obsList.splice(index, 1);
          this.$message({
            message: "取消回收站",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //回收站永久删除数据
    Delobs(item, index) {
      this.$api.post("shop/ifUseObs", { id: item.id, url: item.url }, (res) => {
        if (res.data.all.length == 0) {
          this.$api.del("shop/delObs", { shopId: this.shopId, url: item.url }, (res2) => {
            if (res2.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.$api.post("shop/getDelObs", { shopId: this.shopId }, (res3) => {
                this.obsList = res3.data;
              });
            }
          });
        } else {
          this.$message.error("该图片正在使用，请先解除绑定");
        }
      });
    },
    //删除分组
    delObsGrouping() {
      if (this.obsList.length !== 0) {
        this.$message.error("该分组存在素材，切不可直接删除，请先清零");
        return;
      }

      this.delDialogVisible = true;
    },

    // obs分组操作
    obsTypeFun(e) {
      console.log(e);
      if (e.name == "回收站") {
        this.$api.post("shop/getDelObs", { shopId: this.shopId }, (res) => {
          this.obsList = res.data;
        });
        return;
      }
      this.getObsGrouping();
    },
    //
    getObsGrouping(acquiesce = false) {
      this.$api.post(
        "shop/getObsGrouping",
        { shopId: this.shopId, type: this.obsType },
        (res) => {
          if (res.data.length == 0) {
            this.grouping = [];
            this.obsList = [];
            return;
          }
          if (!acquiesce) {
            this.groupingId = res.data[0].id;
          }
          this.grouping = res.data;
          this.getObs();
        }
      );
    },
    addGrouping() {
      this.inputGrouping = "";
      this.obsGroupingTile = "新增分组";
      this.dialogVisible = true;
    },
    addGroupringFun() {
      let data = {
        shopId: this.shopId,
        obsGroupingName: this.inputGrouping,
        type: this.obsType,
      };
      this.$api.post("shop/addObsGrouping", data, (res) => {
        this.dialogVisible = false;
        if (res.code == 200) {
          this.getObsGrouping();
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    upObsGrouping(item) {
      this.inputGrouping = item.obsGroupingName;
      this.obsGroupingTile = "编辑分组";
      this.dialogVisible = true;
    },
    upObsGroupingFun() {
      let data = {
        id: this.groupingId,
        obsGroupingName: this.inputGrouping,
      };
      this.$api.put("shop/upObsGrouping", data, (res) => {
        this.dialogVisible = false;
        if (res.code == 200) {
          this.getObsGrouping();
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    delGroupringFun() {
      let data = {
        id: this.groupingId,
      };
      this.$api.del("shop/delObsGrouping", data, (res) => {
        this.delDialogVisible = false;
        if (res.code == 200) {
          this.getObsGrouping();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //调用系统上传界面
    uploadShow() {
      if (!this.groupingSelectId) {
        this.$message({
          message: "请先选择分组",
          type: "warning",
        });
        return;
      }
      this.$refs.upload.click();
    },

    //上传图片至obs
    async handleFileChange(e) {
      // 创建ObsClient实例
      var obsClient = new ObsClient({
        access_key_id: "ADLVSN2EVSRCR1SSY52C", // 你的ak
        secret_access_key: "Oimg8nrRxBzYydYqIeX6HWfiuXyEk5WsEHWvPUPB", // 你的sk
        server: "https://obs.cn-north-4.myhuaweicloud.com", // 你的endPoint
      });

      let file = e.target.files;
      console.log(file);
      let that = this;
      let nubSun=0;
      for (let i = 0; i < file.length; i++) {
        const o = file[i];
        let key = "";
        key = this.$uuid + (i + "");
        this.loadingName = `${file.length}个元素上传中...`;
        this.loading = true;
        obsClient.putObject(
          { Bucket: "ddgold", Key: key, SourceFile: o },
          (err, result) => {
            nubSun++
            if (nubSun==file.length) {
              this.loading = false;
            }


            if (err) {
              console.error("Error-->" + err);
              this.$notify.error({
                title: "上传错误",
                message: `${o.name} 上传失败！`,
              });
            } else {
              let detail = {
                url: "https://ddgold.obs.cn-north-4.myhuaweicloud.com/" + key,
                name: o.name,
                size: o.size,
                type: o.type,
              };
              that.temporaryList.push(detail);
              console.log("Status-->" + result.CommonMsg.Status);
              this.$notify({
                title: "成功",
                message: `${o.name} 上传成功！`,
                type: "success",
              });
            }
          }
        );
      }
    },
    //删除图片至obs
    async delFileChange(url, index) {
      let key = url.split("/").splice(-1)[0];
      // 创建ObsClient实例
      var obsClient = new ObsClient({
        access_key_id: "ADLVSN2EVSRCR1SSY52C", // 你的ak
        secret_access_key: "Oimg8nrRxBzYydYqIeX6HWfiuXyEk5WsEHWvPUPB", // 你的sk
        server: "https://obs.cn-north-4.myhuaweicloud.com", // 你的endPoint
      });
      let that = this;
      console.log(key);
      obsClient.deleteObject({ Bucket: "ddgold", Key: key }, (err, result) => {
        if (err) {
          console.error("Error-->" + err);
          this.$message.error("操作失败");
        } else {
          console.log("Status-->" + result.CommonMsg.Status);
          that.temporaryList.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.main {
  width: calc(100% - 20px);
  height: calc(100% - 55px);
  background: #fff;
  padding: 10px;
  position: relative;
  text-align: left;
}

.spanBox {
  /* border:1px solid red; */
  font-size: 12px;
  text-align: center;
  width: 100px;

  margin-left: 15px;
  position: relative;
  display: inline-block;
}

.spanBoxDiv {
  text-align: center;
  width: 100px;
  height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconCuo {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.iconCuo2 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  cursor: pointer;
  z-index: 2;
}

.iconCuo3 {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -1px;
  right: 23px;
  cursor: pointer;
  z-index: 2;
}

.iconJia {
  position: relative;
  width: 30px;
  top: 35px;
}

.bosBox {

  width: 100%;

    text-align: left;
    display: inline-flex;
    padding-bottom: 10px;
    height: 300px;
  overflow: auto;
    flex-wrap: wrap;
}

.buttonIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5px;
  bottom: 4px;
  cursor: pointer;
}

.buttonIcon2 {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 25px;
  bottom: 4px;
  cursor: pointer;
}

.gr-box {
  padding: 2px;
  margin: 2px 0px;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  padding-left: 10px;
}

.gr-box:hover {
  background: #efefef;
  border-radius: 3px;
}

.img {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.img2 {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-left: 10px;
}

.imgGroupingBox {
  display: inline-block;
  width: 280px;
}

.imgBox {
  display: inline-block;
  width: calc(100% - 304px);
  margin-left: 20px;
}

.imgBox2 {
  display: inline-block;
  width: calc(100% - 4px);
  margin-left: 0px;
}

.inputP {
  width: 200px;
  margin-left: 10px;
}

.el-card {
  height: calc(100vh - 185px);
}
.groupBox {
  background: #0000009b;
  color: #fff;
  position: absolute;
  bottom: 23px;
  padding: 2px 4px;
  font-size: 12px;
  width: 92px;
  text-align: left;
}

.upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #e4e4e4;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
div/deep/.el-card__body {
    padding: 20px;
    height: calc(100% - 120px) !important;
    overflow: auto !important;
    /* border: 1px solid red !important; */
}
</style>
