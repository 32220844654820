<template>
    <div class="main">
        <el-button size="mini" class="addButton" @click="addShopButton()" type="primary">新增商户</el-button>
        <el-button size="mini" class="addButton" @click="shopList = [], getShopList()" type="primary">刷新</el-button>
        <div class="table">
            <template>
                <el-table :data="shopList" stripe style="width: 100%">
                    <el-table-column prop="shopId" label="店铺号" width="100">
                    </el-table-column>
                    <el-table-column prop="ShopName" label="店铺" width="180">
                    </el-table-column>
                    <el-table-column prop="appId" label="小程序ID" width="200">
                    </el-table-column>
                    <el-table-column prop="shopAddress" label="店铺地址" width="280">
                    </el-table-column>
                    <el-table-column prop="name" label="店铺负责人">
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话">
                    </el-table-column>
                    <el-table-column prop="addTime" label="创建时间">
                    </el-table-column>
                    <el-table-column prop="endTime" label="到期时间">
                    </el-table-column>
                    <el-table-column prop="address" label="店铺状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == '1'">正常</div>
                            <div style="color:red" v-if="scope.row.state == '2'">终止服务</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="是否过期">
                        <template slot-scope="scope">
                            <div v-if="scope.row.expired == '1'">正常</div>
                            <div style="color:red" v-if="scope.row.expired == '0'">过期</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" width="300" label="操作">
                        <template slot-scope="scope">

                            <el-button size="mini" @click="updateInfoButton(scope.row)">编辑</el-button>

                            <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info"
                                icon-color="red" title="确定删除该分类吗？" @confirm="delTypeButton(scope.row)">
                                <el-button size="mini" slot="reference" type="danger">删除</el-button>
                            </el-popconfirm>
                            <el-button size="mini" @click="updateAppButton(scope.row)">小程序配置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <!-- 新增弹窗 -->
        <el-dialog :title="windowTitle" :visible.sync="addShow" width="500px">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="使用时长" prop="timeChange" size="small" v-if="windowTitle == '新增商户'">
                    <el-select v-model="form.timeChange" placeholder="请选择截止日期">
                        <el-option label="半年体验" value="0.5"></el-option>
                        <el-option label="使用一年" value="1"></el-option>
                        <el-option label="使用二年" value="2"></el-option>
                        <el-option label="使用三年" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="负责人名称" prop="name" size="small">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="负责人电话" prop="phone" size="small">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="店铺名称" prop="shopName" size="small">
                    <el-input v-model="form.shopName"></el-input>
                </el-form-item>

                <el-form-item label="店铺地址" prop="shopAddress">
                    <el-input type="textarea" v-model="form.shopAddress"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addShow = false">取 消</el-button>
                <el-button v-if="windowTitle == '新增商户'" size="mini" type="primary" @click="sava()">提 交</el-button>
                <el-button v-else size="mini" type="primary" @click="updateInfoButtonOk()">保 存</el-button>
            </span>
        </el-dialog>
        <!-- 配置信息弹窗 -->
        <el-dialog title="小程序配置" :visible.sync="addShow2" width="500px">
            <el-form :model="form2" :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
                <el-form-item label="AppId" prop="appId" size="small">
                    <el-input v-model="form2.appId"></el-input>
                </el-form-item>
                <el-form-item label="AppSecret" size="small">
                    <el-input v-model="form2.appSecret"></el-input>
                </el-form-item>
                <el-form-item label="七牛云" size="small">
                    <el-input v-model="form2.qiniu"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addShow2 = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="updateAppButtonOk()">保 存</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Sortable from 'sortablejs'//引入插件
export default {
    data() {
        return {
            shopId: '',
            shopList: [],
            form: {
                name: "",
                phone: "",
                shopAddress: "",
                shopName: "",
                timeChange: "1",
            },
            form2: {
                appId: "",
                qiniu: "",
                appSecret: "",
            },
            rules: {
                timeChange: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                shopName: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                shopAddress: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                appId: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
            },
            addShow: false,
            addShow2: false,
            windowTitle: "新增商户",

            input: "",
            nowObj: "",
            relevanceGoods: [],
            dialogTableVisible: false,
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId")
        this.getShopList();


    },
    methods: {
        getShopList() {
            let data = {
                userId: this.shopId
            }
            this.$api.post("super/getMerchant", data, (res) => {

                this.shopList = res.data;
            })

        },
        //新增弹窗显示
        addShopButton() {
            this.windowTitle = '新增商户';
            this.input = "";
            this.addShow = true;
        },
        sava() {
            let data = this.form;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.addShow = false;
                    this.$api.post("super/addMerchant", data, (res) => {
                        console.log(res);
                        if (res.code == 200) {
                            this.getShopList()
                            this.$message({
                                message: '新增成功',
                                type: 'success'
                            });
                        } else {
                            this.addShow = true;
                            this.$message.error('操作失败');
                        }
                    })
                }
            });


        },
        //编辑基本信息
        updateInfoButton(e) {
            console.log(e);
            this.windowTitle = '编辑商户信息';
            this.addShow = true;
            this.form.name = e.name;
            this.form.phone = e.phone;
            this.form.shopAddress = e.shopAddress;
            this.form.shopName = e.ShopName;
            this.form.shopId = e.shopId;
            this.form.adminId = '999999';
        },
        updateInfoButtonOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {

                    this.$api.put("super/upMerchantInfo", this.form, (res) => {
                        console.log(res);
                        if (res.code == 200) {
                            this.addShow = false;
                            this.getShopList()
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                        } else {
                            this.$message.error('修改失败');
                        }
                    })
                }
            });


        },
        //配置小程序信息
        updateAppButton(e) {
            console.log(e);
            this.addShow2 = true;
            this.form2.appId = e.appId;
            this.form2.qiniu = e.qiniu;
            this.form2.appSecret = '********************';
            this.form2.shopId = e.shopId;
            this.form2.adminId = '999999';
        },
        updateAppButtonOk() {
            this.$refs.ruleForm2.validate((valid) => {
                if (valid) {
                    this.$api.put("super/upAppId", this.form2, (res) => {
                        console.log(res);
                        
                        if (res.code == 200) {
                            this.addShow2 = false;
                            this.getShopList()
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                        } else {
                            this.$message.error('修改失败');
                        }
                    })
                }
            });


        },






    }

}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}
</style>