<template>
  <div class="box">
    <div class="boxSty1"></div>
    <div class="boxSty2"></div>
    <div class="title">

      <img class="logoSty" src="@/assets/logo.png" alt="" srcset="">
      <span style="top:-28px;position: relative;">
        Only+黄金零售管理系统

      </span>
    </div>
    <img class="pcImg" src="../assets/pcBack.png" alt="" srcset="">
    <!-- <img class="backImg"  src="../assets/Shake.png" alt="" srcset=""> -->
    <div class="loginBox" :style="{ background: activeName == 'second' ? '#fff' : '' }">
      <el-tabs v-model="activeName" @tab-click='typeSel'>
        <el-tab-pane label="账户登录" name="first">
          <el-input class="inputBox" v-model="admin" placeholder="请输入账号"></el-input>
          <el-input class="inputBox" placeholder="请输入密码" v-model="passWord" show-password></el-input>
        </el-tab-pane>
        <el-tab-pane label="二维码登录" name="second" style="position: relative;cursor: pointer; ">
          <vue-qr :text="qrValue" :options="qrOptions" style="width: 180px;height: 180px;"
            :style="{ opacity: codeShow ? '0.05' : '1' }"></vue-qr>
          <i class="el-icon-refresh shua" v-if="codeShow" @click="addCode"></i>
          <div class="codeSty" v-if="codeShow">二维码已失效，请刷新</div>
        </el-tab-pane>
        <!-- <el-tab-pane label="验证码登录" name="second">
          <el-input class="inputBox" v-model="admin" placeholder="请输入手机号"></el-input>
          <el-input class="inputBox" v-model="admin" placeholder="请输入验证码"></el-input>
        </el-tab-pane> -->
      </el-tabs>
      <div v-if="activeName == 'first'">
        <el-button class="buttonPoin" type="primary" plain>忘记密码</el-button>
        <el-button @click="Login()" class="buttonPoin" type="primary">登录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr';
function generateUniqueString() {
  return 'c' + Math.random().toString(36).substring(2) + Date.now().toString(36);
}
export default {
  components: {
    VueQr
  },
  data() {
    return {
      admin: '',
      passWord: '',
      activeName: "first",
      qrValue: 'https://example.com',
      qrOptions: {
        width: 150,
        height: 150,
        margin: 0,
      },
      codeShow: false
    };
  },

  methods: {
    Login() {

      let data = {
        admin: this.$fun.setCry(this.admin),
        passWord: this.$fun.setCry(this.passWord),
      }
      this.$api.post('login', data, res => {
        //console.log(res.data);
        console.log(this.$store.state.userId);
        if (res.code == 200) {
          this.$notify({
            title: '登录成功',
            message: '欢迎回来' + res.data.name,
            type: 'success'
          });
          localStorage.setItem("token", res.token)
          localStorage.setItem("shopId", res.data.shopId)
          localStorage.setItem("phone", res.data.phone)
          localStorage.setItem("user", JSON.stringify(res.data))
          this.$router.push("/homeSun")
        } else {
          this.$message.error('账户或密码错误');
        }
      })
      return

    },
    typeSel(e) {
      console.log(e);
      if (e.name == "second") {
        this.addCode();
      }
    },
    //生成二维码
    addCode() {
      let code = generateUniqueString();
      let data = {
        code: code
      }
      this.$api.post('addTowCode', data, res => {
        if (res.code == 200) {
          this.qrValue = code;
          this.codeShow = false;
          this.getLoginStat()
        } else {
          this.$message.error('响应错误');
        }
      })

    },
    //查询登录状态
    getLoginStat() {
      let count = 0; // 计数器，记录循环次数
      let data = {
        code: this.qrValue
      }
      let that = this;
      const intervalId = setInterval(function () {

        that.$api.post('getTowCode', data, res => {
          if (res.code == 200) {
            clearInterval(intervalId);
            that.$notify({
              title: '登录成功',
              message: '欢迎回来' + res.data.name,
              type: 'success'
            });
            localStorage.setItem("token", res.token)
            localStorage.setItem("shopId", res.data.shopId)
            localStorage.setItem("phone", res.data.phone)
            localStorage.setItem("user", JSON.stringify(res.data))
            that.$router.push("/homeSun")
           
          } else {

          }
        })
        count++; // 每次循环计数加20
        if (count >= 10) {
          that.codeShow = true;
          clearInterval(intervalId); // 达到循环次数上限后终止循环
        }
      }, 3000); // 每隔三秒执行一次函数









    }

  }


}
</script>

<style scoped>
.box {
  width: 100%;
  height: 100vh;
  background: #00228A;
  position: relative;
}

.boxSty1 {
  width: 100%;
  height: 30vh;
  background: #F4F4F4;
  position: relative;
  animation: myfirst 2s;
}

@keyframes myfirst {
  from {
    height: 0vh;
  }

  to {
    height: 30vh;
  }
}

.boxSty2 {
  width: 100%;
  height: 70vh;
  background: #00228A;
  position: relative;
}

.codeSty {
  font-size: 12px;
  color: #000
}

.backImg {
  width: 100%;
  height: 70vh;
  position: absolute;
  top: 30vh;
  left: 0px;
  opacity: 0.1;
  animation: myfirst4 6s;
}

.shua {
  position: absolute;
  top: 87px;
  right: 170px;

}

.loginBox {
  width: 360px;
  height: 250px;
  background: #ffffffb6;
  border-radius: 20px;
  border: 1px solid #eeffff;
  position: absolute;
  top: calc(20vh + 140px);
  right: calc(50% - 180px);
  padding: 20px;
  animation: myfirst3 3s;
}

.inputBox {
  width: 300px;
  margin: 10px 0px;
}

.buttonPoin {
  position: relative;
  left: 60px;
  top: 10px;
}

.title {
  width: 100%;
  position: absolute;
  top: calc(15vh - 30px);
  z-index: 2;
  color: #000;
  font-size: 60px;
  font-weight: bold;
  animation: myfirst2 4s;
}

@keyframes myfirst2 {

  0% {
    opacity: 0;
    top: 0
  }

  25% {
    opacity: 0;
    top: 0
  }

  50% {
    opacity: 1;
    top: calc(15vh - 30px);
  }
}

@keyframes myfirst3 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes myfirst4 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.15;
  }
}

@keyframes myfirst5 {
  0% {
    opacity: 0;
  }

  50% {
    transform: rotateY(180deg)
  }

  100% {
    transform: rotateY(0deg)
  }
}

.logoSty {
  width: 115px;
  height: 115px;
  animation: myfirst5 4s;

}

.pcImg {
  width: 30%;
  height: 55%;


  position: absolute;
  bottom: 8vh;
  left: 1vh;
  opacity: 0.1;
  animation: myfirst4 6s;
}
</style>
