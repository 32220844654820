<template>
    <div class="main">

        <el-input v-model="form.seekValue" placeholder="客户名称、手机号模糊搜索" size="small" class="inputSty"
            clearable></el-input>
        <el-select v-model="form.memberId" placeholder="选择执行人员" size="small" class="inputSty" clearable>
            <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>

        <el-date-picker style="width: 300px;top: 1.5px;" v-model="dateArr" value-format="yyyy-MM-dd" type="daterange"
            size="small" range-separator="至" start-placeholder="执行开始日期" end-placeholder="执行结束日期" class="inputSty">
        </el-date-picker>


        <el-button size="small" class="addButton" @click="tableData = [], getWarns()" type="primary">查询</el-button>
        <el-button size="small" class="addButton" @click="addShow = true">创建任务</el-button>

        <!-- 表格显示 -->
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                    <!-- <el-table-column prop="date" label="备注头像" width="80">
                        <template scope="scope">
                            <div class="goodBox">
                                <el-image fit="cover" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]"
                                    class="goodImg"></el-image>
                            </div>
                        </template>
</el-table-column> -->
                    <el-table-column prop="title" label="任务标题"></el-table-column>
                    <el-table-column prop="name" label="执行人员"></el-table-column>
                    <!-- <el-table-column prop="name" label="备注名称"></el-table-column>
                    <el-table-column prop="name" label="跟进人员"></el-table-column>-->
                    <el-table-column prop="crm_intention" label="任务类型">
                        <template scope="scope">
                            <div class="statSty" style="background: #149514" v-if="scope.row.type == '1'">自动任务</div>
                            <div class="statSty" style="background: #ee8c03" v-if="scope.row.type == '2'">待办事项</div>
                            <div class="statSty" style="background: #0346ee" v-if="scope.row.type == '3'">指派任务</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="crm_intention" label="任务状态">
                        <template scope="scope">
                            <div class="statSty" style="background: #a1a8a1" v-if="scope.row.stat == '1'">新建</div>
                            <div class="statSty" style="background: #9b3e3e" v-if="scope.row.stat == '2'">延期</div>
                            <div class="statSty" style="background: #149514" v-if="scope.row.stat == '3'">完成</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="details" label="任务内容"></el-table-column>
                    <el-table-column prop="warnDate" label="执行时间"></el-table-column>
                    <el-table-column prop="addTime" label="创建时间"></el-table-column>
                    <el-table-column prop="address" width="300" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="detailsFun(scope.row)">用户信息</el-button>
                            <el-button size="mini" type="primary"
                                @click="getFollowDetailsFun(scope.row)">动态信息</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

        </div>

        <!-- 用户跟进记录弹窗 -->
        <el-dialog title="用户跟进记录" :visible.sync="addTypeShow" width="800px">

            <el-table :data="obs2" stripe style="width: 100%">
                <!-- <el-table-column prop="date" label="备注头像" width="80">
                    <template scope="scope">
                        <div class="goodBox">
                            <el-image fit="cover" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]"
                                class="goodImg"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="客户名称"></el-table-column>
                <el-table-column prop="name" label="跟进人员"></el-table-column> -->
                <el-table-column prop="crm_intention" label="动态内容">
                    <template scope="scope">
                        <div>【{{ scope.row.title || "系统记录" }}】 {{ scope.row.note }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="跟进人员" width="150px"></el-table-column>
                <el-table-column prop="addDate" label="动态时间" width="150px"></el-table-column>

            </el-table>
        </el-dialog>
        <!-- 详情弹窗 -->
        <el-dialog title="用户详情" :visible.sync="addTypeShow2" width="600px">

            <div class="textSty">用户昵称：{{ obs.nickname }}</div>
            <div class="textSty">备注昵称：{{ obs.crmName }}</div>
            <div class="textSty">登录手机号：{{ obs.phone }}</div>
            <div class="textSty">备注手机号：{{ obs.crmPhone }}</div>
            <div class="textSty">跟进人员：{{ obs.sellName }}</div>
            <div class="textSty">微信号：{{ obs.crmgetWx }}</div>
            <div class="textSty">首次进入时间：{{ obs.addTime2 }}</div>
            <div class="textSty">备注意图：{{ obs.crm_intention }}</div>
            <div class="textSty">进入类型：{{ obs.entryType | entryTypeFI }}</div>
            <div class="textSty">会员类型：{{ obs.crmType | equalType }}</div>
            <div class="textSty">邀约时间：{{ obs.shareTime2 }}</div>
            <div class="textSty">受邀时间：{{ obs.lookTime }}</div>
            <div class="textSty">最近活跃时间：{{ obs.ApTime2 }}</div>
            <div class="textSty">客户姓名：{{ obs.user_name }}</div>
            <div class="textSty">客户性别：{{ obs.user_sex }}</div>
            <div class="textSty">客户生日：{{ obs.user_birthday }}</div>
            <div class="textSty">客户意图：{{ obs.user_intention }}</div>
            <div class="textSty">门店浏览量：{{ obs.pageView }}</div>
            <div class="textSty">商品浏览量：{{ obs.pageView }}</div>
            <div class="textSty">商品收藏量：{{ obs.orderNo }}</div>
            <div class="textSty">用户头像：
                <el-image fit="cover" :src="obs.userAvatar" :preview-src-list="[obs.userAvatar]"
                    style="width: 100px;"></el-image>
            </div>
            <div class="textSty">备注截图：
                <el-image fit="cover" :src="obs.crmImage" :preview-src-list="[obs.crmImage]"
                    style="width: 100px;"></el-image>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addTypeShow2 = false">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 创建任务新增弹窗 -->
        <el-dialog title="创建任务" :visible.sync="addShow" width="600px">
            <el-form :model="form3" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

                <el-form-item label="任务类型" prop="type" maxlength="10" size="small">
                    <el-select v-model="form3.type" placeholder="权限类型">
                        <el-option label="代办事项" value="2"></el-option>
                        <el-option label="指派任务" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指派人员" prop="memberId" maxlength="10" size="small" v-if="form3.type=='3'" >
                    <el-select v-model="form3.memberId" placeholder="选择执行人员" size="small" class="inputSty" clearable>
                        <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行时间" prop="warnTime" maxlength="10" size="small">
                    <el-date-picker v-model="form3.warnTime" type="date" class="inputSty" value-format="yyyy-MM-dd"
                        placeholder="选择日期" size="small">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="任务标题" prop="title" maxlength="10" size="small">
                    <el-input v-model="form3.title"></el-input>
                </el-form-item>
                <el-form-item label="执行内容" prop="details" maxlength="10" size="small">
                    <el-input type="textarea" v-model="form3.details"></el-input>
                </el-form-item>



            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addShow = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addTask()">确 定</el-button>

            </span>
        </el-dialog>
    </div>
</template>

<script>


import moment from 'moment';
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            userAdmin: [],
            form: {
                shopId: this.shopId,
                "memberId": "",
                "begWarnTime": "",
                "endWarnTime": ""

            },
            form2: {},
            form3: {
                shopId: localStorage.getItem("shopId"),
                "title": "",
                "details": "",
                "type": "2",
                "memberId": "",
                "memberIdUp": "",
                "warnTime": ""
            },
            dateArr: [],
            textarea: "",
            user: JSON.parse(localStorage.getItem("user")),
            addTypeShow: false,
            addTypeShow2: false,
            obs: {},
            obs2: [],
            addShow: false,
            rules: {

                title: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                details: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                memberId: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                warnTime: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
            },
        }
    },
    //过滤器 类型对应
    filters: {
        entryTypeFI(val) {
            if (val == "1") {
                return "非邀约"
            } else if (val == "2") {
                return "海报邀约"
            } else if (val == "2") {
                return "销售邀约"
            } else if (val == "2") {
                return "分销邀约"
            } else {
                return ""
            }
        },
        equalType(val) {
            if (val == "0") {
                return "灰度客户"
            } else if (val == "1") {
                return "普通客户"
            } else {
                return "会员客户"
            }
        }

    },
    mounted() {
        this.shopId = localStorage.getItem("shopId");
        this.form.shopId = localStorage.getItem("shopId");
        this.getAdminUser();
        this.form.begWarnTime = moment().format('YYYY-MM-DD');

        const threeDaysAgo = moment().add(7, 'days');
        this.form.endWarnTime = threeDaysAgo.format('YYYY-MM-DD');
        this.dateArr = [this.form.begWarnTime, this.form.endWarnTime]


    },
    methods: {
        getAdminUser() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.userAdmin = res.data;
                this.getWarns();
            })
        },
        getWarns() {


            if (this.dateArr) {
                this.form.begWarnTime = this.dateArr[0];
                this.form.endWarnTime = this.dateArr[1];
            } else {
                this.$message({
                    message: '请先选择时间范围',
                    type: 'warning'
                });
                return
            }


            this.$api.post("crm/getWarns", this.form, (res) => {
                res.data.forEach(e => {
                    let arr = this.userAdmin.filter(o => o.id == e.memberId)[0]
                    e.name = arr ? arr.name : '未知'

                });

                this.tableData = res.data.reverse();
            })

        },
        showWin(item) {
            console.log(item);
            if (!(item.stat == '1')) {
                this.$message({
                    message: '该订单已审核，无需重复操作',
                    type: 'warning'
                });
                return
            };
            this.addTypeShow = true;
            this.form2 = {
                "shopId": this.user.shopId,
                "orderNo": item.orderNo,
                "crmMemberId": this.user.id,
            }
        },
        addTask() {
            this.form3.memberIdUp = this.user.id;
            if (this.form3.type == '2') {
                this.form3.memberId = this.user.id;
            }

            this.$refs.ruleForm.validate((valid) => {
                if (valid) {

                    this.$api.post("crm/addWarns", this.form3, (res) => {
                        if (res.code == 200) {
                            this.getWarns()
                            this.addShow = false
                            this.$message({
                                message: '新增成功',
                                type: 'success'
                            });
                        } else {
                            this.$message.error('新增失败');
                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });
        },
        //查看详情
        detailsFun(item) {
            this.addTypeShow2 = true;

            let data = {
                "shopId": this.user.shopId, //必填
                "openId": item.openId //openId
            }

            this.$api.post("crm/getUserDataU", data, (res) => {
                if (res.code == 200) {
                    this.obs = res.data[0];

                }
            })

        },
        //查看根据记录
        getFollowDetailsFun(item) {
            this.addTypeShow = true;

            let data = {
                "shopId": this.user.shopId, //必填
                "openId": item.openId //openId
            }

            this.$api.post("crm/getFollowOp", data, (res) => {
                if (res.code == 200) {
                    this.obs2 = res.data;

                }
            })

        },

    },


}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputSty {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.textSty {
    width: 40%;
    margin: 6px 4%;
    display: inline-flex;
    /* border: 1px solid #efefef; */
}

.statSty {
    background: rgb(0, 255, 0);
    color: rgb(255, 255, 255);
    padding: 0px 5px;
    width: 70px;
    text-align: center;
}
</style>