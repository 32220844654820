<template>
  <el-container>
    <el-header>
      <div class="shopTitle">{{ shop.ShopName }} <span class="name">({{ shop.shopAddress }})</span></div>
      <!-- <img class="photo" :src="shop.shopImg" alt="" srcset=""> -->
      <div class="jinBox">
        <img class="jinImg" src="@/assets/icon/jin.png" alt="" srcset="">
        <span>今日金价：{{ shop.glodPrice }}元</span>

      </div>
      <!-- <img class="menuImg" src="@/assets/icon/menu.png" alt="" srcset=""> -->
      <!-- <el-button size="mini" class="escBox" @click="EscFun()">安全退出</el-button> -->
    </el-header>
    <el-container>
      <!-- 左侧菜单 -->
      <el-aside width="170px">
        <div style="width: 100%; position: relative;">
          <img class="userImgSty" :src="shop.userImg" alt="" srcset="">
          <div class="userNameBox">
            <div style="color:#333;font-weight: bold;font-size: 15px;" >{{ shop.name }}</div>
            <div>管理员
              <img class="vSty" src="@/assets/icon/v2.png"  srcset="">
            </div>
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>

          <el-collapse-item v-for="item in menu" :key="item.id" :title="item.title" :name="item.id">
            <div class="menuButtonBox" v-for="item2 in item.childs" @click="selectMenuFun(item2)" :key="item2.id"
              v-bind:class="{ 'selectClass': selectMemu == item2.id }">
              <!-- <img :src="item2.icon" alt="" srcset=""> -->
              <div>{{ item2.title }}</div>
            </div>
          </el-collapse-item>
          <div class="menuButtonBox">
            <div style="font-size: 15px; text-align: left;left: -2px;" @click="EscFun()">安全退出</div>
          </div>

        </el-collapse>
      </el-aside>
      <el-main>
        <div class="tagBox">

          <el-tag @click="selectMenuFun(tag)" @close="closeTag(index)" v-for="(tag, index) in tags" :key="index"
            :type="tag.id == selectMemu ? '' : 'info'" closable size="medium"
            style="margin-right: 10px;cursor: pointer;">
            {{ tag.title }}
          </el-tag>
        </div>
        <!-- 路由占位符 -->
        <router-view @iconShow="iconShow" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeName: '2',
      tags: [
        { id: "1.1", page: "homeSun", title: '首页概览' }
      ],
      menu: [
        {
          id: "1", title: '店铺管理', icon: 'el-icon-user', childs: [
            { id: "1.1", page: "homeSun", title: '首页概览' },
            { id: "1.3", page: "limitSet", title: '员工管理', icon: require("../assets/icon/menu/quanxian.png") },
            // { id: "1.4", page: "limitSet", title: '角色权限', icon: require("../assets/icon/menu/quanxian.png") },
            { id: "1.5", page: "homeDecoration", title: '首页装修', icon: require("../assets/icon/menu/fangwuzhuangxiu.png") },
            { id: "1.6", page: "homeList", title: '首页列表', icon: require("../assets/icon/menu/fangwuzhuangxiu.png") },
            { id: "1.7", page: "uploadObs", title: '图片素材', icon: require("../assets/icon/menu/sucai.png") },


          ]
        },
        {
          id: "2", title: '商品管理', icon: 'el-icon-goods', childs: [

            { id: "2.0", page: "priceSet", title: '价格设置', icon: require("../assets/icon/menu/sucai.png") },
            { id: "2.1", page: "goodType", title: '分类管理', icon: require("../assets/icon/menu/fenleiguanli.png") },
            { id: "2.2", page: "goodGrouping", title: '分组管理', icon: require("../assets/icon/menu/bianzu_46.png") },
            { id: "2.3", page: "goodAttribute", title: '属性管理', icon: require("../assets/icon/menu/shuxing.png") },
            { id: "2.4", page: "goodUpload", title: '商品上传', icon: require("../assets/icon/menu/xinzengshangpin.png") },
            { id: "2.5", page: "goodManagement", title: '商品管理', icon: require("../assets/icon/menu/shangpinguanli.png") },
            { id: "2.6", page: "takeStock", title: '数据盘点', icon: require("../assets/icon/menu/sucai.png") }
          ]
        },
        {
          id: "3", title: 'CRM管理', icon: 'el-icon-notebook-1', childs: [
            { id: "3.1", title: '用户管理', page: "userMm", },
            { id: "3.2", title: '客户管理', page: "clientMm", },
            { id: "3.3", title: '预约管理', page: "subscribeMm", },
            { id: "3.4", title: '跟进记录', page: "followUp", },
            { id: "3.5", title: '任务管理', page: "taskMm", }
          ]
        },
        {
          id: "5", title: '订单管理', icon: 'el-icon-setting', childs: [

            { id: "5.1", title: '订单管理', page: 'orderList' },
          ]
        },
        {
          id: "6", title: '优惠活动', icon: 'el-icon-setting', childs: [

            { id: "6.1", title: '优惠活动', page: "activePrice" },
            { id: "6.2", title: '活动商品', page: "activeGoodSet" },
          ]
        },
        {
          id: "7", title: '报表管理', icon: 'el-icon-setting', childs: [

            { id: "7.1", page: "userEchars", title: '客户简报', icon: require("../assets/icon/zhanghu.png") },
            { id: "7.2", page: "goodCharts", title: '商品简报' },
            { id: "7.3", page: "takeStockEchars", title: '商品盘点' },
            { id: "7.4", page: "sellCharts", title: '销售简报' },
          ]
        },
        {
          id: "8", title: '系统管理', icon: 'el-icon-setting', childs: [

            { id: "8.1", page: "shopSet", title: '店铺设置', icon: require("../assets/icon/zhanghu.png") },
            // { id: "8.2", title: '企业微信' },
            { id: "8.3", page: "shopLog", title: '操作日志' },
          ]
        },
        // {
        //   id: "9", title: '商户管理', icon: 'el-icon-data-analysis', childs: [
        //     { id: "9.1", page: "superShop", title: '商户列表' },
        //     { id: "9.2", title: '设备管理' },
        //     { id: "9.3", title: '商家公告' },
        //   ]
        // },

      ],
      selectMemu: "1.1",
      shop: JSON.parse(localStorage.getItem('user')),

    };
  },
  mounted() {
    // let id = this.menu.filter(o=>o.page==this.$route.path.slice(1))
    console.log("路由", this.$route.path.slice(1));
    let url = this.$route.path.slice(1)
    this.menu.forEach(e => {
      e.childs.forEach(o => {
        if (o.page == url) {
          this.selectMemu = o.id
          this.activeName = o.id.slice(0, 1);
          this.tags.push(o)
        }
      })
    });



  },

  methods: {
    selectMenuFun(e) {
      console.log(e);
      this.selectMemu = e.id;
      this.activeName = e.id.slice(0, 1);
      if (!(this.tags.some(o => o.id == e.id))) {
        this.tags.push(e)
      }
      this.$router.push({ name: e.page })

    },
    EscFun() {
      this.$message({
        message: '已安全退出！',
        type: 'success'
      });
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      this.$router.push("/login")
    },
    iconShow(e) {
      let arr = [];
      this.menu.forEach(o => {
        if (o.childs) {
          o.childs.forEach(e => {
            arr.push(e);
          })
        }
      })
      this.selectMemu = arr.filter(o => o.page == e)[0].id;
    },
    //关闭tag
    closeTag(index) {
      this.tags.splice(index, 1)
      this.selectMenuFun(this.tags[index - 1])
    },
  }
}
</script>
<style scoped>
.escBox {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.photo {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 8px;
  left: 15px;
  border-radius: 10px;
  display: inline-table;

}

.menuImg {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 13px;
}

.jinBox {
  position: absolute;
  right: 20px;
  /* border: 1px solid red; */
  top: 16px;
  font-size: 14px;
}

.jinImg {
  width: 28px;
  height: 28px;
  position: absolute;
  left: -35px;
  top: -4px;
}

.shopTitle {
  line-height: 55px;

  text-align: center;
  font-size: 19px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  left: 10px;

}

.adminType {
  font-size: 12px;
  font-weight: normal;

}

.name {
  font-size: 15px;
  font-weight: bold;

}

.el-header>.adminbox {
  position: relative;
  display: inline-block;
  /* border:1px solid red; */
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  top: 10px;
  left: 5px;
}

.el-header {
  position: relative;
  /* background-color: #001862; */
  background-color: #00228A;
  /* background-color: #002BAD; */
  color: #fff;
  text-align: left;
  height: 50px !important;
}

.el-main {
  background-color: #f6f6f6;
  color: #333;
  text-align: center;
  height: calc(100vh - 50px);
  border: 1px solid #cdcdcd;
  padding: 10px;
  padding-top: 5px;
}

.el-aside {
  background-color: #fff;
  color: #333;
  text-align: left;
  height: calc(100vh - 50px);
  overflow-x: hidden;
  padding: 10px;
}

.el-aside::-webkit-scrollbar {
  width: 0px;
  /* 设置滚动条的宽度 */
}

.el-submenu__title {
  padding: 0px !important;
}

.el-submenu__icon-arrow {
  right: 15px !important;
}

.el-submenu {
  position: relative;
  left: -15px;
  width: 155px;

}

.el-submenu .el-menu-item {
  padding: 0 75px 0 45px !important;
}

.el-menu {
  border: 1px solid #fff !important;
  position: relative;
}

.menuButtonBox {
  font-size: 14px;
  text-align: left;
  line-height: 14px;
  padding: 13px;
  font-weight: 500;
}

.menuButtonBox:hover {
  /* border:1px solid #409EFF; */
  background: #51a7fe12;
  cursor: pointer;
}

.menuButtonBox img {
  width: 35px;
  height: 35px;
  position: relative;
  top: 5px;
  margin-bottom: 5px;
}

.menuButtonBox div {
  position: relative;
  /* bottom: 5px; */
}

.selectClass {
  border-left: 3px solid #409EFF;
  color: #409EFF;
  background: #51a7fe12;

}

div/deep/.el-collapse-item__content {
  padding-bottom: 10px !important;

}

div/deep/.el-collapse-item__header {
  font-size: 15px !important;
  padding-left: 10px;
}

.el-collapse-item__wrap {
  text-align: left;
}

.userImgSty {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 10px 0px;

}

.tagBox {
  /* border:1px solid red; */
  width: 100%;
  height: 35px;
  text-align: left;

  white-space: nowrap;
  /* 防止内容换行 */
  overflow-x: auto;
  /* 允许水平滚动 */
  overflow-y: hidden;
  /* 隐藏垂直滚动条 */

}

.userNameBox {
  line-height: 30px;
    font-size: 13px;
    color: #666666;
    /* border: 1px solid red; */
    position: absolute;
    right: 5px;
    top: 17px;
    width: 88px;
}
.userNameBox > div{
  line-height: 20px;
}
.vSty{
  width: 13px;
    height: 13px;
    position: relative;
    top: 2px;
    left: -1px;
}
</style>
