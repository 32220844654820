<template>
  <div>
    <div>
      选择分组：
      <el-select v-model="groupingId" size="mini" placeholder="请选择分组" @change="groudingChange">
        <el-option v-for="item in grouping" :key="item.id" :label="item.obsGroupingName" :value="item.id"> </el-option>
      </el-select>
      名称筛选：

        <el-input size="mini"
            style="width: 200px"
            prefix-icon="el-icon-search"
            @input="inputChange"
            placeholder="按名称筛选"
            v-model="seekValue"
            clearable
          >
          </el-input>

      <el-button @click="uploadObs()" class="buttonPosition" size="mini">本地上传</el-button>
    </div>
    <div class="imgBox">
      <span class="spanBox" v-for="(item, index) in obsList" :key="index" @click="item.select = !item.select" :style="`${item.select ? 'border:1px solid #00228A' : ''}`">
        <img v-if="obsType == '图片'" class="img" :src="item.url" alt="" srcset="" />
        <Video v-if="obsType == '视频'" class="img"> </Video>
        <div class="spanBoxDiv">{{ item.name }}</div>
      </span>
    </div>
    <el-button @click="$emit('close', 11)" class="button1" size="mini">取 消</el-button>
    <el-button @click="OK()" class="button2" size="mini" type="primary">确 认</el-button>

    <!-- 上传图片弹窗 -->
    <div v-if="dialogShow2">
      <el-dialog title="上传图片" :visible.sync="dialogShow2" width="600px" :append-to-body="true">
        请选择分组：
        <el-select v-model="groupingSelectId" size="mini" placeholder="请选择分组">
          <el-option v-for="item in grouping" :key="item.id" :label="item.obsGroupingName" :value="item.id"> </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <input style="display: none" ref="upload" id="file" @change="handleFileChange" accept="mp4/*" type="file" :multiple="true" />
          <div class="bosBox">
            <div class="upload" @click="$refs.upload.click()">
              <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="" />
            </div>
            <span class="spanBox2" v-for="(item, index) in temporaryList" :key="index">
              <img class="iconCuo" src="@/assets/icon/del.png" alt="" srcset="" @click="delFileChange(item.url, index)" />
              <img class="img" :src="item.url" alt="" srcset="" />
              <div class="spanBoxDiv">{{ item.name }}</div>
            </span>
          </div>

          <el-button size="mini" @click="dialogShow2 = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="addObs()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ObsClient from "esdk-obs-browserjs";
import Video from "@/components/video";
export default {
  props: ["propsObsType"],
  data() {
    return {
      grouping: [],
      shopId: "",
      obsType: "图片",
      groupingId: "",
      srcList: [],
      obsList: [],
      dialogShow2: false,
      groupingSelectId: "",
      temporaryList: [],
      obsListCory: [],
      seekValue:"",
    };
  },
  mounted() {
    this.obsType = this.propsObsType;
    this.shopId = localStorage.getItem("shopId");
    this.getObsGrouping();
    this.obsList.forEach((o) => {
      o.select = false;
    });
  },
  methods: {
    getObsGrouping() {
      this.$api.post("shop/getObsGrouping", { shopId: this.shopId, type: this.obsType }, (res) => {
        this.groupingId = res.data[0].id;
        this.grouping = res.data;
        this.getObs();
      });
    },
    //对象模糊查询
    inputChange(e) {
      console.log(e);
      let keyword = this.seekValue;
      const filter = keyword.toUpperCase();
      let data = JSON.parse(JSON.stringify(this.obsListCory));

      // 使用 filter 方法对数组进行过滤
      const filteredData = data.filter((item) => {
        // 判断名字是否包含搜索关键词
        // return item.name.toUpperCase().includes(filter);
        // 获取对象名称的前缀
        const namePrefix = item.name.toUpperCase().substring(0, filter.length);
        // 判断对象名称前缀是否与搜索关键词匹配
        return namePrefix === filter;
      });

      this.obsList = filteredData;
    },
    //上传图片至obs
    async handleFileChange(e) {
      // 创建ObsClient实例
      var obsClient = new ObsClient({
        access_key_id: "ADLVSN2EVSRCR1SSY52C", // 你的ak
        secret_access_key: "Oimg8nrRxBzYydYqIeX6HWfiuXyEk5WsEHWvPUPB", // 你的sk
        server: "https://obs.cn-north-4.myhuaweicloud.com", // 你的endPoint
      });
      let file = e.target.files;
      console.log(file);
      let that = this;
      for (let i = 0; i < file.length; i++) {
        const o = file[i];
        let key = "";
        key = this.$uuid + (i + "");
        obsClient.putObject({ Bucket: "ddgold", Key: key, SourceFile: o }, (err, result) => {
          if (err) {
            console.error("Error-->" + err);
          } else {
            let detail = {
              url: "https://ddgold.obs.cn-north-4.myhuaweicloud.com/" + key,
              name: o.name,
              size: o.size,
              type: o.type,
            };
            that.temporaryList.push(detail);
            console.log("Status-->" + result.CommonMsg.Status);
          }
        });
      }
    },
    //删除图片至obs
    async delFileChange(url, index) {
      let key = url.split("/").splice(-1)[0];
      // 创建ObsClient实例
      var obsClient = new ObsClient({
        access_key_id: "ADLVSN2EVSRCR1SSY52C", // 你的ak
        secret_access_key: "Oimg8nrRxBzYydYqIeX6HWfiuXyEk5WsEHWvPUPB", // 你的sk
        server: "https://obs.cn-north-4.myhuaweicloud.com", // 你的endPoint
      });
      let that = this;
      console.log(key);
      obsClient.deleteObject({ Bucket: "ddgold", Key: key }, (err, result) => {
        if (err) {
          console.error("Error-->" + err);
          this.$message.error("操作失败");
        } else {
          console.log("Status-->" + result.CommonMsg.Status);
          that.temporaryList.splice(index, 1);
        }
      });
    },
    groudingChange(e) {
      console.log("监听变化");
      this.getObs();
    },
    getObs() {
      let data = {
        shopId: this.shopId,
        obsType: this.obsType,
        groupingTypeId: this.groupingId,
        showObs: 1,
      };

      this.$api.post("shop/getObs", data, (res) => {
        res.data.forEach((o) => {
          o.select = false;
        });
        this.obsList = res.data;
        this.obsListCory = JSON.parse(JSON.stringify(res.data));
      });
    },
    OK() {
      let data = [];
      this.obsList.forEach((o) => {
        if (o.select) {
          data.push(o);
        }
      });

      this.$emit("getObsUrl", data);
    },
    //上传图片
    uploadObs() {
      console.log("上传图片");
      this.dialogShow2 = true;
      console.log(this.dialogShow2);
    },
    addObs() {
      if (!this.groupingSelectId) {
        this.$message({
          message: "请先选择分组",
          type: "warning",
        });
        return;
      }
      if (this.temporaryList.length == 0) {
        this.$message({
          message: "请先上传图片",
          type: "warning",
        });
        return;
      }
      let data = {
        shopId: this.shopId,
        obsType: this.obsType,
        groupingTypeId: this.groupingSelectId,
        obs: this.temporaryList,
        date: "2012-09-09",
      };
      this.$api.post("shop/addObs", data, (res) => {
        if (res.code == 200) {
          this.getObsGrouping();
          this.getObs();
          this.dialogShow2 = false;
          this.temporaryList = [];
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error("添加失败");
        }
      });
    },
  },
};
</script>

<style scoped>
.imgBox {
  width: calc(100% - 20px);
  height: 300px;
  border: 1px solid #efefef;
  margin: 20px 0 40px 0;
  padding: 10px;
  overflow: auto;
}

.button1 {
  position: absolute;
  right: 85px;
  bottom: 10px;
}

.spanBoxDiv {
  text-align: center;
  width: 100px;
  height: 20px;
  overflow: hidden;
}

.button2 {
  position: absolute;
  right: 18px;
  bottom: 10px;
}

.iconCuo {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.buttonPosition {
  position: relative;
  left: 20px;
}

.img {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.img2 {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-left: 10px;
}

.spanBox {
  border: 1px solid #fff;
  font-size: 12px;
  text-align: center;
  width: 100px;
  height: 123px;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  overflow: hidden;
}
.spanBox2 {
  border: 1px solid #fff;
  font-size: 12px;
  text-align: center;
  width: 100px;
  height: 119px;
  position: relative;
  display: inline-block;
  margin-left: 15px;
  overflow: hidden;
}

.spanBox:hover {
  border: 1px dashed #00228a;
  cursor: pointer;
}

.bosBox {
  width: 100%;
  /* border:1px solid red; */
  text-align: left;
  display: inline-flex;
  padding-bottom: 10px;
}

.upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #e4e4e4;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.iconJia {
  position: relative;
  width: 30px;
  top: 35px;
}

.spanBox {
  /* border:1px solid red; */
  font-size: 12px;
  text-align: center;
  width: 100px;

  margin-left: 15px;
  position: relative;
  display: inline-block;
}
</style>
