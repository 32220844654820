<template>
    <div>
        <el-tabs v-model="activeIndex" type="card" @tab-click="handleSelect">
            <!-- 小程序页面 -->
            <el-tab-pane :disabled="!config.skipUrl.typeShow.some(o => o == '0')" label="商城页面" name="0">
                <div>
                    基本页面：
                    <el-radio-group v-model="pageUrl" size="small">
                        <el-radio v-for="(item, index) in pageUrlArr" :key="index" :label="item.url" border>{{ item.name
                            }}</el-radio>

                    </el-radio-group>
                </div>
            </el-tab-pane>
            <!-- h5页面 -->
            <el-tab-pane :disabled="!config.skipUrl.typeShow.some(o => o == '1')" label="跳转链接" name="1">
                请输入跳转页面的Url：<el-input style="width: 500px;" size="small" v-model="inputUrl" clearable
                    placeholder="请输入内容"></el-input>
            </el-tab-pane>
            <!-- 分组 -->
            <el-tab-pane :disabled="!config.skipUrl.typeShow.some(o => o == '2')" label="选择分组" name="2">
                选择分组:
                <el-radio-group v-model="goodAttributeId" size="mini" @input="getgoods">
                    <el-radio v-for="(item, index) in goodAttributeData" :key="index" :label="item.id" border>{{
            item.name
        }}</el-radio>
                </el-radio-group>

            </el-tab-pane>
            <!-- 分类 -->
            <el-tab-pane :disabled="!config.skipUrl.typeShow.some(o => o == '3')" label="选择分类" name="3">
                选择分类:
                <el-radio-group v-model="goodGroupingId" size="mini" @input="getgoods">
                    <el-radio v-for="(item, index) in goodGroupingData" :key="index" :label="item.id" border>{{
            item.name
        }}</el-radio>
                </el-radio-group>
            </el-tab-pane>
            <!-- 自定义商品 -->
            <el-tab-pane :disabled="!config.skipUrl.typeShow.some(o => o == '4')" label="自定义选择商品" name="4">
            </el-tab-pane>
        </el-tabs>
        <div v-if="activeIndex == '2' || activeIndex == '3'">
            商品预览：
            <template>
                <el-table class="tableBox" :data="goods" style="width: 100%">

                    <el-table-column prop="goodName" label="图片" width="60">
                        <template scope="scope">
                            <img class="goodPT" :src="scope.row.imgMain" alt="" srcset="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodName" label="商品名称" width="250">
                        <template scope="scope">
                            <div>{{ scope.row.goodName.slice(0, 15) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格" width="180">
                        <template scope="scope">
                            <div>￥{{ scope.row.price }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="groupingName" label="所属分组">
                    </el-table-column>
                    <el-table-column prop="typeName" label="所属分类">
                    </el-table-column>
                    <el-table-column prop="weight" label="重量">
                        <template scope="scope">
                            <div>{{ scope.row.weight }}g</div>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <!-- 自定义商品 -->
        <div v-if="activeIndex == '4'" style="display: flex;">
            <!-- <img class="goodPT2" v-for="(item,index) in selectGoods" :key="index" :src="item.imgMain" alt="" srcset=""> -->
            <div style="width: 70%;display: inline-block; border:1px solid #f0f0f0 ">
                <el-input v-model="inputGoodName" placeholder="商品名称" size="mini" class="inputBox"></el-input>
                <el-select v-model="value" placeholder="选择分类" size="mini" class="inputBox">
                    <el-option v-for="(item, index) in goodGroupingData" :key="index" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
                <el-select v-model="value" placeholder="选择分组" size="mini" class="inputBox">
                    <el-option v-for="(item, index) in goodAttributeData" :key="index" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
                <el-button size="mini" type="primary" @click="getgoods()">筛选</el-button>
                <template>
                    <el-table class="tableBox" :data="goods" style="width: 99%;margin:0.5%;" ref="multipleTable"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="goodName" label="图片" width="60">
                            <template scope="scope">
                                <img class="goodPT" :src="scope.row.imgMain" alt="" srcset="">
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodName" label="商品名称" width="180">
                            <template scope="scope">
                                <div>{{ scope.row.goodName.slice(0, 10) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格" width="100">
                            <template scope="scope">
                                <div>￥{{ scope.row.price }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="groupingName" label="所属分组">
                        </el-table-column>
                        <el-table-column prop="typeName" label="所属分类">
                        </el-table-column>
                        <el-table-column prop="weight" label="重量">
                            <template scope="scope">
                                <div>{{ scope.row.weight }}g</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>

            </div>
            <!-- 已选商品 -->
            <div class="goodsBox">
                <el-card class="box-card" :body-style="{ padding: '0px' }">
                    <div slot="header" class="clearfix">
                        <span>已择商品</span>
                        <el-button @click="selectGoods = []" style="float: right; padding: 3px 0"
                            type="text">全部删除</el-button>
                    </div>
                    <div class="goodSty" v-for="(item, index) in selectGoods" :key="index">
                        <img class="cuoIcon" @click="selectGoods.splice(index, 1)" src="@/assets/icon/cuo.png" alt=""
                            srcset="">
                        <img class="goodPT2" :src="item.imgMain" alt="" srcset="">
                        <div>{{ item.goodName.slice(0, 5) }}</div>
                    </div>
                </el-card>
                <!-- <div>已择商品：</div>
                <div>
                    <div class="goodSty" v-for="(item, index) in selectGoods" :key="index">
                        <img class="cuoIcon" src="@/assets/icon/cuo.png" alt="" srcset="">
                        <img class="goodPT2" :src="item.imgMain" alt="" srcset="">
                        <div>{{ item.goodName.slice(0, 5) }}</div>
                    </div>
                </div> -->
            </div>
        </div>
        <div style="height: 40px;">
            <el-button @click="$emit('close', '')" class="button2" size="small">取 消</el-button>
            <el-button @click="okObsUrl()" class="button" size="small" type="primary">选 择</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["config"],
    data() {
        return {
            activeIndex: "4",
            pageUrl: "",
            pageUrlArr: [
                { name: '商品分类', url: '/sdcdscsd/csdcsdc/cdc1' },
                { name: '我的收藏', url: '/sdcdscsd/csdcsdc/cdc2' },
                { name: '个人中心', url: '/sdcdscsd/csdcsdc/cdc3' },
            ],
            shopId: localStorage.getItem("shopId"),
            goodAttributeData: [],
            goodAttributeId: '',
            goodGroupingData: [],
            goodGroupingId: '',
            goods: [],
            inputUrl: '',
            data: [],
            selectGoods: [],
            value: [],
            filterMethod: [],
            inputGoodName: "",
            once: true
        }
    },
    watch: {
        selectGoods: {
            handler(newVal, oldVal) {
                //console.log("-------");
                //console.log(newVal);
            }

        }
    },
    mounted() {
        let data = this.config.skipUrl
        this.activeIndex = data.typeId;
        if (data.typeId == '0') {
            this.pageUrl = data.value;
        }
        if (data.typeId == '1') {
            this.inputUrl = data.value;
        }
        if (data.typeId == '4') {
            this.selectGoods = data.value;
            this.getgoods()
        }
    },
    methods: {
        //挂载对象选择
        handleSelect(val) {
            console.log("变化", val._props.label);
            if (val._props.label == '选择分组') {
                this.goodGroupingId = "";
                this.getGoodAttribute();
            }
            if (val._props.label == '选择分类') {
                this.goodAttributeId = "";
                this.getGoodGrouping();
            }
            if (val._props.label == '自定义选择商品') {
                this.goodAttributeId = "";
                this.goodGroupingId = "";
                this.getgoods();
            }

        },
        //商品全选
        handleSelectionChange(obj) {
            // if(this.once){
            // this.once =false;
            // return
            // }

            console.log("商品全选");
            if (!this.config.skipUrl.chooseS) {
                if (obj.length > 1) {
                    this.$message({
                        message: '商品不支持多选',
                        type: 'warning'
                    });

                    return
                }
            }
            this.selectGoods = obj;
            console.log(obj);
            //this.toggleSelection(this.selectGoods)

        },
        //操作表格 选择框
        toggleSelection(rows) {
            console.log(rows);
            let arr = JSON.parse(JSON.stringify(rows))
            console.log(arr);

            if (arr) {
                arr.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }

        },
        //获取分组列表
        getGoodAttribute() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("good/getGoodGrouping", data, (res) => {
                this.goodAttributeData = res.data;
                this.goodAttributeId = res.data[0].id;
                this.getgoods();
            })
        },
        //获取分类列表
        getGoodGrouping() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("good/getGoodType", data, (res) => {
                this.goodGroupingData = res.data;
                this.goodGroupingId = res.data[0].id;
                this.getgoods();
            })
        },
        //获取商品
        getgoods() {
            this.goods = [];
            let data = {
                shopId: this.shopId,
                groupingId: this.goodAttributeId,
                typeId: this.goodGroupingId,
                "goodName": "", //商品名称
                "state": "", //商品状态
                "productId": "", //商品ID
                "priceType": "", //价格类型1一口价，2按克工费,3整体工费
                "sort": "", //1收升2收降3浏升4浏降，不填默认
                "priceSort": "", //1价格升2收降3浏升4浏降，不填默认
                "priceMin": "", //价格范围最小
                "priceMax": "" //价格范围最大
            }
            this.$api.post("good/getGoods", data, (res) => {
                this.goods = res.data;
                //this.toggleSelection(this.selectGoods)

            })
        },
        //确定选择
        okObsUrl() {
            let obj = this.config.skipUrl;
            obj.typeId = this.activeIndex;
            if (this.activeIndex == '0') {
                let name = this.pageUrlArr.filter(o => o.url == this.pageUrl)[0].name
                obj.type = '页面';
                obj.showText = '跳转页面至：' + name;
                obj.value = this.pageUrl;
            }
            if (this.activeIndex == '1') {
                obj.value = this.inputUrl;
                obj.type = 'h5链接';
                obj.showText = '跳转至h5链接：' + this.inputUrl;
            }
            if (this.activeIndex == '2') {
                obj.value = this.goodAttributeId;
                obj.type = '分组';
                obj.showText = '跳转至分组：';
            }
            if (this.activeIndex == '3') {
                obj.value = this.goodGroupingId;
                obj.type = '分类';
                obj.showText = '跳转至分类：';
            }
            if (this.activeIndex == '4') {
                obj.value = this.selectGoods;
                obj.showText = '已选择 ' + this.selectGoods.length + ' 个商品';
                obj.type = '单个商品';
            }

            this.$emit("okObsUrl", obj);
            this.$emit('close', '');
        },


    }
}

</script>

<style scoped>
::v-deep .el-table__body tr,
::v-deep .el-table__body td {
    padding: 0;
    height: 40px;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodsBox {
    /* border: 1px solid #f0f0f0; */
    display: inline-block;
    width: 29%;
    position: relative;
    left: 1%;
}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputBox {
    width: 150px;
    margin: 0.5%;
}


.button {
    margin: 10px 0;
    position: absolute;
    right: 20px;
    bottom: 0px;
}

.button2 {
    margin: 10px 0;
    position: absolute;
    right: 90px;
    bottom: 0px;
}

.el-radio {
    margin-right: 0px !important;
}

.goodSty {
    border: 1px solid #f3f3f3;
    width: 60px;
    height: 76px;
    display: inline-block;
    margin: 5px;

    font-size: 12px;
    position: relative;

}

.goodPT2 {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 5px;
    margin-bottom: 0px;
}

.cuoIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 2;
    cursor: pointer;
}
</style>