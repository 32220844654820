<template>
    <div class="main">
        <el-tabs type="border-card">
            <el-tab-pane label="店铺基础配置" style="overflow: auto;">
                <div style="display: flex;">
                    <div style="width: 375px; display: inline-block; border:1px solid #efefef;margin-right: 50px; ">
                        <div style="width: 100%;height: 40px;text-align: center;background: #efefef;color:#666; line-height: 40px;" >手机预览</div>
                        <div >
                            <img class="imgShow"
                                :src="form.shopPhotograph"
                                alt="" srcset="">
                            <div class="box1">
                                <div class="boxImg">
                                    <img class="img2"
                                        :src="form.shopImg"
                                        alt="">
                                    <div class="title">
                                        <div style="font-weight: bold; font-size: 18px;line-height: 24px; ">
                                            {{ form.shopName||'暂未设置店铺名称' }}
                                        </div>
                                        <div style="color:#9a9999; font-size: 12px; ">
                                            8000m
                                        </div>

                                    </div>

                                </div>
                                <div style="font-size: 12px; color:#9a9999; ">
                                    {{ form.shopIntro||'暂未设置店铺简介' }}
                                </div>



                            </div>
                            <div class="box1">
                                <div class="box2">
                                    <img class="icon2"
                                        src="https://myuser.obs.cn-north-4.myhuaweicloud.com/dianhua.png_117081388710930?x-image-process=image/resize,m_lfit,h_100"
                                        alt="" srcset="">
                                    <div class="title2">客服热线</div>
                                    <div class="title3"> {{ form.shopPhone||'暂未设置店铺简介' }}</div>
                                    <img class="icon3" @click="phoneFun()"
                                        src="https://myuser.obs.cn-north-4.myhuaweicloud.com/电话.png_117081400691350?x-image-process=image/resize,m_lfit,h_100"
                                        alt="" srcset="">
                                </div>
                                <div class="box2"
                                    style="border-bottom: 1px solid #efefef;border-top: 1px solid #efefef;">
                                    <img class="icon2"
                                        src="https://myuser.obs.cn-north-4.myhuaweicloud.com/position.png_117081388775590?x-image-process=image/resize,m_lfit,h_100"
                                        alt="" srcset="">
                                    <div class="title2">门店地址</div>
                                    <div class="title3"> {{ form.shopAddress||'暂未设置门店地址' }}</div>
                                    <img class="icon3" @click="navigateToStore()"
                                        src="https://myuser.obs.cn-north-4.myhuaweicloud.com/导航 (1).png_117081392654910?x-image-process=image/resize,m_lfit,h_100"
                                        alt="" srcset="">
                                </div>
                                <div class="box2">
                                    <img class="icon2"
                                        src="https://myuser.obs.cn-north-4.myhuaweicloud.com/时间 (1).png_117081390461660?x-image-process=image/resize,m_lfit,h_100"
                                        alt="" srcset="">
                                    <div class="title2"> 营业时间</div>
                                    <div class="title3">  {{ form.shopTime||'暂未设置门店营业时间' }}</div>

                                </div>


                            </div>







                        </div>

                    </div>
                    <div style="width: 500px; display: inline-block;">

                        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="店铺头像" prop="shopImg" style="width: 400px;">
                                <div class="upload2" v-if="form.shopImg">
                                    <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset="" v-if="!setShow"
                                        @click="form.shopImg = ''">
                                    <el-image class="img" :src="form.shopImg" alt="" srcset=""
                                        :prediv-src-list="srcList" style="width: 100px; height: 100px;"></el-image>
                                </div>
                                <div v-else class="upload" @click="uploadObsShow = true, imgType = '1', obsType = '图片'">
                                    <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="">
                                </div>
                            </el-form-item>
                            <el-form-item label="门店照片" prop="shopPhotograph" style="width: 400px;">
                                <div class="upload2" v-if="form.shopPhotograph">
                                    <img class="iconCuo2" src="@/assets/icon/del.png" alt="" srcset="" v-if="!setShow"
                                        @click="form.shopPhotograph = ''">
                                    <el-image class="img" :src="form.shopPhotograph" alt="" srcset=""
                                        :prediv-src-list="srcList" style="width: 100px; height: 100px;"></el-image>
                                </div>
                                <div v-else class="upload" @click="uploadObsShow = true, imgType = '2', obsType = '图片'">
                                    <img class="iconJia" src="@/assets/icon/jia.png" alt="" srcset="">
                                </div>
                            </el-form-item>
                            <el-form-item label="门店名称" prop="shopName" style="width: 400px;">
                                <el-input v-model="form.shopName" size="small" :disabled="setShow"></el-input>
                            </el-form-item>
                            <el-form-item label="门店简介" prop="shopIntro" style="width: 400px;">
                                <el-input v-model="form.shopIntro" type="textarea" size="small"
                                    :disabled="setShow"></el-input>
                            </el-form-item>

                            <el-form-item label="门店经纬度" prop="shopXY" style="width: 400px;">
                                <el-input v-model="form.shopXY" size="small" :disabled="setShow"></el-input>
                            </el-form-item>
                            <el-form-item label="门店地址" prop="shopAddress" style="width: 400px;">
                                <el-input v-model="form.shopAddress" type="textarea" size="small"
                                    :disabled="setShow"></el-input>
                            </el-form-item>
                            <el-form-item label="客服热线" prop="shopPhone" style="width: 400px;">
                                <el-input v-model="form.shopPhone" size="small" :disabled="setShow"></el-input>
                            </el-form-item>
                            <el-form-item label="营业时间" prop="shopTime" style="width: 400px;">
                                <el-input v-model="form.shopTime" size="small" :disabled="setShow"></el-input>
                            </el-form-item>
                            <div style="width: 400px; text-align: right;">

                                <el-button size="small" v-if="setShow" type="primary"
                                    @click="setShow = false">进行修改</el-button>
                                <el-button size="small" v-if="!setShow" @click="setShow = true"">取 消</el-button>
                        <el-button size=" small" v-if="!setShow" type="primary" @click="save()">保 存</el-button>
                            </div>

                        </el-form>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="自动任务配置">自动任务配置</el-tab-pane>
            <el-tab-pane label="小程序配置">

                <div style=" width: 100%; text-align: center; font-size: 12px;  margin-top:200px " >暂无配置权限~</div>

            </el-tab-pane>

        </el-tabs>


        <!-- <gifComponents /> -->


        <!-- 上传图片弹窗 -->
        <el-dialog title="选择图片" :visible.sync="uploadObsShow" width="800px">
            <obsUpload v-if="uploadObsShow" propsObsType="图片" @getObsUrl="getObsUrl" @close="uploadObsShow = false" />
        </el-dialog>

    </div>
</template>

<script>

import obsUpload from "@/components/obsUpload.vue";
export default {
    components: {
        obsUpload,
    },

    data() {

        return {
            shopId: '',
            user: JSON.parse(localStorage.getItem("user")),
            form: {
                shopId: "",
                memberId: "",
                shopImg: "",
                shopPhotograph: "",
                shopName: "",
                shopIntro: "",
                shopAddress: "",
                shopPhone: "",
                shopTime: "",
                shopXY: "",

            },
            rules: {
                shopName: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopIntro: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopAddress: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopTime: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopPhone: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopXY: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                shopImg: [
                    { required: true, message: '请上传店铺头像', trigger: 'change' }
                ],
                shopPhotograph: [
                    { required: true, message: '请上传门店照片', trigger: 'change' }
                ],
            },
            uploadObsShow: false,
            imgType: "1",
            setShow: true,

        }
    },
    mounted() {
        this.getShopInfo();
    },
    methods: {
        //选择图片
        getObsUrl(e) {
            console.log(e);
            if (this.imgType == '1') {
                this.form.shopImg = e[0].url;
            } else {
                this.form.shopPhotograph = e[0].url;
            }
            this.uploadObsShow = false;
        },
        //保存店铺基础信息
        save() {
            this.form.shopId = this.user.shopId
            this.form.memberId = this.user.id;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$api.put("shop/upShopInfo", this.form, (res) => {
                        if (res.code == 200) {

                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.setShow = true
                            this.getShopInfo()
                        } else {
                            this.$message.error(res.data.msg);

                        }
                    })
                } else {
                    this.$message({
                        message: '请先完善表单！',
                        type: 'warning'
                    });
                }
            });

        },
        //获取店铺的信息
        getShopInfo() {
            this.setShow = true
            this.$api.post("shop/getshopInfo", { shopId: this.user.shopId }, (res) => {
                if (res.code == 200) {
                    this.form = res.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            })

        },

    }
}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.el-tab-pane {
    height: calc(100vh - 160px);
}

.upload {
    width: 100px;
    height: 100px;
    border: 1px dashed #e4e4e4;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
}

.iconJia {
    position: relative;
    width: 30px;
    top: 35px;

}

.upload2 {
    width: 100px;
    height: 100px;
    border: 1px dashed #fff;
    border-radius: 5px;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    position: relative;
}

.iconCuo2 {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 2px;
    cursor: pointer;
    z-index: 2;
}





.box1 {
    width: 335px;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    background: #fff;
}

.imgShow {
    width: 355px;
    height: 150px;
    border-radius: 10px;
    margin: 10px;

}

.boxImg {
    width: 100%;

    position: relative;
}

.img2 {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.title {
    width: 272px;

    position: absolute;
    left: 60px;
    top: 3px;
}

.box2 {
    height: 50px;

    position: relative;
    padding: 5px 0px;
}

.title2 {
    color: #333;
    font-size: 14px;
    position: absolute;
    left: 27px;
    top: 14px;
}

.title3 {
    color: #9a9999;
    font-size: 12px;
    position: absolute;
    left: 25px;
    top: 35px;
}

.icon2 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: -1px;


}

.icon3 {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 10px;
    right: 7px;
}
</style>