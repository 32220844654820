<template>
    <div class="main">

        <div class="box1" style=" padding-top: 40px;height:calc(50% - 30px); ">
            <div class="title" style="top:5px">近10次商品盘点数据趋势</div>
            <qiun-vue-ucharts type="line" :opts="opts" :chartData="chartData" />
        </div>
        <div class="box1" style="height: auto;">
            <el-calendar  @change="dateChange" :range="['2024-03-01', '2019-04-1']" >
                <template slot="dateCell" slot-scope="{date, data}">
                    <div style="font-weight: bold;" >{{ date.getDate() }}</div>
                    <div style="color:red" v-if="dateArr.filter(o=>o.addTime==date.getDate())[0]" >盘点数量：{{dateArr.filter(o=>o.addTime==date.getDate())[0].number  }}</div>
                </template>
            </el-calendar>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import qiunVueUcharts from '@qiun/vue-ucharts'
export default {
    components: {
        qiunVueUcharts
    },
    data() {
        return {
            value: "",
            chartData: {},
            chartData2: {},
            chartData3: {},
            chartData4: {},
            user: JSON.parse(localStorage.getItem("user")),
            //您可以通过修改 config-ucharts.js 文件中下标为 ['column'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
            opts: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 10, 0, 15],
                enableScroll: false,
                legend: {},
                xAxis: {
                    disableGrid: true
                },
                yAxis: {
                    gridType: "dash",
                    dashLength: 2
                },
                extra: {
                    line: {
                        type: "step",
                        width: 2,
                        activeType: "hollow"
                    }
                }
            },
            dateArr:[],

        };
    },
    mounted() {
        this.getServerData1();
        this.getServerData2();
    },
    methods: {
        getServerData1() {
            let data = {
                shopId: this.user.shopId
            }
            this.$api.post("shop/getTakeStockE", data, (res) => {
                let arr = []
                let arr2 = []
                res.data.forEach(e => {
                    arr.push(e.addTime)
                    arr2.push(e.number)
                });

                let res2 = {
                    categories: arr,
                    series: [
                        {
                            name: "商品数量",
                            data: arr2
                        }

                    ]
                };
                this.chartData = JSON.parse(JSON.stringify(res2));

            })

        },
        getServerData2() {
            let data = {
                shopId: this.user.shopId,
                "day":moment().format('YYYY-MM')
            }
            this.$api.post("shop/getTakeStockEday", data, (res) => {
               
                this.dateArr = res.data;
            })




        },
        dateChange(e){
            console.log(e);
        }
    }
}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.box1 {
    width: 100%;
    height: 40%;
    /* border: 1px solid red; */
    display: flex;
    position: relative;
}

.box2 {
    width: 33%;
    height: calc(100% - 50px);
    padding-top: 50px;
    /* border: 1px solid rgb(33, 38, 91); */
    display: inline-block;
    position: relative;
}

.title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-weight: bold;
}
div/deep/.el-button-group {
    display: none !important;
}
</style>