<template>
  <div class="main">
    <el-button size="mini" class="addButton" @click="addTypeButton()" type="primary">新增分类</el-button>
    <el-button size="mini" class="addButton" @click="(tableData = []), getGoodType()">刷新</el-button>
    <!-- <el-button size="mini" class="addButton" @click="getGoodType()" type="primary">获取分类</el-button> -->
    <div class="table">
      <template>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="排序" width="50">
            <img class="iconPX" src="@/assets/icon/px.png" alt="" srcset="" />
          </el-table-column>
          <el-table-column prop="name" label="名称" width="280"> </el-table-column>
          <el-table-column prop="address" label="是否显示">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.showType" active-color="#13ce66" @change="switchFun($event, scope.row)" inactive-color="#ff4949"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="address" width="300" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="updateTypeButton(scope.row)">编辑</el-button>

              <el-popconfirm confirm-button-text="好的" cancel-button-text="不用了" icon="el-icon-info" icon-color="red" title="确定删除该分类吗？" @confirm="delTypeButton(scope.row)">
                <el-button size="mini" slot="reference" type="danger">删除</el-button>
              </el-popconfirm>

              <el-button size="mini" type="primary" plain @click="handleDelete(scope.row)">查看关联商品</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <el-dialog :title="windowTitle" :visible.sync="addTypeShow" width="300px">
        <el-input style="width: 250px" clearable maxlength="10" size="small" v-model="input" placeholder="请输入分类名称"></el-input>

        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="addTypeShow = false">取 消</el-button>
          <el-button v-if="windowTitle == '新增分类'" size="mini" type="primary" @click="savaType()">确 定</el-button>
          <el-button v-else size="mini" type="primary" @click="updateType()">保 存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 关联商品弹窗 -->
    <el-dialog title="关联商品" :visible.sync="dialogTableVisible">
      <el-table class="tableBox" :data="relevanceGoods" style="width: 100%" height="460px">
        <el-table-column prop="goodName" label="图片" width="60">
          <template scope="scope">
            <el-image class="goodPT" fit="cover" :src="scope.row.imgMain + '?x-image-process=image/resize,m_lfit,h_100'" :preview-src-list="[scope.row.imgMain]"></el-image>
   
          </template>
        </el-table-column>
        <el-table-column prop="goodName" label="商品名称" width="250">
          <template scope="scope">
            <div>{{ scope.row.goodName.slice(0, 15) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="180">
          <template scope="scope">
            <div>￥{{ scope.row.price }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="groupingName" label="所属分组">
                </el-table-column> -->
        <el-table-column prop="typeName" label="所属分类"> </el-table-column>
        <el-table-column prop="weight" label="重量">
          <template scope="scope">
            <div>{{ scope.row.weight }}g</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: right; margin-top: 10px" small background layout="prev, pager, next" :page-size="nubPage" :total="relevanceGoods[0] ? relevanceGoods[0].total_count : 20" @current-change="pageChange"> </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs"; //引入插件
export default {
  data() {
    return {
      shopId: "",
      tableData: [],
      addTypeShow: false,
      windowTitle: "新增分类",
      input: "",
      nowObj: "",
      relevanceGoods: [],
      dialogTableVisible: false,
      typeId: "",
      nubPage: 3,
      thePage: 1,
    };
  },
  mounted() {
    this.shopId = localStorage.getItem("shopId");
    this.getGoodType();
    // 阻止默认行为
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.rowDrop(); //行拖拽
  },
  methods: {
    getGoodType() {
      let data = {
        shopId: this.shopId,
      };
      this.$api.post("good/getGoodType", data, (res) => {
        res.data.forEach((o) => {
          o.showType = o.showType ? true : false;
        });
        res.data.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.tableData = res.data;
      });
    },
    //新增弹窗显示
    addTypeButton() {
      this.windowTitle = "新增分类";
      this.input = "";
      this.addTypeShow = true;
    },
    savaType() {
      let data = {
        shopId: this.shopId,
        name: this.input,
        sort: this.tableData.length + 1 + "",
        showType: 1,
      };
      this.$api.post("good/addGoodType", data, (res) => {
        console.log(res);
        this.addTypeShow = false;
        if (res.code == 200) {
          this.getGoodType();
          this.$message({
            message: "新增成功",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    updateTypeButton(e) {
      this.windowTitle = "编辑分类名称";
      this.input = e.name;
      this.addTypeShow = true;
      this.nowObj = e;
      //console.log(e);
    },
    updateType() {
      let data = {
        id: this.nowObj.id,
        name: this.input || this.nowObj.name,
        sort: this.nowObj.sort,
        showType: this.nowObj.showType ? 1 : 0,
      };
      this.$api.put("good/upGoodType", data, (res) => {
        console.log(res);
        this.addTypeShow = false;
        if (res.code == 200) {
          this.getGoodType();
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    delTypeButton(e) {
      //let result = await this.handleDelete(e,true);

      this.handleDelete(e, true).then((result) => {
        if (result !== 0) {
          this.$message.error("该分组已关联商品，不可删除");
        } else {
          let data = {
            shopId: this.shopId,
            id: e.id + "",
          };
          this.$api.del("good/delGoodType", data, (res) => {
            if (res.code == 200) {
              this.getGoodType();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            } else {
              this.$message.error("操作失败");
            }
          });
        }
      });
    },
    //修改分类 显示隐藏
    switchFun(e, data) {
      this.nowObj = data;
      this.updateType();
    },
    //行拖拽插件
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (newIndex == oldIndex) return;
          _this.tableData.splice(newIndex, 0, _this.tableData.splice(oldIndex, 1)[0]);
          var newArray = _this.tableData.slice(0);
          _this.tableData = [];
          _this.$nextTick(function () {
            _this.tableData = newArray;
            _this.sortSave();
          });
        },
      });
    },
    //保存拖拽排序的结果
    sortSave(data) {
      console.log("保存拖拽排序的结果");
      let sort = [];
      this.tableData.forEach((o, index) => {
        sort.push({ id: o.id, sort: index + 1 + "" });
      });
      console.log(sort);
      this.$api.put("good/sortGoodType", sort, (res) => {
        if (res.code == 200) {
          this.getGoodType();
          this.$message({
            message: "排序成功",
            type: "success",
          });
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //查看关联商品
    handleDelete(obs, type) {
      this.typeId = obs.id;
      console.log("查看关联商品", obs);
      if (!type) {
        this.dialogTableVisible = true;
      }
      return new Promise((resolve, reject) => {
        let data = {
          shopId: this.shopId,
          typeId: obs.id + "",
          nubPage: this.nubPage,
          thePage: 1,
        };
        this.$api.post("good/getGoods", data, (res) => {
          console.log(res);
          this.relevanceGoods = res.data;
          resolve(res.data.length);
        });
      });
    },
    //分页查询
    pageChange(e) {
      let data = {
          shopId: this.shopId,
          typeId: this.typeId,
        nubPage: this.nubPage,
          thePage: e,
        };
        this.$api.post("good/getGoods", data, (res) => {
          console.log(res);
          this.relevanceGoods = res.data;
          
        });

    },
  },
};
</script>

<style scoped>
.main {
  width: calc(100% - 20px);
  height: calc(100% - 55px);
  background: #fff;
  padding: 10px;
  position: relative;
  text-align: left;
}

.addButton {
  position: relative;
}

.table {
  border: 1px solid #e9ecf0;
  padding: 10px;
  margin-top: 10px;
  height: calc(100% - 80px);
  overflow: auto;
}

div/deep/.el-dialog__body {
  padding: 5px 20px;
}

.el-button--mini {
  margin: 0px 5px;
}

.iconPX {
  width: 23px;
  height: 21px;
  position: relative;
  top: 3px;
  left: 3px;
  cursor: move;
}

.tableBox {
  margin-top: 10px;

  height: 40vh;
  border: 1px solid #f6f6f6;
}

.goodPT {
  position: relative;
  top: 3px;
  width: 30px;
}
</style>
