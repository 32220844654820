<template>
    <div class="main">

        <div class="box1" style=" padding-top: 40px;height:calc(50% - 30px); ">
            <div class="title" style="top:5px">用户客户增长情况</div>


            <qiun-vue-ucharts  type="column" :opts="opts" :chartData="chartData" />
            <!-- <qiun-data-charts type="line" :opts="opts1" :chartData="chartData" /> -->
            <!-- <qiun-vue-ucharts type="line" :opts="opts1" :chartData="chartData" /> -->

        </div>
        <div class="box1">
            <div class="box2" style="width: 40%;">
                <div class="title">用户活跃度</div>
                <qiun-vue-ucharts type="bar" :opts="opts2" :chartData="chartData2" />
            </div>
            <div class="box2" style="width: 30%;">
                <div class="title">受邀成功率</div>
                <qiun-vue-ucharts type="pie" :opts="opts3" :chartData="chartData3" />
            </div>
            <div class="box2" style="width: 30%;">
                <div class="title">用户登录率</div>
                <!-- <qiun-vue-ucharts type="ring" :opts="opts4" :chartData="chartData4" /> -->
                <qiun-vue-ucharts type="ring" :opts="opts4" :chartData="chartData4" />
            </div>
        </div>




        <!-- 必须要有父元素包裹 -->
        <!-- <div class="charts-box">
            <qiun-vue-ucharts type="column" :opts="opts" :chartData="chartData" />
        </div> -->

    </div>
</template>

<script>
import qiunVueUcharts from '@qiun/vue-ucharts'
export default {
    components: {
        qiunVueUcharts
    },
    data() {
        return {
            chartData: {},
            chartData2: {},
            chartData3: {},
            chartData4: {},
            user: JSON.parse(localStorage.getItem("user")),
            //您可以通过修改 config-ucharts.js 文件中下标为 ['column'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
            opts: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 15, 0, 5],
                legend: {},
                xAxis: {
                    disableGrid: true
                },
                yAxis: {
                    data: [
                        {
                            min: 0
                        }
                    ]
                },
                extra: {
                    column: {
                        type: "group",
                        width: 30,
                        activeBgColor: "#000000",
                        activeBgOpacity: 0.08
                    }
                }
            },
            opts1: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 10, 0, 15],
                enableScroll: false,
                legend: {},
                xAxis: {
                    disableGrid: true
                },
                yAxis: {
                    gridType: "dash",
                    dashLength: 2
                },
                extra: {
                    line: {
                        type: "step",
                        width: 2,
                        activeType: "hollow"
                    }
                }
            },
            opts2: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [15, 30, 0, 5],
                enableScroll: false,
                legend: {},
                xAxis: {
                    boundaryGap: "justify",
                    disableGrid: false,
                    min: 0,
                    axisLine: false,
                    max: 40
                },
                yAxis: {},
                extra: {
                    bar: {
                        type: "group",
                        width: 30,
                        meterBorde: 1,
                        meterFillColor: "#FFFFFF",
                        activeBgColor: "#000000",
                        activeBgOpacity: 0.08,
                        linearType: "custom",
                        barBorderCircle: true,
                        seriesGap: 2,
                        categoryGap: 2
                    }
                }
            },
            opts3: {
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [5, 5, 5, 5],
                enableScroll: false,
                extra: {
                    pie: {
                        activeOpacity: 0.5,
                        activeRadius: 10,
                        offsetAngle: 0,
                        labelWidth: 15,
                        border: false,
                        borderWidth: 3,
                        borderColor: "#FFFFFF"
                    }
                }
            },
            opts4: {
                rotate: false,
                rotateLock: false,
                color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
                padding: [5, 5, 5, 5],
                dataLabel: true,
                enableScroll: false,
                legend: {
                    show: true,
                    position: "right",
                    lineHeight: 25
                },
                title: {
                    name: "登录率",
                    fontSize: 15,
                    color: "#666666"
                },
                subtitle: {
                    name: "70%",
                    fontSize: 25,
                    color: "#7cb5ec"
                },
                extra: {
                    ring: {
                        ringWidth: 60,
                        activeOpacity: 0.5,
                        activeRadius: 10,
                        offsetAngle: 0,
                        labelWidth: 15,
                        border: true,
                        borderWidth: 3,
                        borderColor: "#FFFFFF",
                        linearType: "custom"
                    }
                }
            }

        };
    },
    mounted() {
        this.getServerData1();
        this.getServerData2();
    },
    methods: {
        getServerData1() {
            let data = {
                shopId: this.user.shopId
            }
            this.$api.post("shop/getUserE", data, (res) => {
                let res2 = {
                    categories: res.data.date,
                    series: [
                        {
                            name: "用户数量",
                            data: res.data.user
                        },
                        {
                            name: "客户数量",
                            data: res.data.crm
                        }
                    ]
                };
                this.chartData = JSON.parse(JSON.stringify(res2));
               
                // this.chartData = JSON.parse(JSON.stringify(res2));

            })

        },
        getServerData2() {
            let data = {
                shopId: this.user.shopId
            }
            this.$api.post("shop/getDynamicE", data, (res) => {
                let name = [];
                let value = [];
                res.data.date.forEach(e => {
                    name.push(e.nickname)
                    value.push(e.pageView)
                });
                let res2 = {
                    categories: name,
                    series: [
                        {
                            name: "商品浏览量",
                            data: value
                        },

                    ]
                };
                this.chartData2 = JSON.parse(JSON.stringify(res2));


                let res3 = {
                    series: [
                        {
                            data: [{ "name": "已受邀", "value": res.data.shou.not_null_count }, { "name": "待受邀", "value": res.data.shou.null_count }]
                        }
                    ]
                };
                this.chartData3 = JSON.parse(JSON.stringify(res3));

                let res4 = {
                    series: [
                        {
                            data: [{ "name": "登录", "value": res.data.login.not_null_count }, { "name": "未登录", "value": res.data.login.null_count }]
                        }
                    ]
                };
                this.chartData4 = JSON.parse(JSON.stringify(res4));





            })




        },
    }
}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.box1 {
    width: 100%;
    height: 50%;
    /* border: 1px solid red; */
    display: flex;
    position: relative;
}

.box2 {
    width: 33%;
    height: calc(100% - 50px);
    padding-top: 50px;
    /* border: 1px solid rgb(33, 38, 91); */
    display: inline-block;
    position: relative;
}

.title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-weight: bold;
}
</style>