import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from '../views/login.vue'
import goodType from '../views/goods/goodType'
import goodGrouping from '../views/goods/goodGrouping'
import goodAttribute from '../views/goods/goodAttribute'
import goodManagement from '../views/goods/goodManagement'
import goodUpload from '../views/goods/goodUpload'
import uploadObs from '../views/shop/uploadObs.vue'
import homeDecoration from '../views/shop/homeDecoration.vue'
import homeList from '../views/shop/homeList.vue'
import limitSet from '../views/shop/limitSet.vue'
import shopSet from '../views/shop/shopSet.vue'
import superShop from '../views/superShop/shop'
import homeSun from '../views/shop/homeSun.vue'
import shopLog from '../views/shop/shopLog.vue'
import priceSet from '../views/shop/priceSet.vue'
import orderList from '../views/order/orderList.vue'
import takeStock from '../views/goods/takeStock.vue'

import userMm from '../views/crm/userMm.vue'
import clientMm from '../views/crm/clientMm.vue'
import subscribeMm from '../views/crm/subscribeMm.vue'
import followUp from '../views/crm/followUp.vue'
import taskMm from '../views/crm/taskMm.vue'

import userEchars from '../views/echars/user.vue'
import goodCharts from '../views/echars/goodCharts.vue'
import takeStockEchars from '../views/echars/takeStockEchars.vue'
import sellCharts from '../views/echars/sellCharts.vue'

import activePrice from '../views/active/activePrice.vue'
import activeGoodSet from '../views/active/activeGoodSet.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    children: [
      { path: 'goodType', name: 'goodType', component: goodType },
      { path: 'goodGrouping', name: 'goodGrouping', component: goodGrouping },
      { path: 'goodAttribute', name: 'goodAttribute', component: goodAttribute },
      { path: 'goodManagement', name: 'goodManagement', component: goodManagement },
      { path: 'uploadObs', name: 'uploadObs', component: uploadObs },
      { path: 'goodUpload', name: 'goodUpload', component: goodUpload },
      { path: 'homeDecoration', name: 'homeDecoration', component: homeDecoration },
      { path: 'homeList', name: 'homeList', component: homeList },
      { path: 'limitSet', name: 'limitSet', component: limitSet },
      { path: 'shopSet', name: 'shopSet', component: shopSet },
      { path: 'superShop', name: 'superShop', component: superShop },
      { path: 'homeSun', name: 'homeSun', component: homeSun },
      { path: 'shopLog', name: 'shopLog', component: shopLog },
      { path: 'priceSet', name: 'priceSet', component: priceSet },
      { path: 'orderList', name: 'orderList', component: orderList },
      { path: 'takeStock', name: 'takeStock', component: takeStock },

      { path: 'userMm', name: 'userMm', component: userMm },
      { path: 'clientMm', name: 'clientMm', component: clientMm },
      { path: 'subscribeMm', name: 'subscribeMm', component: subscribeMm },
      { path: 'followUp', name: 'followUp', component: followUp },
      { path: 'taskMm', name: 'taskMm', component: taskMm },

      { path: 'userEchars', name: 'userEchars', component: userEchars },
      { path: 'goodCharts', name: 'goodCharts', component: goodCharts },
      { path: 'takeStockEchars', name: 'takeStockEchars', component: takeStockEchars },
      { path: 'sellCharts', name: 'sellCharts', component: sellCharts },

      { path: 'activePrice', name: 'activePrice', component: activePrice },
      { path: 'activeGoodSet', name: 'activeGoodSet', component: activeGoodSet },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next) =>{
  //console.log(to.name);
  //console.log(localStorage.getItem("token"));
  if (to.name=="login" || localStorage.getItem("token")) {
    next()
  }else{
    console.log("登录过期");
    router.push("/login")
  }

})

export default router
