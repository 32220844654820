<template>
    <div class="main">
        <el-button size="mini" class="addButton" @click="addGroupingButton()" type="primary">新增商品分组</el-button>
        <!-- <el-button size="mini" class="addButton" @click="getGoodGrouping()" t'y'p'r="primary">获取分组</el-button> -->
        <el-button size="mini" class="addButton" @click="tableData = [], getGoodGrouping()">刷新</el-button>
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                    <el-table-column prop="name" label="排序" width="50">
                        <img class="iconPX" src="@/assets/icon/px.png" alt="" srcset="">
                    </el-table-column>
                    <el-table-column prop="name" label="分组名称">
                    </el-table-column>
                    <el-table-column prop="name" label="商品数" width="100">
                        <template slot-scope="scope">
                            <div>32</div>

                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="是否显示" width="100">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.showGrouping" active-color="#13ce66"
                                @change="switchFun($event, scope.row)" inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" width="300" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="updateGroupingButton(scope.row)">编辑</el-button>

                            <el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info"
                                icon-color="red" title="确定删除该分组吗？" @confirm="delGroupingButton(scope.row)">
                                <el-button size="mini" slot="reference" type="danger">删除</el-button>
                            </el-popconfirm>

                            <!-- <el-button size="mini" type="primary" plain @click="handleDelete(scope.row)">编辑关联商品</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <el-dialog :title="windowTitle" :visible.sync="addGroupingShow" width="300px">
                <el-input clearable maxlength="10" style="width: 250px;" size="small" v-model="input"
                    placeholder="请输入分组名称"></el-input>

                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="addGroupingShow = false">取 消</el-button>
                    <el-button v-if="windowTitle == '新增分组'" size="mini" type="primary" @click="savaGrouping()">确
                        定</el-button>
                    <el-button v-else size="mini" type="primary" @click="updateGrouping()">保 存</el-button>
                </span>
            </el-dialog>
            <!--   选择商品弹窗 -->
            <el-dialog title="选择商品" :visible.sync="dialogTableVisible2" v-if="dialogTableVisible2">
                <el-table :data="goodsArr" @selection-change="handleSelectionChange" ref="multipleTable">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column fixed prop="date" label="商品信息" width="100">
                        <template scope="scope">
                            <div class="goodBox">
                                <el-image fit="cover" :src="scope.row.imgMain" :preview-src-list="srcList"
                                    class="goodImg"></el-image>
                            </div>
                        </template>
                    </el-table-column>


                    <el-table-column property="goodName" label="商品名称" width="200"></el-table-column>
                    <el-table-column property="glodPrice" label="商品价格"></el-table-column>
                    <el-table-column property="weight" label="商品重量"></el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="addGroupingShow = false">取 消</el-button>
                    <el-button size="mini" type="primary" @click="addGood()">确
                        定</el-button>

                </span>

            </el-dialog>

        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs'//引入插件
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            addGroupingShow: false,
            dialogTableVisible2: false,
            windowTitle: "新增分组",
            input: "",
            nowObj: "",
            goodsArr: [],
            selectArr: [],
            srcList: [],
            groupId: "",
        }
    },
    mounted() {
        this.shopId = localStorage.getItem("shopId")
        this.getGoodGrouping();
        // 阻止默认行为
        document.body.ondrop = function (event) {
            event.preventDefault();
            event.stopPropagation();
        };
        this.rowDrop()//行拖拽

    },
    methods: {
        getGoodGrouping() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("good/getGoodGrouping", data, (res) => {
                res.data.forEach(o => {
                    o.showGrouping = o.showGrouping ? true : false;
                });
                res.data.sort((a, b) => { return a.sort - b.sort })
                this.tableData = res.data;
            })

        },
        //新增弹窗显示
        addGroupingButton() {
            this.windowTitle = '新增分组';
            this.input = "";
            this.addGroupingShow = true;
        },
        savaGrouping() {
            let data = {
                shopId: this.shopId,
                name: this.input,
                sort: this.tableData.length + 1 + '',
                showGrouping: 1
            }
            this.$api.post("good/addGoodGrouping", data, (res) => {
                console.log(res);
                this.addGroupingShow = false;
                if (res.code == 200) {
                    this.getGoodGrouping()
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    this.input = '';
                } else {
                    this.$message.error('操作失败');
                }

            })
        },
        updateGroupingButton(e) {
            this.windowTitle = '编辑分组名称';
            this.input = e.name;
            this.addGroupingShow = true;
            this.nowObj = e;
            //console.log(e);

        },
        updateGrouping() {
            let data = {
                id: this.nowObj.id,
                name: this.input || this.nowObj.name,
                sort: this.nowObj.sort,
                showGrouping: this.nowObj.showGrouping ? 1 : 0,
            }
            this.$api.put("good/upGoodGrouping", data, (res) => {
                console.log(res);
                this.addGroupingShow = false;
                if (res.code == 200) {
                    this.getGoodGrouping()
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('修改失败');
                }
                this.input = '';
            })


        },
        delGroupingButton(e) {
            console.log(e.id);
            let data = {
                shopId: this.shopId,
                id: e.id + '',
            }
            this.$api.del("good/delGoodGrouping", data, (res) => {

                if (res.code == 200) {
                    this.getGoodGrouping()
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        //修改分组 显示隐藏
        switchFun(e, data) {
            this.nowObj = data;
            this.updateGrouping()
        },
        //行拖拽插件
        rowDrop() {
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            const _this = this
            Sortable.create(tbody, {
                onEnd({ newIndex, oldIndex }) {
                    if (newIndex == oldIndex) return
                    _this.tableData.splice(
                        newIndex,
                        0,
                        _this.tableData.splice(oldIndex, 1)[0]
                    )
                    var newArray = _this.tableData.slice(0)
                    _this.tableData = []
                    _this.$nextTick(function () {
                        _this.tableData = newArray
                        _this.sortSave();
                    })
                }
            })
        },
        //保存拖拽排序的结果
        sortSave(data) {
            console.log("保存拖拽排序的结果");
            let sort = [];
            this.tableData.forEach((o, index) => {
                sort.push({ id: o.id, sort: index + 1 + '' })
            });
            console.log(sort);
            this.$api.put('good/sortGoodGrouping', sort, (res) => {
                if (res.code == 200) {
                    this.getGoodGrouping()
                    this.$message({
                        message: '排序成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('操作失败');
                }

            })

        },
        //打开商品编辑窗口
        handleDelete(e) {
            console.log("添加商品", e);
            this.groupId = e.id
            this.dialogTableVisible2 = true;
            let data = {
                shopId: this.shopId
            }
            this.$api.post("good/getGoods", data, res => {
                console.log(res);
                this.goodsArr = res.data; 
                let arr = []
                res.data.forEach(o => {
                    arr.push(o.imgMain)
                });
                //图片预览
                this.srcList = arr;
                //已知选择图片呈现
                
                let alData = JSON.parse(e.goodArr);
                if (!alData) {
                    return
                }
                setTimeout(() => {
                    alData.forEach(o => {
                        if (o) {
                            this.$refs.multipleTable.toggleRowSelection(res.data.filter(e => e.id == o)[0]);
                        }
                    })
                }, 500);



            })

        },
        //选择的数据
        handleSelectionChange(arr) {
            this.selectArr = arr;

        },
        //保存商品至分组
        addGood() {
            let arr = [];
            this.selectArr.forEach(o => {
                arr.push(o.id);
            })
            let data = {
                goodIdArr: arr,
                shopId: this.shopId,
                groupId: this.groupId,
            }
            this.$api.post("good/addGroupGood", data, res => {
                if (res.code == 200) {
                    this.getGoodGrouping()
                    this.dialogTableVisible2 = false;
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('操作失败');
                }
            })
        }



    }

}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.goodBox {
    /* border: 1px dashed red; */
    position: relative;
    display: flex;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.goodImg {
    width: 65px;
    height: 65px;
    border-radius: 3px;
}
</style>