<template>
    <div class="main">

        <el-input v-model="form.seekValue" placeholder="客户手机号模糊搜索" size="small" class="inputSty"
            clearable></el-input>
        <el-select  v-if="!form.seekValue" v-model="form.memberId" placeholder="选择预约人员" size="small" class="inputSty" clearable>
            <el-option v-for="item in userAdmin" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>

        <el-date-picker style="width: 300px;top: 1.5px;" v-if="!form.seekValue" v-model="dateArr" value-format="yyyy-MM-dd" type="daterange"
            size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="inputSty">
        </el-date-picker>
        
        <el-button size="small" class="addButton" @click="tableData = [], getSubscribe()" type="primary">查询</el-button>

        <!-- 表格显示 -->
        <div class="table">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                    <el-table-column  label="预约状态">
                        <template scope="scope">
                            <div v-if="scope.row.stat == '1'">已过期</div>
                            <div v-if="scope.row.stat == '2'" style="color:#00D400">预约中</div>
                        </template>
                    </el-table-column>

            
                
                    <el-table-column prop="nickname" label="客户名称"></el-table-column>
                    <el-table-column prop="name" label="预约人员"></el-table-column>
                
                    <el-table-column prop="phone" label="预约电话"></el-table-column>
                    <el-table-column prop="crm_intention" label="预约时间">
                        <template scope="scope" >
                            <div>{{ scope.row.subscribeTime }} {{ scope.row.timeStr }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="预约门店"></el-table-column>
                    <el-table-column prop="addTime" label="创建时间"></el-table-column>
                 
                    <!-- <el-table-column prop="address" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.userLookTime"  size="mini" @click="detailsFun(scope.row)">客户详情</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </template>

        </div>
       

    </div>
</template>

<script>


import moment from 'moment';
export default {
    data() {
        return {
            shopId: '',
            tableData: [],
            userAdmin: [],
            form: {
                shopId: this.shopId,
                "startTime": "",
                "endTime": "",
                "seekValue": "",
                "memberId": ""
            },
            form2: {},
            dateArr: [],
            textarea: "",
            user: JSON.parse(localStorage.getItem("user")),
            addTypeShow: false,
            addTypeShow2: false,
            obs: {},
        }
    },
    //过滤器 类型对应
    filters: {
        entryTypeFI(val) {
            if (val == "1") {
                return "非邀约"
            } else if (val == "2") {
                return "海报邀约"
            } else if (val == "2") {
                return "销售邀约"
            } else if (val == "2") {
                return "分销邀约"
            } else {
                return ""
            }
        },
        equalType(val) {
            if (val == "0") {
                return "灰度客户"
            } else if (val == "1") {
                return "普通客户"
            } else {
                return "会员客户"
            }
        }

    },
    mounted() {
        this.shopId = localStorage.getItem("shopId");
        this.form.shopId = localStorage.getItem("shopId");
        this.getAdminUser();

        const threeDaysAgo = moment().subtract(3, 'days');
        this.form.startTime = threeDaysAgo.format('YYYY-MM-DD');
        this.form.endTime = moment().format('YYYY-MM-DD');
        this.dateArr = [this.form.startTime, this.form.endTime]

    },
    methods: {
        getAdminUser() {
            let data = {
                shopId: this.shopId
            }
            this.$api.post("user/getAdmin", data, (res) => {
                this.userAdmin = res.data;
                this.getSubscribe();
            })
        },
        getSubscribe() {

            if (this.dateArr) {
                this.form.startTime = this.dateArr[0];
                this.form.endTime = this.dateArr[1];
            } else {
                this.form.startTime = '';
                this.form.endTime = '';
            }
            if (this.form.syntaxValue) {
                this.form.startTime = '';
                this.form.endTime = '';
            }

            this.$api.post("crm/getSubscribeAdmin", this.form, (res) => {
                res.data.forEach(e => {
                    let arr = this.userAdmin.filter(o => o.id == e.memberId)[0]
                    e.name = arr ? arr.name : '未知'
                   
                });

                this.tableData = res.data;
            })

        },
        showWin(item) {
            console.log(item);
            if (!(item.stat == '1')) {
                this.$message({
                    message: '该订单已审核，无需重复操作',
                    type: 'warning'
                });
                return
            };
            this.addTypeShow = true;
            this.form2 = {
                "shopId": this.user.shopId,
                "orderNo": item.orderNo,
                "crmMemberId": this.user.id,
            }
        },
        savaType(stat) {
            this.form2.stat = stat;
            this.form2.note = this.textarea;
            this.$api.put("order/checkOrder", this.form2, (res) => {
                if (res.code == 200) {
                    this.getSubscribe()
                    this.addTypeShow = false
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        //查看详情
        detailsFun(item) {
            this.addTypeShow2 = true;

            let data = {
                "shopId": this.user.shopId, //必填
                "openId": item.crmOpenId //openId
            }

            this.$api.post("crm/getSubscribeDataU", data, (res) => {
                if (res.code == 200) {
                    this.obs = res.data[0];

                }
            })

        },

    },


}
</script>

<style scoped>
.main {
    width: calc(100% - 20px);
    height: calc(100% - 55px);
    background: #fff;
    padding: 10px;
    position: relative;
    text-align: left;
}

.addButton {
    position: relative;

}

.table {
    border: 1px solid #E9ECF0;
    padding: 10px;
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: auto;
}

div/deep/.el-dialog__body {
    padding: 5px 20px;
}

.el-button--mini {
    margin: 0px 5px;
}

.iconPX {
    width: 23px;
    height: 21px;
    position: relative;
    top: 3px;
    left: 3px;
    cursor: move;
}

.tableBox {
    margin-top: 10px;

    height: 40vh;
    border: 1px solid #f6f6f6;

}

.goodPT {
    position: relative;
    top: 3px;
    width: 30px;
}

.inputSty {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.goodImg {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}

.textSty {
    width: 40%;
    margin: 6px 4%;
    display: inline-flex;
    /* border: 1px solid #efefef; */
}
</style>